import React from 'react';
import styles from './AaGallery.module.scss';
import cn from 'classnames';
import ImageGallery from 'react-image-gallery';
import { Icon } from 'views/components/Icon';
import { observer } from 'mobx-react-lite';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './AaGallery.scss';
import { IReviewImageUrlsDtoModel } from 'api/models/Domain/Queries/SharedDtos/Reviews/ReviewImageUrlsDtoModel';
import ReactImageGallery from 'react-image-gallery';

interface IAaGalleryProps {
  items: IReviewImageUrlsDtoModel[];
  hideNav?: boolean;
  className?: string;
  startIndex?: number;
  setShowFullscreenGallery?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AaGallery: React.FC<IAaGalleryProps> = observer(function ({
  items,
  hideNav,
  className,
  startIndex,
  setShowFullscreenGallery,
}) {
  const galleryRef = React.useRef<ReactImageGallery | null>(null);
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  React.useEffect(() => {
    if (setShowFullscreenGallery) {
      galleryRef.current?.fullScreen();
    }
  }, [galleryRef, setShowFullscreenGallery]);

  return (
    <div className={cn(styles.root, className)}>
      <ImageGallery
        ref={galleryRef}
        items={items.map((i: IReviewImageUrlsDtoModel) => {
          return {
            original: i.imageUrl,
            thumbnail: i.thumbnailUrl,
          };
        })}
        startIndex={startIndex ?? 0}
        additionalClass={cn(styles.carousel, {
          [styles.fullscreen]: isFullScreen,
          [styles.fullscreenOnly]: setShowFullscreenGallery,
        })}
        showPlayButton={false}
        showFullscreenButton={isFullScreen}
        onScreenChange={isFullScreen => {
          setIsFullScreen(Boolean(isFullScreen));
          if (!isFullScreen && setShowFullscreenGallery) setShowFullscreenGallery(false);
        }}
        lazyLoad
        showThumbnails={isFullScreen}
        showNav
        onClick={() => {
          galleryRef.current?.fullScreen();
        }}
        renderLeftNav={(onClick, disabled) => {
          if (hideNav) {
            return null;
          }
          return (
            <div
              className={cn(styles.carouselNav, styles.left, {
                [styles.fullscreen]: isFullScreen,
              })}>
              <button className={styles.galleryButton} disabled={disabled} onClick={onClick}>
                <span className="visually-hidden">Previous image</span>
                <Icon className={styles.icon} name="arrow-left" />
              </button>
            </div>
          );
        }}
        renderRightNav={(onClick, disabled) => {
          if (hideNav) {
            return null;
          }
          return (
            <div
              className={cn(styles.carouselNav, styles.right, {
                [styles.fullscreen]: isFullScreen,
              })}>
              <button className={styles.galleryButton} disabled={disabled} onClick={onClick}>
                <span className="visually-hidden">Next image</span>
                <Icon className={styles.icon} name="arrow-right" />
              </button>
            </div>
          );
        }}
        renderFullscreenButton={(onClick, _) => {
          return (
            <button onClick={onClick} className={cn(styles.galleryButton, styles.closeFullScreen)}>
              <Icon className={styles.icon} name="times" />
            </button>
          );
        }}
      />
      <button
        className={styles.makeFullScreen}
        onClick={() => {
          galleryRef.current?.fullScreen();
        }}>
        Full screen
      </button>
    </div>
  );
});
