import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Shell } from 'views/components/application/Shell';
import { ErrorBoundary } from 'views/components/application/ErrorBoundary';
import { Search } from 'views/routes/pois/Search';
import { NotFound } from 'views/routes/notFound/NotFound';
import { routePaths } from './routePaths';

export const Routes: React.FC = observer(function Routes() {
  const location = useLocation();

  return (
    <Shell>
      <ErrorBoundary location={location}>
        <Switch>
          <Redirect exact from="/" to={routePaths.pois.to()} />
          <Route path={routePaths.pois.template}>
            <Search />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Shell>
  );
});
