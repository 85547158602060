import { FieldError, ValidationOptions } from 'react-hook-form';
import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import styles from './Select.module.scss';
import { Icon, IconName } from 'views/components/Icon';

interface ISelectProps {
  selectErrors: FieldError | undefined;
  name: string;
  register: (Ref: HTMLSelectElement, validateRule?: ValidationOptions) => void;
  selections: { value: any; description: string }[];
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  className?: string;
  onChange?: () => void;
  required?: boolean;
  icon?: IconName;
}

export const Select: React.FC<ISelectProps> = observer(function ({
  selectErrors,
  name,
  register,
  placeholder,
  selections,
  label,
  className,
  onChange,
  required,
  icon,
  disabled,
}) {
  return (
    <div className={cn(styles.root, className)}>
      {label && (
        <label htmlFor={name} className={cn({ [styles.error]: selectErrors })}>
          {label}
        </label>
      )}
      {required && (
        <span className={cn(styles.required, { [styles.error]: selectErrors })}>Required</span>
      )}
      <div
        className={cn(styles.selectBox, {
          [styles.error]: selectErrors,
          [styles.disabled]: disabled,
        })}>
        <select
          name={name}
          id={name}
          ref={register}
          defaultValue=""
          onChange={onChange}
          disabled={disabled}>
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {selections.map(s => (
            <option value={s.value} key={s.description}>
              {s.description}
            </option>
          ))}
        </select>
        <Icon name={icon ? icon : 'chevron-down'} className={styles.icon} />
      </div>
      {selectErrors && <span className={styles.errorMessage}>{selectErrors.message}</span>}
    </div>
  );
});
