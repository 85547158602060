import React from 'react';
import { useForm } from 'react-hook-form';
import { RadioSlider } from 'views/components/forms/radioSlider/RadioSlider';
import { useUrlParam } from 'views/hooks';
import { urlParamNames } from 'views/routes/routePaths';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { ReactComponent as StarFilled } from 'images/stars/star-filled.svg';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { roundStarRating } from 'views/components/detail/starRating/StarRating';

const filterDisplayOptions: ReadonlyArray<string> = ['Any', '3', '4', '5'];
export const validFilterValues = filterDisplayOptions.map(x =>
  x === 'Any' ? 'Any' : roundStarRating(Number(x), 'filter').toString()
);
const filterOptions = validFilterValues.map((value, i) => {
  return {
    value: value,
    display: filterDisplayOptions[i],
    icon: parseFloat(value) ? <StarFilled /> : undefined,
  };
});

interface IFormData {
  accessibility: string;
  service: string;
}

interface IFiltersProps {
  className?: string;
}

export const Filters: React.FC<IFiltersProps> = function () {
  const [, , unsetShowFilter] = useUrlParam(urlParamNames.search.SHOW_FILTER);
  const [accessibilityFilter, setAccessibilityFilter, unsetAccessibilityFilter] = useUrlParam(
    urlParamNames.search.ACCESSIBILITY_FILTER
  );
  const [serviceFilter, setServiceFilter, unsetServiceFilter] = useUrlParam(
    urlParamNames.search.SERVICE_FILTER
  );

  const sanitiseAndGetAccessibilityRating = () => {
    if (accessibilityFilter && validFilterValues.includes(accessibilityFilter)) {
      return accessibilityFilter;
    }

    unsetAccessibilityFilter();
    return 'Any';
  };

  const sanitiseAndGetServiceRating = () => {
    if (serviceFilter && validFilterValues.includes(serviceFilter)) {
      return serviceFilter;
    }

    unsetServiceFilter();
    return 'Any';
  };

  const { register, handleSubmit, watch, setValue, control } = useForm<IFormData>({
    defaultValues: {
      accessibility: sanitiseAndGetAccessibilityRating(),
      service: sanitiseAndGetServiceRating(),
    },
  });

  const accessibilityField = 'accessibility';
  const selectedAccessibilityRating = watch(accessibilityField);

  const serviceField = 'service';
  const selectedServiceRating = watch(serviceField);

  async function onSubmit(data: IFormData) {
    if (data.accessibility === 'Any') {
      unsetAccessibilityFilter();
    } else {
      setAccessibilityFilter(data.accessibility);
    }
    unsetShowFilter();

    if (data.service === 'Any') {
      unsetServiceFilter();
    } else {
      setServiceFilter(data.service);
    }
    unsetShowFilter();
  }

  const backButton = <BackButton onClick={unsetShowFilter} />;

  React.useEffect(
    () => {
      setValue(accessibilityField, sanitiseAndGetAccessibilityRating());
      setValue(serviceField, sanitiseAndGetServiceRating());
    },

    // Adding sanitiseAndGetAccessibilityRating to dependencies causes multiple re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessibilityFilter, serviceFilter, setValue]
  );

  return (
    <SubpageForm
      title="Filters"
      titleLeftAligned
      leftAction={backButton}
      submitText="Apply"
      onSubmit={handleSubmit(onSubmit)}
      control={control}
      firstTagToFocus="button"
      onEsc={unsetShowFilter}>
      <RadioSlider
        label="Accessibility"
        register={register}
        name={accessibilityField}
        selected={selectedAccessibilityRating}
        options={filterOptions}
      />
      <RadioSlider
        label="Service"
        register={register}
        name={serviceField}
        selected={selectedServiceRating}
        options={filterOptions}
      />
    </SubpageForm>
  );
};
