import React from 'react';
import styles from './FooterLinks.module.scss';
import { Link } from 'react-router-dom';
import { addParamToLocation } from 'infrastructure/locationUtils';
import { urlParams as shellUrlParams } from 'views/components/application/Shell';
import { urlParamNames } from 'views/routes/routePaths';
import SpinalLifeLogo from 'images/spinal-life-logo.png';

export const FooterLinks: React.FC = function () {
  return (
    <footer className={styles.root}>
      <div className={styles.legalLinkContainer}>
        <Link
          to={l => addParamToLocation(l, urlParamNames.landingPage.SHOW_LANDING_PAGE, 'y')}
          className={styles.link}
          title={'View help and frequently asked questions'}>
          Help
        </Link>
        <div className={styles.separator}>・</div>
        <Link
          to={l => addParamToLocation(l, shellUrlParams.SHOW_TERMS_OF_USE, 'y')}
          className={styles.link}
          title={'View terms of use'}>
          Terms of Use
        </Link>
        <div className={styles.separator}>・</div>
        <Link
          to={l => addParamToLocation(l, shellUrlParams.SHOW_PRIVACY_POLICY, 'y')}
          className={styles.link}
          title={'View privacy policy'}>
          Privacy&nbsp;Policy
        </Link>
      </div>
      <a
        className={styles.branding}
        href="https://www.spinal.com.au"
        rel="noopener noreferrer"
        target="_blank">
        <img src={SpinalLifeLogo} alt="Spinal Life Australia" />
      </a>
    </footer>
  );
};
