import cn from 'classnames';
import styles from './IconButton.module.scss';
import { Icon, IIconProps } from 'views/components/Icon';
import React from 'react';

interface IIconButtonProps<TLinkState> {
  className?: string;
  title: string;
  icon: IIconProps;
  onClick: () => void;
}

export function IconButton<TLinkState = unknown>({
  className,
  title,
  icon,
  onClick,
}: IIconButtonProps<TLinkState>) {
  return (
    <button
      className={cn(className, styles.root)}
      title={title}
      aria-label={title}
      onClick={onClick}
      // type="button" is to stop any use of an IconButton in a form being treated as a "submit" button
      type="button">
      <Icon {...icon} />
    </button>
  );
}
