import React from 'react';
import { AaMap } from './aaMap/AaMap';
import { useStore } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import { LoadingPane } from 'views/components/LoadingPane';

interface ISearchMapProps {
  className?: string;
}

export const SearchMap: React.FC<ISearchMapProps> = observer(function ({ className }) {
  const store = useStore();
  const config = store.configuration;

  React.useEffect(() => {
    if (config.hasLoaded && !config.azure.key) {
      store.notifications.addWarning('Map cannot be displayed at this time');
    }
  }, [config.azure.key, config.hasLoaded, store.notifications]);

  if (config.hasLoaded && !config.azure.key) {
    return null;
  }

  return (
    <LoadingPane className={className} isLoading={config.isLoading}>
      {config.azure.key && <AaMap apiKey={config.azure.key} />}
    </LoadingPane>
  );
});
