import React from 'react';
import styles from './Onboarding.module.scss';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useStore } from 'views/hooks';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { Input } from 'views/components/forms/input/Input';
import { BackButton } from 'views/components/detail/backButton/BackButton';

interface ISetDisplayNameProps {
  className?: string;
  onNext: () => void;
  onBack: () => void;
  setDisplayName: (displayName: string) => void;
}

interface IFormData {
  displayName: string;
}

export const DisplayName: React.FC<ISetDisplayNameProps> = observer(function ({
  className,
  onNext,
  onBack,
  setDisplayName,
}) {
  const { register, handleSubmit, errors, control } = useForm<IFormData>();
  const security = useStore().security;

  const onSubmit = (data: IFormData) => {
    setDisplayName(data.displayName);
    onNext();
  };

  return (
    <SubpageForm
      leftAction={<BackButton onClick={onBack} />}
      className={cn(className)}
      containerClassName={styles.subsection}
      title="Hey there... you?"
      titleLeftAligned
      submitText="Next"
      submitIcon="arrow-right"
      onSubmit={handleSubmit(onSubmit)}
      control={control}
      firstTagToFocus="input">
      <p>
        We don't know what to call you. Enter your name below so we can personalise your experience
      </p>
      <Input
        inputErrors={errors.displayName}
        name="displayName"
        register={register({
          required: 'Please enter a display name',
          maxLength: {
            value: 100,
            message: 'A display name cannot be more than 100 characters',
          },
          pattern: { value: /\S+/, message: 'Display name cannot be empty' },
        })}
        label="Name"
        defaultValue={security.userProfile && security.userProfile.displayName}
        placeholder="Please enter a display name"
        highlightOnFocus
        autoComplete={false}
      />
    </SubpageForm>
  );
});
