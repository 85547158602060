import React from 'react';
import { Location } from 'history';

interface IErrorBoundaryProps {
  location: Location;
}

interface IErrorBoundaryState {
  hasError: boolean;
  path: string;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, path: props.location.pathname };
  }

  // Yes, resetting state when props change is explicitly mentioned as what to avoid in the React docs:
  // https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
  // HOWEVER, we don't want a controlled component, as then we have to manage the state externally,
  // and we don't want to use a key, as it causes all child components to be remounted, which causes
  // the map to have to re-initialise.
  static getDerivedStateFromProps(
    nextProps: Readonly<IErrorBoundaryProps>,
    prevState: IErrorBoundaryState
  ) {
    const nextPath = nextProps.location.pathname;
    const pathChanged = nextPath !== prevState.path;
    return { hasError: pathChanged ? false : prevState.hasError, path: nextPath };
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error('Rendering failed below Error Boundary: ', error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>:(</h1>
          <p>Unfortunately something went wrong. Please try again later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}
