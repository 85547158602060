import React, { useState } from 'react';
import styles from './ShareToSocials.module.scss';
import { useUrlParam } from 'views/hooks';
import { urlParamNames } from 'views/routes/routePaths';
import { Subpage } from 'views/components/layout/Subpage';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { FacebookIcon, FacebookShareButton, TwitterShareButton, TwitterIcon } from 'react-share';

interface IShareToSocialsProps {
  shareUrl: string;
}

export const ShareToSocials: React.FC<IShareToSocialsProps> = function ({
  shareUrl
}) {
  const [, , unsetShowShare] = useUrlParam(urlParamNames.search.SHOW_SHARE);
  const [isCopied, setIsCopied] = useState(false);

  const backButton = <BackButton onClick={unsetShowShare} />;

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(shareUrl)
      .then(() => {
        setIsCopied(true);
      });
  }

  return (
        <Subpage
            title="Share to Socials"
            leftAction={backButton}
            className={styles.root}>
              <section className={styles.sharePanel}>
                <div className={styles.url}>
                  <textarea disabled={true} value={shareUrl} className={styles.urlText}></textarea>
                </div>
                
                <div className={styles.copyLink}>
                  <button className={styles.actionButton} onClick={handleCopyClick}>
                    <span>{isCopied ? 'Copied!' : 'Copy Link'}</span>
                  </button>
                </div>
                
                <div className={styles.socials}>
                  <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={48} round className={styles.socialShareIcon}/>
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size={48} round className={styles.socialShareIcon}/>
                  </TwitterShareButton>
                </div>
              </section>
        </Subpage>
  );
};
