import { useContext, useEffect } from 'react';
import { routePaths } from 'views/routes/routePaths';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from 'views/hooks';
import { MapContext } from 'views/routes/pois/Search';

/*
 * This functionality has been broken into its own component so POI details
 * are reliably loaded for all components that need them
 *  */
export const PointOfInterestDetailsLoader = function () {
  const { id } = useParams();
  const store = useStore();
  const mapContext = useContext(MapContext);
  const history = useHistory();

  useEffect(() => {
    id &&
      store.pointsOfInterestRepo
        .load(id)
        .then(
          () => mapContext.forceReloadSingleMapMarker && mapContext.forceReloadSingleMapMarker(id)
        )
        .catch(() => history.push(routePaths.pois.toKeepingExistingParams()(history.location)));
  }, [id, history, mapContext, store.pointsOfInterestRepo]);

  return null;
};
