import { types, Instance } from 'mobx-state-tree';

export const ProfileDtoModel = types.model('ProfileDtoModel', {
  displayName: types.string,
  emailAddress: types.maybe(types.string),
  emailConsent: types.boolean,
  facebookId: types.maybe(types.string),
  hasOnboarded: types.boolean,
  mobilityAid: types.maybe(types.frozen<Domain.Aggregates.User.Entities.MobilityAid>()),
  shouldSeeEmailConsentPrompt: types.boolean,
});

export interface IProfileDtoModel extends Instance<typeof ProfileDtoModel> {}
