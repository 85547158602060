import React from 'react';
import cn from 'classnames';
import styles from './TextArea.module.scss';
import { FieldError, ValidationOptions } from 'react-hook-form';

interface ITextAreaProps {
  textAreaErrors: FieldError | undefined;
  name: string;
  register: (Ref: HTMLTextAreaElement, validateRule?: ValidationOptions) => void;
  placeholder?: string;
  label: string;
  rows?: number;
  maxLength?: number;
  required?: boolean;
  autoFocus?: boolean;
}

export const TextArea: React.FC<ITextAreaProps> = function ({
  textAreaErrors,
  name,
  register,
  placeholder,
  label,
  rows,
  maxLength,
  required,
  autoFocus,
}) {
  return (
    <div className={cn(styles.root, { [styles.error]: textAreaErrors })}>
      <label htmlFor={name} className={cn({ [styles.error]: textAreaErrors })}>
        {label}
      </label>
      {required && (
        <span className={cn(styles.required, { [styles.error]: textAreaErrors })}>Required</span>
      )}
      <textarea
        className={cn(styles.textArea, { [styles.error]: textAreaErrors })}
        id={name}
        name={name}
        placeholder={placeholder}
        rows={rows || 4}
        maxLength={maxLength}
        ref={register}
        autoFocus={autoFocus}
      />
      {textAreaErrors && <span className={styles.errorMessage}>{textAreaErrors.message}</span>}
    </div>
  );
};
