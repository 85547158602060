import React from 'react';
import styles from './StarRating.module.scss';
import cn from 'classnames';
import { ReactComponent as StarFilled } from 'images/stars/star-filled.svg';
import { ReactComponent as StarHalf } from 'images/stars/star-half.svg';
import { ReactComponent as StarEmpty } from 'images/stars/star-empty.svg';
import { ReactComponent as StarFilledBlack } from 'images/stars/star-filled-black.svg';
import { ReactComponent as StarHalfBlack } from 'images/stars/star-half-black.svg';

interface IStarRatingProps {
  rating: number | undefined;
  titlePrefix: 'Accessibility' | 'Service';
  className?: string;
  useBlackStars?: boolean;
}

export function StarRating({ rating, className, useBlackStars, titlePrefix }: IStarRatingProps) {
  const roundedRating = rating ? roundStarRating(rating, 'display') : 0;

  const filledStar = (key: number) =>
    useBlackStars ? (
      <StarFilledBlack key={key} aria-hidden />
    ) : (
      <StarFilled key={key} aria-hidden />
    );

  const halfStar = (key: number) =>
    useBlackStars ? <StarHalfBlack key={key} aria-hidden /> : <StarHalf key={key} aria-hidden />;

  const emptyStar = (key: number) => (useBlackStars ? null : <StarEmpty key={key} aria-hidden />);

  const title = rating
    ? `${titlePrefix}: ${roundedRating} out of 5 stars`
    : `${titlePrefix} does not have a rating`;

  return (
    <div className={cn(className, styles.root)} title={title} aria-label={title}>
      {rating ? (
        [1, 2, 3, 4, 5].map(n =>
          n <= roundedRating
            ? filledStar(n)
            : Math.ceil(roundedRating) === n
            ? halfStar(n)
            : emptyStar(n)
        )
      ) : (
        <span>Unrated</span>
      )}
    </div>
  );
}

export function roundStarRating(x: number, use: 'display' | 'filter') {
  return Math.round(x * 2) / 2 - (use === 'filter' ? 0.25 : 0);
}
