import { types, Instance } from 'mobx-state-tree';

export const AddressDtoModel = types.model('AddressDtoModel', {
  businessName: types.maybe(types.string),
  businessPhone: types.maybe(types.string),
  businessUrl: types.maybe(types.string),
  city: types.string,
  freeFormAddress: types.string,
  key: types.string,
  latitude: types.number,
  longitude: types.number,
  postcode: types.string,
  state: types.string,
  streetAddress: types.string,
});

export interface IAddressDtoModel extends Instance<typeof AddressDtoModel> {}
