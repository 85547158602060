import React from 'react';
import styles from './markers.module.scss';
import cn from 'classnames';
import { Icon } from 'views/components/Icon';
import { CategoryIcon } from 'views/routes/pois/CategoryIcon';
import { IPointOfInterestDtoModel } from 'api/models/Domain/Queries/PointOfInterest/SearchPointsOfInterestQuery/PointOfInterestDtoModel';
import * as atlas from 'azure-maps-control';
import { PieChart } from 'react-minimal-pie-chart';
import { siteDefault, rgbToCssColor } from 'views/themes';
import { StarRating } from 'views/components/detail/starRating/StarRating';

interface IAaMapUserMarker {
  className?: string;
}

export const AaMapUserMarker: React.FC<IAaMapUserMarker> = function ({ className }) {
  return (
    <div data-poi-id={'user'} className={cn(styles.user, className)}>
      <Icon className={styles.icon} name="user" />
    </div>
  );
};

interface IAaMapNewPoiMarker {
  className?: string;
}

export const AaMapNewPoiMarker: React.FC<IAaMapNewPoiMarker> = function ({ className }) {
  return (
    <div data-poi-id={'new-poi'} className={cn(styles.newPoi, className)}>
      <Icon className={styles.icon} name="map-marker" />
    </div>
  );
};

interface IAaMapClusterMarker {
  cluster: atlas.data.Feature<atlas.data.Geometry, any>;
  className?: string;
}

export const AaMapClusterMarker: React.FC<IAaMapClusterMarker> = function ({ cluster, className }) {
  const placesCount = (count: number) => `${count} ${count > 1 ? 'places' : 'place'}`;

  return (
    <div
      data-poi-id={'cluster'}
      className={cn(styles.cluster, className)}
      title={`${cluster.properties.point_count_abbreviated} places here`}>
      <PieChart
        totalValue={cluster.properties.point_count_abbreviated}
        data={[
          {
            title: `Accessibility: 1 to 3 stars (${placesCount(cluster.properties.RedPoiCount)})`,
            value: cluster.properties.RedPoiCount,
            color: rgbToCssColor(siteDefault['--map-marker-poi-red-rgb']),
          },
          {
            title: `Accessibility: Unrated (${placesCount(cluster.properties.UnratedPoiCount)})`,
            value: cluster.properties.UnratedPoiCount,
            color: rgbToCssColor(siteDefault['--map-marker-poi-unrated-rgb']),
          },
          {
            title: `Accessibility: 3 to 4 stars (${placesCount(
              cluster.properties.YellowPoiCount
            )})`,
            value: cluster.properties.YellowPoiCount,
            color: rgbToCssColor(siteDefault['--map-marker-poi-yellow-rgb']),
          },
          {
            title: `Accessibility: 4 to 5 stars (${placesCount(cluster.properties.GreenPoiCount)})`,
            value: cluster.properties.GreenPoiCount,
            color: rgbToCssColor(siteDefault['--map-marker-poi-green-rgb']),
          },
        ]}
      />
      <div className={styles.innerCircle}>
        <span className={styles.clusterPointCount}>
          {cluster.properties.point_count_abbreviated}
        </span>
      </div>
    </div>
  );
};

interface IAaMapPoiMarker {
  shape: atlas.Shape;
  labelPlacement: 'left' | 'right';
  pinClass?: string;
}

export const AaMapPoiMarker: React.FC<IAaMapPoiMarker> = function ({
  shape,
  labelPlacement,
  pinClass,
}) {
  const poi: IPointOfInterestDtoModel = shape.getProperties();

  const getBackgroundStyle = (accessibilityRating: number | undefined) => {
    if (!accessibilityRating || accessibilityRating < 1) {
      return styles.unrated;
    }

    if (accessibilityRating < 3) {
      return styles.red;
    }

    if (accessibilityRating < 4) {
      return styles.yellow;
    }

    return styles.green;
  };

  return (
    <div>
      <div
        title={poi.name}
        data-poi-id={poi.id}
        className={cn(styles.pin, pinClass, getBackgroundStyle(poi.averageAccessibilityRating))}>
        <CategoryIcon className={styles.icon} category={poi.primaryCategory} />
      </div>
      <div
        className={cn(
          styles.label,
          labelPlacement === 'left' ? styles.textToLeft : styles.textToRight
        )}>
        <span className={styles.poiName} title={poi.name}>
          {poi.name}
        </span>
        <span className={styles.accessibilityRating}>
          <Icon name="wheelchair" className={styles.accessibilityRatingIcon} />
          {poi.averageAccessibilityRating && poi.averageAccessibilityRating >= 1 ? (
            <>
              <StarRating
                rating={poi.averageAccessibilityRating}
                className={styles.accessibilityRating}
                titlePrefix="Accessibility"
                useBlackStars
              />
              {poi.reviewCount > 0 && (
                <span
                  title={`${poi.reviewCount} ${poi.reviewCount > 1 ? 'reviews' : 'review'}`}
                  className={styles.reviewCount}>{`(${poi.reviewCount})`}</span>
              )}
            </>
          ) : (
            'UNRATED'
          )}
        </span>
      </div>
    </div>
  );
};
