import React from 'react';
import { Icon, IIconProps } from 'views/components/Icon';
import { PointOfInterestCategory } from 'api/enums/PointOfInterestCategory';

const categoryToIconName: { [key in PointOfInterestCategory]: IIconProps['name'] } = {
  [PointOfInterestCategory.Bathroom]: 'toilet',
  [PointOfInterestCategory.ChangingRoom]: 'hanger',
  [PointOfInterestCategory.Accommodation]: 'bed',
  [PointOfInterestCategory.Activity]: 'spa',
  [PointOfInterestCategory.Attraction]: 'ferris-wheel',
  [PointOfInterestCategory.Bank]: 'bank-building',
  [PointOfInterestCategory.Bar]: 'guinness-beer',
  [PointOfInterestCategory.BusStop]: 'bus',
  [PointOfInterestCategory.CafeRestaurant]: 'cutlery',
  [PointOfInterestCategory.CarPark]: 'parking',
  [PointOfInterestCategory.Dentist]: 'tooth',
  [PointOfInterestCategory.EquipmentHire]: 'dolly',
  [PointOfInterestCategory.GeneralPractitioner]: 'stethoscope',
  [PointOfInterestCategory.Grocer]: 'vegetarian-food',
  [PointOfInterestCategory.Gymnasium]: 'dumbbell',
  [PointOfInterestCategory.Hospital]: 'hospital-3',
  [PointOfInterestCategory.Information]: 'info',
  [PointOfInterestCategory.LightRailStation]: 'tram',
  [PointOfInterestCategory.Museum]: 'bust',
  [PointOfInterestCategory.OtherRetail]: 'shopping-cart',
  [PointOfInterestCategory.Park]: 'evergreen',
  [PointOfInterestCategory.Pharmacy]: 'hospital',
  [PointOfInterestCategory.ServiceStation]: 'gas-station',
  [PointOfInterestCategory.Services]: 'ticket',
  [PointOfInterestCategory.TaxiRank]: 'taxi',
  [PointOfInterestCategory.Theatre]: 'cinema',
  [PointOfInterestCategory.Tour]: 'bus-alt',
  [PointOfInterestCategory.TrainStation]: 'train',
  [PointOfInterestCategory.VehicleHire]: 'car',
  [PointOfInterestCategory.Venue]: 'music',
  [PointOfInterestCategory.Beach]: 'umbrella-beach',
  [PointOfInterestCategory.AlliedHealth]: 'caduceus',
  [PointOfInterestCategory.Specialist]: 'organ-transplantation',
  [PointOfInterestCategory.OtherHealthService]: 'heart-with-pulse',
};

interface ICategoryIconProps {
  className?: string;
  category: PointOfInterestCategory;
}

export const CategoryIcon: React.FC<ICategoryIconProps> = function ({ className, category }) {
  return <Icon className={className} name={categoryToIconName[category]} />;
};

export function getCategoryIconName(category: PointOfInterestCategory) {
  return categoryToIconName[category];
}
