import React from 'react';
import styles from './SearchPanel.module.scss';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useStore, useUrlParam } from 'views/hooks';
import { useDebouncedCallback } from 'use-debounce';
import { Icon } from 'views/components/Icon';
import { PoisPanel } from 'views/routes/pois/searchPanel/poisPanel/PoisPanel';
import { AreasPanel } from 'views/routes/pois/searchPanel/areasPanel/AreasPanel';
import { CategoriesPanel } from 'views/routes/pois/searchPanel/CategoriesPanel/CategoriesPanel';
import { MapContext } from 'views/routes/pois/Search';
import { isValidCoordinate } from 'infrastructure/geoUtils';
import { urlParamNames, routePaths } from 'views/routes/routePaths';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { LoadingPane } from 'views/components/LoadingPane';
import { maxSearchRadius, defaultSearchRadius } from './poisPanel/searchFurther/SearchFurther';
import { parseCategoryName, pluraliseCategory } from 'domain/store/repos/CategoriesRepo';
import { NoPoiResults } from './noPoiResults/NoPoiResults';

function ignoreErrors(p: Promise<unknown>) {
  // We do not need any special error handling as the standard notifications are fine
  return p.catch(() => {});
}

const hideMobileKeyboard = function () {
  (document.activeElement as HTMLElement).blur();
};

interface IFormData {
  search: string;
}

interface ISearchPanelProps {
  className?: string;
}

export const SearchPanel: React.FC<ISearchPanelProps> = observer(function SearchPanel({
  className,
}) {
  const store = useStore();
  const searchContext = React.useContext(MapContext);
  const history = useHistory();
  const environment = store.environment;
  const pointsOfInterestRepo = store.pointsOfInterestRepo;
  const searchModel = store.search;
  const isSmallViewport = store.environment.isSmallViewport;

  const [showList, setShowList] = useUrlParam(urlParamNames.search.SHOW_LIST);
  const [showLandingPage] = useUrlParam(urlParamNames.landingPage.SHOW_LANDING_PAGE);
  const [searchValue] = useUrlParam(urlParamNames.search.SEARCH);
  const [categoryValue, , unsetCategoryValue] = useUrlParam(urlParamNames.search.CATEGORY);
  const [accessibilityFilter] = useUrlParam(urlParamNames.search.ACCESSIBILITY_FILTER);
  const [serviceFilter] = useUrlParam(urlParamNames.search.SERVICE_FILTER);
  const [radius, setRadius] = useUrlParam(urlParamNames.search.SEARCH_RADIUS);
  const [showMapActionContextMenu, , unsetShowMapActionContextMenu] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU
  );

  const [searchCoordinates, setSearchCoordinates] = useUrlParam(
    urlParamNames.search.SEARCH_COORDINATES
  );
  const [searchLat, searchLng] =
    searchCoordinates !== null ? searchCoordinates.split('_') : [null, null];

  const [overrideCoordinates, setOverrideCoordinates] = useUrlParam(
    urlParamNames.search.OVERRIDE_COORDINATES
  );
  const [overrideLat, overrideLng] =
    overrideCoordinates !== null ? overrideCoordinates.split('_') : [null, null];

  const { id } = useParams();
  const selectedPoi = pointsOfInterestRepo.searchResults.find(i => i.id === id);

  const formRef = React.useRef<HTMLFormElement | null>(null);
  const resultsRef = React.useRef<HTMLDivElement | null>(null);
  const { register, handleSubmit, setValue } = useForm<IFormData>({
    defaultValues: { search: searchValue || '' },
  });

  const searchResettable =
    showList ||
    searchValue !== null ||
    categoryValue !== null ||
    accessibilityFilter !== null ||
    serviceFilter !== null ||
    pointsOfInterestRepo.pointOfInterestDetails;

  function resetSearch() {
    pointsOfInterestRepo.clearSearch();
    if (!isSmallViewport) {
      searchContext.focusSearchField && searchContext.focusSearchField();
    }
    const overrideCoordinatesToPersist = isValidCoordinate(overrideLat, overrideLng)
      ? `${overrideLat}_${overrideLng}`
      : undefined;

    history.push(routePaths.pois.to());
    overrideCoordinatesToPersist && setOverrideCoordinates(overrideCoordinatesToPersist);
  }

  function resetScroll() {
    resultsRef.current?.scrollTo(0, 0);
  }

  function invalidSearchRadius(radius: string | null) {
    if (radius === null || isNaN(Number(radius)) || Number(radius) <= 0) {
      setRadius(defaultSearchRadius.toString());
    } else if (Number(radius) > maxSearchRadius) {
      setRadius(maxSearchRadius.toString());
    } else if (Number(radius) % 1) {
      setRadius(Math.ceil(Number(radius)).toString());
    } else {
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    searchContext.focusSearchField = () => formRef.current?.search.select();
    // Disable the lint rule in this case because we ONLY want this running when the component first mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // If the search is set via the url, we want to update the search field to match
    setValue('search', searchValue || '');

    // Check that category is valid
    const category = parseCategoryName(categoryValue);
    if (category === undefined) {
      unsetCategoryValue();
    }

    if ((searchLat || searchLng) && !isValidCoordinate(searchLat, searchLng)) {
      resetSearch();
      return;
    }

    if ((overrideLat || overrideLng) && !isValidCoordinate(overrideLat, overrideLng)) {
      resetSearch();
      return;
    }

    if (searchValue !== null || category !== undefined || accessibilityFilter || serviceFilter) {
      // If no location set in URL don't search. Check and set location to the map position if available
      if (!searchLat || !searchLng) {
        if (searchContext.getMapFocusCoords) {
          const [lng, lat] = searchContext.getMapFocusCoords();
          setSearchCoordinates(`${lat}_${lng}`);
        }
        return;
      }

      if (invalidSearchRadius(radius)) {
        return;
      }

      const searchDistance = Number(radius);
      const [lat, lng] = [Number(searchLat), Number(searchLng)];
      const locationOverride: [number, number] | undefined =
        overrideLat && overrideLng ? [Number(overrideLat), Number(overrideLng)] : undefined;
      const search = searchValue || '';
      const accessibility = accessibilityFilter ? Number(accessibilityFilter) : undefined;
      const service = serviceFilter ? Number(serviceFilter) : undefined;

      ignoreErrors(
        searchModel.performSearch(
          search,
          category,
          accessibility,
          service,
          lat,
          lng,
          searchDistance,
          locationOverride
        )
      );
      searchContext.mapHasMoved = false;
    } else {
      searchModel.clearSearch();
      resetScroll();
    }
    // Warning suppressed as setSearchCoordinates & searchCoordinates are not included as dependencies
    // Including them causes the useEffect to execute a number of extra times on load for no reason
    // resetSearch inclusion causes the loading spinner to pop in and out
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessibilityFilter,
    serviceFilter,
    categoryValue,
    searchValue,
    setValue,
    unsetCategoryValue,
    searchLat,
    searchLng,
    overrideLat,
    overrideLng,
    searchContext,
    searchModel,
    radius,
  ]);

  // Automatically search when the user stops changing the search value
  const [debouncedSetSearch] = useDebouncedCallback(setSearch, 1000);

  function setSearch(search: string) {
    if (searchContext.getMapFocusCoords) {
      const [lng, lat] = searchContext.getMapFocusCoords();

      const location = routePaths.pois.toKeepingExistingParams({
        SEARCH: search,
        SEARCH_COORDINATES: [lat, lng],
        SEARCH_RADIUS: defaultSearchRadius,
      })(history.location);
      history.push(location);

      searchContext.mapHasMoved = false;
    }
  }

  // Still allow form submissions (eg. via the enter key)
  const onSubmit = handleSubmit((data: IFormData) => {
    setSearch(data.search);
  });

  const searchPlaceholder = categoryValue ? `Search ${pluraliseCategory(categoryValue)}` : 'Search';

  const shouldShowSearchFromHere =
    searchModel.state !== 'cleared' && searchContext.mapHasMoved && !showMapActionContextMenu;

  const isViewingPoiDetailsPage = !!useRouteMatch({
    path: routePaths.poi.template,
  });

  return (
    <div
      className={cn(className, styles.root, {
        [styles.preventSelect]: isSmallViewport && !!showList,
      })}>
      <section className={styles.search}>
        <h2 className={'visually-hidden'}>Keyword Search</h2>
        <form
          role="search"
          className={cn(styles.inputArea, {
            [styles.pinchZoomDisabled]: !showList && !environment.isViewportZoomed,
          })}
          ref={formRef}
          onSubmit={onSubmit}
          onReset={resetSearch}>
          <input
            type="search"
            name="search"
            ref={register}
            aria-label="Search"
            placeholder={searchPlaceholder}
            autoComplete="off"
            onChange={e => debouncedSetSearch(e.target.value)}
            onFocus={() => {
              unsetShowMapActionContextMenu();
              isSmallViewport && setShowList('y');
              history.push(routePaths.pois.toKeepingExistingParams()(history.location));
            }}
            autoFocus={!isSmallViewport && !showLandingPage && !isViewingPoiDetailsPage}
            tabIndex={1}
          />
          {searchResettable ? (
            <button
              className={styles.inputAction}
              type="reset"
              name="clear"
              aria-label="Clear Search"
              tabIndex={2}>
              <Icon name="times" />
            </button>
          ) : (
            <button
              className={styles.inputAction}
              onClick={onSubmit}
              tabIndex={2}>
              <Icon name="search" />
            </button>
          )}
          <button
            type="submit"
            className={cn(styles.searchFromHere, styles.preventSelect, {
              [styles.showSearchFromHere]: shouldShowSearchFromHere,
              [styles.poiSelected]: !!selectedPoi,
              [styles.listView]: showList,
            })}
            tabIndex={!shouldShowSearchFromHere ? -1 : undefined}>
            <span>Search This Area</span>
            <Icon name="place-marker" />
          </button>
        </form>
      </section>
      <div
        className={cn(styles.results, {
          [styles.showList]: showList,
          [styles.searchLoaded]: searchModel.state === 'some-found',
          [styles.preventSelect]: isSmallViewport && !showList,
        })}
        ref={resultsRef}
        onTouchStart={hideMobileKeyboard}>
        <div
          aria-live="polite"
          className={cn('visually-hidden', styles.accessibilityResultsSection)}>
          <p>
            {(store.search.state === 'some-found' || store.search.state === 'none-found') &&
              `${searchModel.count} result${searchModel.count === 1 ? '' : 's'} found`}
          </p>
        </div>
        <CategoriesPanel />
        <LoadingPane
          className={cn(styles.loadingPane, {
            [styles.searchActive]: store.search.state !== 'cleared',
          })}
          isLoading={searchModel.state === 'loading'}>
          <AreasPanel />
          <PoisPanel />
          {!pointsOfInterestRepo.count && Number(radius) >= maxSearchRadius && (
            <NoPoiResults clearSearch={resetSearch} />
          )}
        </LoadingPane>
      </div>
    </div>
  );
});
