import React from 'react';
import { observer } from 'mobx-react-lite';
import { useDebouncedCallback } from 'use-debounce';
import styles from './Autocomplete.module.scss';
import { IAddressDtoModel } from 'api/models/Domain/Queries/Address/FuzzyAddressSearchQuery/AddressDtoModel';

interface IAutocompleteProps {
    className?: string;
    suggestions?: IAddressDtoModel[];
    defaultSuggestion: IAddressDtoModel;
    name: string;
    label: string;
    placeholder: string;
    setActiveSuggestion: (suggestion: IAddressDtoModel) => void;
    setSearch: (search: string) => void;
    register: (Ref: HTMLInputElement) => void;
}

export const Autocomplete: React.FC<IAutocompleteProps> = observer(function ({
    name,
    label,
    placeholder,
    suggestions,
    defaultSuggestion,
    setActiveSuggestion,
    setSearch,
    register
  }) {
    const [debouncedSetSearch] = useDebouncedCallback(setSearch, 350);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = React.useState(-1);

    const onClick = (data: IAddressDtoModel) => {
        setActiveSuggestionIndex(-1);
        setActiveSuggestion(data);
    }

    const onChange = (text: string) => {
        setActiveSuggestionIndex(-1);
        debouncedSetSearch(text);
    }

    const onKeyDown = (keyCode: number) => {
        switch (keyCode) {
            case 13: // Enter key
                if (suggestions)
                {
                    const suggestion = suggestions[activeSuggestionIndex];
                    setActiveSuggestion(suggestion);
                }
                setActiveSuggestionIndex(-1);
                break;
            case 38: // Up Arrow
                if (activeSuggestionIndex > 0) {
                    setActiveSuggestionIndex(activeSuggestionIndex - 1);
                }
                break;
            
            case 40: // Down Arrow
                if(suggestions && activeSuggestionIndex < suggestions.length - 1) {
                    setActiveSuggestionIndex(activeSuggestionIndex + 1);
                }
                break;
        
            default:
                break;
        }
    }

    let suggestionsListComponent;

    if (suggestions && suggestions.length) {
        suggestionsListComponent = (
            <div className={styles.suggestionsList}>
                <ul className={styles.suggestions}>
                    {suggestions.map((suggestion, index) => {
                        let className = styles.suggestion;

                        if (index === activeSuggestionIndex) {
                            className = styles.activeSuggestion;
                        }

                        return (
                            <li className={className} key={suggestion.key} onClick={() => onClick(suggestion)}>
                            {suggestion.key}
                            </li>
                        );
                    })}
                    <li className={styles.defaultSuggestion} key={defaultSuggestion.key} onClick={() => onClick(defaultSuggestion)}>
                        {defaultSuggestion.key}
                    </li>
                </ul>
            </div>
        );
      }
  
    return (
        <div className={styles.root}>
            <label htmlFor={name}>
                {label}
            </label>
            <input
                tabIndex={0}
                name={name}
                type="text"
                autoComplete="new-password"
                ref={register}
                onChange={e => onChange(e.target.value)}
                onKeyDown={e => onKeyDown(e.keyCode)}
                placeholder={placeholder}
            />
            {suggestions && suggestionsListComponent}
        </div>
    );
  });