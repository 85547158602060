import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { useForm } from 'react-hook-form';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { useStore, useUrlParam } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import { allMobilityAid, MobilityAid } from 'api/enums/MobilityAid';
import { Select } from 'views/components/forms/select/Select';
import { ImageFilePicker, ImageFile } from 'views/components/forms/imageFilePicker/ImageFilePicker';
import { TextArea } from 'views/components/forms/textArea/TextArea';
import { StarRatingInput } from 'views/components/forms/starRatingInput/StarRatingInput';
import { MapContext } from 'views/routes/pois/Search';
import { BackButton } from 'views/components/detail/backButton/BackButton';

interface IFormData {
  reviewText: string;
  mobilityAidUsed: MobilityAid;
  photos: Array<ImageFile>;
  accessibilityRating: string;
  serviceRating: string;
}

export const CreateReview: React.FC = observer(function CreateReview() {
  const { id } = useParams();
  const store = useStore();
  const reviewsRepo = store.reviewsRepo;
  const pointsOfInterestRepo = store.pointsOfInterestRepo;
  const security = store.security;
  const userMobilityAid = security.userProfile && security.userProfile.mobilityAid;
  const history = useHistory();
  const [mobilityAidFilter] = useUrlParam(urlParamNames.review.MOBILITY_AID);
  const mapContext = useContext(MapContext);

  const { register, handleSubmit, errors, setValue, watch, getValues, control } = useForm<
    IFormData
  >({
    defaultValues: {
      reviewText: '',
      mobilityAidUsed: userMobilityAid,
      accessibilityRating: '',
      serviceRating: '',
    },
  });

  React.useEffect(() => {
    setValue('mobilityAidUsed', userMobilityAid);
  }, [setValue, userMobilityAid]);

  const goBack = (
    <BackButton
      onClick={() => {
        history.push(routePaths.poi.toKeepingExistingParams(id || '')(history.location));
      }}
    />
  );

  // Watching the specified form elements for changes
  const selectedAccessibilityRating = watch('accessibilityRating');
  const selectedServiceRating = watch('serviceRating');

  async function onSubmit(data: IFormData) {
    if (id === undefined) return;
    await reviewsRepo.createReview(
      id,
      data.reviewText,
      Number(data.mobilityAidUsed),
      data.photos.filter(p => p.isFile && !p.isDeleted).map(p => p as File),
      data.accessibilityRating,
      data.serviceRating
    );
  }

  async function afterSubmit() {
    if (id === undefined) return;
    reviewsRepo.loadReviews(id, 1, mobilityAidFilter ? Number(mobilityAidFilter) : undefined);
    await pointsOfInterestRepo.load(id);
    mapContext.forceReloadSingleMapMarker && mapContext.forceReloadSingleMapMarker(id);
    history.push(routePaths.poi.toKeepingExistingParams(id)(history.location));
  }

  return (
    <SubpageForm
      control={control}
      leftAction={goBack}
      title="Review"
      titleLeftAligned
      onSubmit={handleSubmit(onSubmit)}
      afterSubmit={afterSubmit}
      encType="multipart/form-data"
      submitText="Post Review"
      firstTagToFocus="button">
      <StarRatingInput
        register={register({
          required: 'Please rate accessibility',
        })}
        name="accessibilityRating"
        inputErrors={errors.accessibilityRating}
        required={true}
        label="Accessibility"
        getCurrentValue={() => getValues().accessibilityRating}
        resetCurrentValue={() => setValue('accessibilityRating', undefined)}
        selected={selectedAccessibilityRating}
      />
      <StarRatingInput
        register={register()}
        name="serviceRating"
        inputErrors={errors.serviceRating}
        required={false}
        label="Service"
        getCurrentValue={() => getValues().serviceRating}
        resetCurrentValue={() => setValue('serviceRating', undefined)}
        selected={selectedServiceRating}
      />
      <Select
        selectErrors={errors.mobilityAidUsed}
        name="mobilityAidUsed"
        required={true}
        register={register({
          required: 'Please select a mobility aid',
        })}
        selections={[
          { value: '', description: 'Select one...' },
          ...allMobilityAid.map(d => {
            return {
              value: String(d.value),
              description: d.description,
            };
          }),
        ]}
        label="Mobility Aid Used"
      />
      <TextArea
        textAreaErrors={errors.reviewText}
        name="reviewText"
        register={register({
          required: { value: true, message: 'Review text is required' },
          pattern: { value: /\S+/, message: 'Review text cannot be empty' },
        })}
        label="Review"
        maxLength={2500}
        placeholder="Enter your review here. Remember to highlight accessibility features!"
        rows={4}
        required={true}
      />
      <ImageFilePicker
        name="photos"
        maxImages={15}
        label="Add photos"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
      />
    </SubpageForm>
  );
});
