import React from 'react';
import styles from './LandscapeDeviceWarning.module.scss';
import { Icon } from 'views/components/Icon';

export const LandscapeDeviceWarning: React.FC = function () {
  const [dismissed, setDismissed] = React.useState(false);

  if (dismissed) return null;

  return (
    <div className={styles.root}>
      <Icon name="sync" className={styles.spinIcon} />
      <div className={styles.message}>
        <span>Please rotate your device to portrait mode for the best experience</span>
        <button onClick={() => setDismissed(true)} className={styles.dismissButton}>
          Dismiss
        </button>
      </div>
    </div>
  );
};
