import React from 'react';
import styles from './Icon.module.scss';
import cn from 'classnames';
import { ReactComponent as Hanger } from 'images/icons/icons8-hanger.svg';
import { ReactComponent as WebDesign } from 'images/icons/icons8-web_design.svg';
import { ReactComponent as GuinnessBeer } from 'images/icons/icons8-guinness_beer.svg';
import { ReactComponent as CoffeeToGo } from 'images/icons/icons8-coffee_to_go.svg';
import { ReactComponent as Parking } from 'images/icons/icons8-parking.svg';
import { ReactComponent as GasStation } from 'images/icons/icons8-gas_station.svg';
import { ReactComponent as Car } from 'images/icons/icons8-car.svg';
import { ReactComponent as Bust } from 'images/icons/icons8-bust.svg';
import { ReactComponent as Evergreen } from 'images/icons/icons8-evergreen.svg';
import { ReactComponent as Stethoscope } from 'images/icons/icons8-stethoscope.svg';
import { ReactComponent as Dumbbell } from 'images/icons/icons8-dumbbell.svg';
import { ReactComponent as Hospital3 } from 'images/icons/icons8-hospital_3.svg';
import { ReactComponent as Hospital } from 'images/icons/icons8-hospital.svg';
import { ReactComponent as BankBuilding } from 'images/icons/icons8-bank_building.svg';
import { ReactComponent as VegetarianFood } from 'images/icons/icons8-vegetarian_food.svg';
import { ReactComponent as Bus } from 'images/icons/icons8-bus.svg';
import { ReactComponent as Tram } from 'images/icons/icons8-tram.svg';
import { ReactComponent as Train } from 'images/icons/icons8-train.svg';
import { ReactComponent as Spa } from 'images/icons/icons8_spa.svg';
import { ReactComponent as FerrisWheel } from 'images/icons/icons8_ferris_wheel.svg';
import { ReactComponent as Cinema } from 'images/icons/icons8_cinema.svg';
import { ReactComponent as Music } from 'images/icons/icons8_music.svg';
import { ReactComponent as Tooth } from 'images/icons/icons8_tooth.svg';
import { ReactComponent as ShoppingCart } from 'images/icons/icons8-shopping_cart.svg';
import { ReactComponent as Ticket } from 'images/icons/icons8_ticket.svg';
import { ReactComponent as Taxi } from 'images/icons/icons8_taxi.svg';
import { ReactComponent as Info } from 'images/icons/icons8_info.svg';
import { ReactComponent as PlaceMarker } from 'images/icons/icons8_place_marker.svg';
import { ReactComponent as Loading } from 'images/icons/icons8-loading.svg';
import { ReactComponent as Cutlery } from 'images/icons/icons8_cutlery.svg';
import { ReactComponent as GoogleSignInIcon } from 'images/icons/icons8-google.svg';
import { ReactComponent as FacebookSignInIcon } from 'images/icons/icons8-facebook.svg';
import { ReactComponent as SignInIcon } from 'images/icons/Sign in Icon.svg';
import { ReactComponent as ChevronDown } from 'images/icons/chevron-down.svg';
import { ReactComponent as Add } from 'images/icons/icons8-add.svg';
import { ReactComponent as CheckBoxUnChecked } from 'images/icons/un-checked.svg';
import { ReactComponent as CheckBoxChecked } from 'images/icons/checked.svg';
import { ReactComponent as Review } from 'images/icons/review.svg';
import { ReactComponent as Nearby } from 'images/icons/nearby.svg';
import { ReactComponent as Filter } from 'images/icons/filter.svg';
import { ReactComponent as Bin } from 'images/icons/icons8-trash.svg';
import { ReactComponent as Star } from 'images/icons/star.svg';
import { ReactComponent as Service } from 'images/icons/service.svg';
import { ReactComponent as AddPhoto } from 'images/icons/Add Photo.svg';
import { ReactComponent as NoResults } from 'images/icons/noResults.svg';
import { ReactComponent as MoreVert } from 'images/icons/more_vert-24px.svg';
import { ReactComponent as ShareIcon } from 'images/icons/share_icon.svg';
import { ReactComponent as Caduceus } from 'images/icons/icons8-caduceus.svg';
import { ReactComponent as OrganTransplantation } from 'images/icons/icons8-organ-transplantation.svg';
import { ReactComponent as HeartWithPulse } from 'images/icons/icons8-heart-with-pulse.svg';
import { ReactComponent as ForwardArrow } from 'images/icons/icons8-forward-arrow.svg';

export type IconName = FontIcon | SvgIcon;
type SvgComponentType = typeof Hanger;

type FontIcon =
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'toilet'
  | 'map-marker'
  | 'external-link-alt'
  | 'times'
  | 'bed'
  | 'user'
  | 'map-marked'
  | 'search'
  | 'list'
  | 'wheelchair'
  | 'check'
  | 'sliders-h'
  | 'phone'
  | 'envelope'
  | 'bus-alt'
  | 'dolly'
  | 'umbrella-beach'
  | 'angle-up'
  | 'exclamation-triangle'
  | 'sync'
  | 'plus'
  | 'minus';

type SvgIcon =
  | 'hanger'
  | 'web-design'
  | 'guinness-beer'
  | 'coffee-to-go'
  | 'parking'
  | 'gas-station'
  | 'car'
  | 'bust'
  | 'evergreen'
  | 'stethoscope'
  | 'dumbbell'
  | 'hospital-3'
  | 'hospital'
  | 'bank-building'
  | 'vegetarian-food'
  | 'bus'
  | 'tram'
  | 'train'
  | 'spa'
  | 'ferris-wheel'
  | 'cinema'
  | 'music'
  | 'tooth'
  | 'shopping-cart'
  | 'ticket'
  | 'taxi'
  | 'info'
  | 'campground'
  | 'place-marker'
  | 'loading'
  | 'cutlery'
  | 'sign-in-icon'
  | 'google-sign-in-icon'
  | 'facebook-sign-in-icon'
  | 'chevron-down'
  | 'add'
  | 'checkbox-unchecked'
  | 'checkbox-checked'
  | 'review'
  | 'nearby'
  | 'filter'
  | 'bin'
  | 'star'
  | 'service'
  | 'add-photo'
  | 'no-results'
  | 'more-vert'
  | 'share-icon'
  | 'caduceus'
  | 'organ-transplantation'
  | 'heart-with-pulse'
  | 'forward-arrow';

export interface IIconProps {
  className?: string;
  name: IconName;
}

const svgMapping: [SvgIcon, SvgComponentType][] = [
  ['hanger', Hanger],
  ['web-design', WebDesign],
  ['guinness-beer', GuinnessBeer],
  ['coffee-to-go', CoffeeToGo],
  ['parking', Parking],
  ['gas-station', GasStation],
  ['car', Car],
  ['bust', Bust],
  ['evergreen', Evergreen],
  ['stethoscope', Stethoscope],
  ['dumbbell', Dumbbell],
  ['hospital-3', Hospital3],
  ['hospital', Hospital],
  ['bank-building', BankBuilding],
  ['vegetarian-food', VegetarianFood],
  ['bus', Bus],
  ['tram', Tram],
  ['train', Train],
  ['spa', Spa],
  ['ferris-wheel', FerrisWheel],
  ['cinema', Cinema],
  ['music', Music],
  ['tooth', Tooth],
  ['shopping-cart', ShoppingCart],
  ['ticket', Ticket],
  ['taxi', Taxi],
  ['info', Info],
  ['place-marker', PlaceMarker],
  ['loading', Loading],
  ['cutlery', Cutlery],
  ['sign-in-icon', SignInIcon],
  ['google-sign-in-icon', GoogleSignInIcon],
  ['facebook-sign-in-icon', FacebookSignInIcon],
  ['chevron-down', ChevronDown],
  ['add', Add],
  ['checkbox-unchecked', CheckBoxUnChecked],
  ['checkbox-checked', CheckBoxChecked],
  ['review', Review],
  ['nearby', Nearby],
  ['filter', Filter],
  ['bin', Bin],
  ['star', Star],
  ['service', Service],
  ['add-photo', AddPhoto],
  ['no-results', NoResults],
  ['more-vert', MoreVert],
  ['share-icon', ShareIcon],
  ['caduceus', Caduceus],
  ['organ-transplantation', OrganTransplantation],
  ['heart-with-pulse', HeartWithPulse],
  ['forward-arrow', ForwardArrow]
];

const svgMap = new Map<IconName, SvgComponentType>(svgMapping);

export const Icon: React.FC<IIconProps> = function ({ className, name }) {
  const SvgIcon = svgMap.get(name);

  return (
    <span
      className={cn(styles.root, className, { las: !SvgIcon, [`la-${name}`]: !SvgIcon })}
      aria-hidden="true">
      {!!SvgIcon && <SvgIcon focusable={false} />}
    </span>
  );
};
