import { Location } from 'history';

export function getParamFromLocation<T>(location: Location<T>, name: string) {
  const params = new URLSearchParams(location.search);
  return params.get(name);
}

export function addParamToLocation<T>(
  location: Location<T>,
  name: string,
  value: string
): Location<T> {
  const params = new URLSearchParams(location.search);
  if (!params.has(name) || params.get(name) !== value) {
    params.append(name, value);
  }
  return { ...location, search: params.toString() };
}

export function setParamInLocation<T>(
  location: Location<T>,
  name: string,
  value: string
): Location<T> {
  const params = new URLSearchParams(location.search);
  if (params.has(name)) {
    params.delete(name);
  }
  params.append(name, value);
  return { ...location, search: params.toString() };
}

export function removeParamFromLocation<T>(location: Location<T>, name: string): Location<T> {
  const params = new URLSearchParams(location.search);
  params.delete(name);
  return { ...location, search: params.toString() };
}
