import { ValidationOptions } from 'react-hook-form';
import React from 'react';
import styles from './Checkbox.module.scss';
import { Icon } from 'views/components/Icon';
import cn from 'classnames';

interface ICheckBoxProps {
  register: (Ref: HTMLInputElement, validateRule?: ValidationOptions) => void;
  name: string;
  title?: string;
  label?: string;
  className?: string;
  checked?: boolean;
}

export const CheckBox: React.FC<ICheckBoxProps> = function ({
  register,
  label,
  name,
  title,
  className,
  checked
}) {
  const [isChecked, setIsChecked] = React.useState<boolean>(!!checked);

  return (
    <div className={cn(styles.root, className)}>
      {title && <span className={styles.title}>{title}</span>}
      <label>
        {label && <span>{label}</span>}
        <input
          ref={register}
          type="checkbox"
          name={name}
          className="visually-hidden"
          onChange={e => setIsChecked(e.target.checked)}
        />
        <Icon name={isChecked ? 'checkbox-checked' : 'checkbox-unchecked'} />
      </label>
    </div>
  );
};
