import React from 'react';
import styles from './IconList.module.scss';
import { IIconProps, Icon } from 'views/components/Icon';
import { Link } from 'react-router-dom';

export interface IItem {
  iconProps: IIconProps;
  label: string;
  value?: string | JSX.Element;
  valueSuffix?: string;
  linkTo?: string;
  externalLink?: boolean;
}

interface IIconListProps {
  items: (IItem | null)[];
}

export const IconList: React.FC<IIconListProps> = function ({ items }) {
  return (
    <ul className={styles.root}>
      {items
        .filter((i): i is IItem => !!i?.value)
        .map(i => {
          const [Wrapper, wrapperProps] = getItemWrapper(i, styles.wrapper);
          return (
            <li key={i.label} className={styles.item}>
              <Icon {...i.iconProps} />
              <Wrapper {...wrapperProps}>
                <span className={styles.value}>
                  {i.value}
                  {i.valueSuffix && <span className={styles.valueSuffix}> — {i.valueSuffix}</span>}
                </span>
              </Wrapper>
            </li>
          );
        })}
    </ul>
  );
};

function getItemWrapper(
  item: IItem,
  className: string
): ['a' | typeof React.Fragment | typeof Link, any] {
  const defaultProps = { className };
  if (!item.linkTo) {
    return [React.Fragment, {}];
  } else if (item.externalLink) {
    return [
      'a',
      { ...defaultProps, href: item.linkTo, target: '_blank', rel: 'noopener noreferrer' },
    ];
  }
  return [Link, { ...defaultProps, to: item.linkTo }];
}
