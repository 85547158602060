import { flow, types } from 'mobx-state-tree';
import { ISendUserFeedbackCommandModel } from 'api/models/Domain/Aggregates/Feedback/Commands/SendUserFeedbackCommandModel';
import { getAjax } from 'domain/store/RootStoreModel';
import ky from 'ky';

export const FeedbackModel = types.model().actions(self => {
  const sendFeedback = function* (command: ISendUserFeedbackCommandModel) {
    try {
      yield getAjax(self).post('/api/feedback', {
        json: command,
      });
    } catch (error) {
      if (error instanceof ky.HTTPError && error.response.status === 400) {
        throw new Error('Feedback not sent successfully. Please try again.');
      }
      throw error;
    }
  };

  return {
    sendFeedback: flow(sendFeedback),
  };
});
