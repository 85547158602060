import React from 'react';
import styles from './Reviews.module.scss';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStore, useUrlParam } from 'views/hooks';
import { ReviewItem } from './ReviewItem';
import { useHistory } from 'react-router-dom';
import { urlParams } from 'views/components/application/Shell';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { Select } from 'views/components/forms/select/Select';
import { allMobilityAid, MobilityAid, mobilityAidDescription } from 'api/enums/MobilityAid';
import { useForm } from 'react-hook-form';
import { Subsection } from 'views/components/layout/Subpage';
import { LoadingPane } from 'views/components/LoadingPane';
import { Icon } from 'views/components/Icon';

interface IReviewsPanelProps {
  poiID: string;
}

interface IFormData {
  mobilityAidFilter: MobilityAid;
}

export const Reviews: React.FC<IReviewsPanelProps> = observer(function Reviews({ poiID }) {
  const store = useStore();
  const reviewRepo = store.reviewsRepo;
  const reviews = reviewRepo.reviews;
  const reviewsFound = !!reviews.length;
  const security = store.security;
  const history = useHistory();

  const currentPage = reviewRepo.currentPage;
  const lastPage = reviewRepo.isLastPage;
  const firstPage = currentPage === 1;

  const noMobilityAidFilter = 0;
  const showMobilityAidSelect = !!store.pointsOfInterestRepo.pointOfInterestDetails?.reviewCount;

  const scrollAnchorRef = React.useRef<HTMLHeadingElement | null>(null);

  const [mobilityAidParam, setMobilityAidParam, unsetMobilityAidParam] = useUrlParam(
    urlParamNames.review.MOBILITY_AID
  );
  const mobilityAidFilter = Number(mobilityAidParam);

  const [, setShowProfile] = useUrlParam(urlParams.SHOW_PROFILE);

  const { register, errors, getValues, setValue } = useForm<IFormData>();

  React.useEffect(() => {
    reviewRepo.loadReviews(poiID, 1, mobilityAidFilter);
    setValue('mobilityAidFilter', mobilityAidFilter);
  }, [mobilityAidFilter, poiID, reviewRepo, setValue]);

  async function changePage(poiID: string, newPage: number) {
    await reviewRepo.loadReviews(poiID, newPage, mobilityAidFilter);
    scrollAnchorRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  let noneFoundMessage = 'There are no reviews ';
  switch (mobilityAidFilter) {
    case noMobilityAidFilter:
      break;
    case MobilityAid.Crutches:
      noneFoundMessage += `from people using ${mobilityAidDescription(
        mobilityAidFilter
      ).toLowerCase()} `;
      break;
    case MobilityAid.None:
      noneFoundMessage += 'from people not using mobility aids ';
      break;
    default:
      noneFoundMessage += `from people using a ${mobilityAidDescription(
        mobilityAidFilter
      ).toLowerCase()} `;
  }

  const leaveReviewPrompt = (showText: boolean = true) => {
    return (
      <div className={styles.addReviewPrompt}>
        <Icon name="review" className={styles.addReviewIcon} />
        {showText && <span>{noneFoundMessage} for this place yet, why not add one?</span>}
        <button
          className={styles.linkPrompt}
          aria-label={security.isLoggedIn ? 'Add review' : 'Sign in to add review'}
          onClick={() => {
            if (security.isLoggedIn) {
              history.push(
                routePaths.createReview.toKeepingExistingParams(poiID)(history.location)
              );
            } else {
              setShowProfile('y');
              history.push(routePaths.poi.toKeepingExistingParams(poiID)(history.location));
            }
          }}>
          {security.isLoggedIn ? 'Leave a review' : 'Sign in to leave a review'}
        </button>
      </div>
    );
  };

  return (
    <Subsection className={styles.reviewSection}>
      <div className="visually-hidden" aria-hidden ref={scrollAnchorRef} />
      {showMobilityAidSelect && (
        <Select
          label="Show reviews from"
          className={styles.filter}
          selectErrors={errors.mobilityAidFilter}
          name="mobilityAidFilter"
          register={register}
          selections={[
            { value: noMobilityAidFilter, description: 'Everyone' },
            ...allMobilityAid.map(d => {
              return {
                value: String(d.value),
                description:
                  d.value === MobilityAid.None
                    ? 'People not using mobility aids'
                    : d.value === MobilityAid.Crutches
                    ? 'Crutch users'
                    : d.description + ' users',
              };
            }),
          ]}
          onChange={() => {
            Number(getValues().mobilityAidFilter) === noMobilityAidFilter
              ? unsetMobilityAidParam()
              : setMobilityAidParam(getValues().mobilityAidFilter.toString());
          }}
          icon="filter"
        />
      )}
      <LoadingPane
        isLoading={reviewRepo.isLoadingReviews}
        className={cn({ [styles.loadingPane]: reviewRepo.isLoadingReviews })}>
        {reviewsFound ? (
          <>
            <ul aria-label={'Reviews'} className={styles.reviews}>
              {reviews.map(r => (
                <li key={r.id}>
                  <ReviewItem review={r} poiID={poiID} />
                </li>
              ))}
            </ul>
            <div className={styles.reviewNavigation}>
              {!firstPage && (
                <button className={styles.back} onClick={() => changePage(poiID, currentPage - 1)}>
                  Back
                </button>
              )}
              {!lastPage && (
                <button className={styles.next} onClick={() => changePage(poiID, currentPage + 1)}>
                  Next
                </button>
              )}
            </div>
            {!security.isLoggedIn && reviewsFound && (
              <div className={styles.signInPromptContainer}>{leaveReviewPrompt(false)}</div>
            )}
          </>
        ) : (
          leaveReviewPrompt()
        )}
      </LoadingPane>
    </Subsection>
  );
});
