import React from 'react';
import cn from 'classnames';
import styles from './FloatingBackButton.module.scss';
import { BackButton } from 'views/components/detail/backButton/BackButton';

interface IFloatingBackButtonProps {
  className?: string;
  hidden: boolean;
  closePage: () => void;
}

export const FloatingBackButton: React.FC<IFloatingBackButtonProps> = function ({
  className,
  hidden,
  closePage,
}) {
  return (
    <BackButton
      className={cn(className, styles.root, { [styles.hidden]: hidden })}
      onClick={closePage}
    />
  );
};
