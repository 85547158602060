import React, { useEffect, useRef } from 'react';
import styles from './EmailConsentNotification.module.scss';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { observer } from 'mobx-react-lite';
import { getMillisecondsFromCssTime } from 'infrastructure/cssUtils';
import { siteDefault } from 'views/themes';
import { FloatingBackButton } from 'views/components/detail/floatingBackButton/FloatingBackButton';
import { useStore } from 'views/hooks';
import { Input } from 'views/components/forms/input/Input';
import { useForm } from 'react-hook-form';
import { IUpdateUserEmailConsentCommandModel } from 'api/models/Domain/Aggregates/User/Commands/UpdateUserEmailConsentCommandModel';

interface EmailConsentNotificationProps {
  closeEvent: () => void;
}

interface IFormData {
  emailAddress?: string;
}

export const EmailConsentNotification: React.FC<EmailConsentNotificationProps> = observer(function({ closeEvent }) {
  const store = useStore();
  const security = store.security;
  const tinyScreen = store.environment.isTinyViewport;

  const contentContainer = useRef<HTMLElement | null>(null);
  const overlay = useRef<HTMLDivElement | null>(null);
  const [floatingCloseHidden, setFloatingCloseHidden] = React.useState(true);

  const onScrollEvent = () => {
    if (contentContainer.current) {
      setFloatingCloseHidden(contentContainer.current.scrollTop < 250);
    }
  };

  const { register, handleSubmit, errors, watch } = useForm<IFormData>({
    defaultValues: {
      emailAddress: security.userProfile?.emailAddress
    },
  });

  const emailProvided = !!watch('emailAddress');

  const saveAndClosePage = async () => {
    const emailAddress = security.userProfile && security.userProfile.emailAddress;
    await updateUserEmailConsent(emailAddress, false);

    closePage();
  };

  const closePage = () => {
    if (!contentContainer.current || !overlay.current) return;
    contentContainer.current.style.opacity = '0';
    overlay.current.style.opacity = '0';

    setTimeout(() => {
      closeEvent();
    }, getMillisecondsFromCssTime(siteDefault['--reveal-page-transition-time']));
  }

  const onSubmit = async (data: IFormData) => {
    await updateUserEmailConsent(data.emailAddress, true);
    closePage();
  }

  const updateUserEmailConsent = async (email: string | undefined, emailConsent: boolean) => {
    const data: IUpdateUserEmailConsentCommandModel = {
      emailAddress: email,
      emailConsent: emailConsent
    };

    await security.updateUserEmailConsentDetails(data);
  };

  document.addEventListener('keyup', e => {
    e.key === 'Escape' && saveAndClosePage();
  });

  useEffect(() => {
    contentContainer.current && contentContainer.current.getElementsByTagName('button')[0].focus();
  }, []);

  return (
    <>
      <div className={styles.overlay} onClick={saveAndClosePage} ref={overlay} />
      <article ref={contentContainer} className={styles.root} onScroll={onScrollEvent}>
        <BackButton
          className={styles.closeButton}
          onClick={() => {
            saveAndClosePage();
          }}
        />
        <h2>Hello again! We’d love to keep in touch!</h2>
        <p>
          Please provide your email address so we can share improvements and competitions with you.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
              inputErrors={errors.emailAddress}
              name="emailAddress"
              register={register({
                validate: () =>
                  emailProvided || 'Please provide an email address',
              })}
              label="Email"
              placeholder="Enter your email"
              type="email"
              defaultValue={security.userProfile && security.userProfile.emailAddress}
            />
          <div className={styles.largeActionButtonContainer}>
            <button type="submit" className={styles.largeActionButton}>
              Save
            </button>
            <button type="button" className={styles.largeActionButton} onClick={e => saveAndClosePage()}>
              Dismiss
            </button>
          </div>
        </form>

        {tinyScreen && (
          <FloatingBackButton
            className={styles.floatingBackButton}
            hidden={floatingCloseHidden}
            closePage={saveAndClosePage}
          />
        )}
      </article>
    </>
  );
});
