import { flow, getRoot, types } from 'mobx-state-tree';
import {
  PointOfInterestCategory,
  pointOfInterestCategoryDescription,
} from 'api/enums/PointOfInterestCategory';
import { IRootStoreModel } from 'domain/store/RootStoreModel';
import { observable } from 'mobx';

export const SearchModel = types.model('SearchModel', {}).extend(self => {
  const internalProperties = observable({
    state: 'cleared' as 'cleared' | 'loading' | 'complete',
  });

  function* performSearch(
    search: string,
    category: PointOfInterestCategory | undefined,
    minimumAccessibility: number | undefined,
    minimumService: number | undefined,
    searchLat: number,
    searchLng: number,
    searchDistance: number,
    overrideLatLong?: [number, number]
  ): Generator {
    const root: IRootStoreModel = getRoot(self);

    try {
      internalProperties.state = 'loading';

      root.categoriesRepo.search(search, !!category);
      yield Promise.all([
        root.areasRepo.search(search),
        root.pointsOfInterestRepo.search(
          search,
          category,
          minimumAccessibility,
          minimumService,
          searchLat,
          searchLng,
          searchDistance,
          overrideLatLong
        ),
      ]);

      root.analytics.recordSearch({
        response: 'success',
        searchTerm: search,
        category: category && pointOfInterestCategoryDescription(category),
        numberOfResults: root.pointsOfInterestRepo.searchResults.length,
        minimumAccessibility: minimumAccessibility,
        radius: searchDistance,
        searchLocation: [searchLat, searchLng],
      });
    } finally {
      // Duplicate searches will be ignored by the repos and will result in a complete Promise.all above,
      // causing state to be set to 'complete' while the initial search is still retrieving data. This will
      // prevent state from being set to 'complete' if any loading operation is still running.
      // If state is cleared, all searches have been aborted, so state should remain 'cleared'.
      internalProperties.state =
        internalProperties.state === 'cleared'
          ? 'cleared'
          : !root.areasRepo.isLoadingSearch && !root.pointsOfInterestRepo.isLoadingSearch
          ? 'complete'
          : 'loading';
    }
  }

  function clearSearch(): void {
    const root: IRootStoreModel = getRoot(self);

    root.pointsOfInterestRepo.clearSearch();
    root.categoriesRepo.clearSearch();
    root.areasRepo.clearSearch();
    internalProperties.state = 'cleared';
  }

  return {
    views: {
      get state() {
        if (internalProperties.state === 'complete') {
          const root: IRootStoreModel = getRoot(self);
          if (
            root.categoriesRepo.searchResults.length ||
            root.pointsOfInterestRepo.nearbySearchResults.length ||
            root.areasRepo.searchResults.length
          ) {
            return 'some-found';
          } else {
            return 'none-found';
          }
        }
        return internalProperties.state;
      },
      get count(): number {
        const root: IRootStoreModel = getRoot(self);

        return (
          root.pointsOfInterestRepo.count +
          root.categoriesRepo.searchResults.length +
          root.areasRepo.searchResults.length
        );
      },
    },
    actions: { performSearch: flow(performSearch), clearSearch },
  };
});
