import { cast, flow, types } from 'mobx-state-tree';
import { observable } from 'mobx';
import { getAjax } from 'domain/store/RootStoreModel';
import { ReviewDtoModel } from 'api/models/Domain/Queries/Reviews/GetReviewsQuery/ReviewDtoModel';
import { IGetReviewsQueryResultsModel } from 'api/models/Domain/Queries/Reviews/GetReviewsQuery/GetReviewsQueryResultsModel';
import { MobilityAid } from 'api/enums/MobilityAid';
import Compressor from 'compressorjs';
import { IReviewImageUrlsDtoModel } from 'api/models/Domain/Queries/SharedDtos/Reviews/ReviewImageUrlsDtoModel';
import ky from 'ky';

export const ReviewsRepo = types
  .model('ReviewsRepo', {
    reviews: types.optional(types.array(ReviewDtoModel), []),
    isLastPage: types.optional(types.boolean, true),
  })
  .extend(self => {
    const localState = observable({
      loadingReviews: true,
      postingReview: false,
      currentPage: 1,
    });

    function* createReview(
      pointOfInterestId: string,
      reviewText: string,
      mobilityAidUsed: MobilityAid,
      photos: Array<File>,
      accessibilityRating: string,
      serviceRating: string
    ): Generator {
      try {
        const formData = new FormData();
        localState.postingReview = true;

        formData.append('pointOfInterestId', pointOfInterestId);
        formData.append('reviewText', reviewText);
        formData.append('mobilityAidUsed', mobilityAidUsed.toString());
        formData.append('accessibilityRating', accessibilityRating);
        formData.append('serviceRating', serviceRating);

        if (photos.length) {
          yield Promise.all(processAndAddReviewImages(photos, formData));
        }

        yield getAjax(self).post('/api/reviews', {
          body: formData,
        });
      } catch (error) {
        if (error instanceof ky.HTTPError && error.response.status === 400) {
          throw new Error('Review not added. Please try again.');
        }
        throw error;
      } finally {
        localState.postingReview = false;
      }
    }

    function processAndAddReviewImages(photos: Array<File>, formData: FormData, name?: string) {
      const images = [];
      const numberOfUploads = Math.min(photos.length, 15);
      for (let i = 0; i < numberOfUploads; i++) {
        // https://github.com/fengyuanchen/compressorjs/blob/master/README.md
        images.push(
          new Promise((resolve, reject) => {
            new Compressor(photos[i], {
              quality: 0.6,
              maxWidth: 1080,
              maxHeight: 1080,
              mimeType: 'image/jpeg',
              success(photo: Blob) {
                formData.append(name || `reviewImages`, photo, `photo${i}`);
                resolve();
              },
              error() {
                reject(`Unable to process image ${photos[i].name}`);
              },
            });
          }),
          new Promise((resolve, reject) => {
            new Compressor(photos[i], {
              maxWidth: 200,
              maxHeight: 200,
              minWidth: 100,
              minHeight: 100,
              mimeType: 'image/jpeg',
              success(thumbNail: Blob) {
                formData.append(name || `reviewImages`, thumbNail, `thumbNail${i}`);
                resolve();
              },
              error() {
                reject(`Unable to process image ${photos[i].name}`);
              },
            });
          })
        );
      }
      return images;
    }

    function clearReviews() {
      self.reviews.clear();
    }

    function* loadReviews(pointOfInterestId: string, page: number, mobilityAid?: MobilityAid) {
      const url = `/api/reviews/?poiId=${pointOfInterestId}&page=${page}&mobilityAid=${
        mobilityAid || ''
      }`;

      try {
        clearReviews();
        localState.loadingReviews = true;
        const reviewQuery: IGetReviewsQueryResultsModel = yield getAjax(self).get(url).json();
        self.reviews = cast(reviewQuery.reviews);
        self.isLastPage = reviewQuery.isLastPage;
      } finally {
        localState.currentPage = page;
        localState.loadingReviews = false;
      }
    }

    function* updateReview(
      reviewId: string,
      reviewText: string,
      mobilityAidUsed: MobilityAid,
      accessibilityRating: string,
      serviceRating: string | undefined,
      imagesToAdd: File[],
      imagesToRemove: IReviewImageUrlsDtoModel[]
    ): Generator {
      try {
        const formData = new FormData();
        localState.postingReview = true;

        formData.append('reviewId', reviewId);
        formData.append('reviewText', reviewText);
        formData.append('mobilityAidUsed', mobilityAidUsed.toString());
        formData.append('accessibilityRating', accessibilityRating);

        imagesToRemove.forEach(p => {
          formData.append('imagesToRemove', p.thumbnailUrl);
          formData.append('imagesToRemove', p.imageUrl);
        });

        if (imagesToAdd.length) {
          yield Promise.all(processAndAddReviewImages(imagesToAdd, formData, 'imagesToAdd'));
        }

        serviceRating && formData.append('serviceRating', serviceRating);

        yield getAjax(self).put('/api/reviews/update', {
          body: formData,
        });
      } catch (error) {
        if (error instanceof ky.HTTPError && error.response.status === 400) {
          throw new Error('Review not updated. Please try again.');
        }
        throw error;
      } finally {
        localState.postingReview = false;
      }
    }

    return {
      views: {
        get isLoadingReviews() {
          return localState.loadingReviews || localState.postingReview;
        },
        get currentPage(): number {
          return localState.currentPage;
        },
        get isPostingReview() {
          return localState.postingReview;
        },
      },
      actions: {
        createReview: flow(createReview),
        loadReviews: flow(loadReviews),
        updateReview: flow(updateReview),
      },
    };
  });
