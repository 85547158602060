import { flow, types } from 'mobx-state-tree';
import { getAjax } from 'domain/store/RootStoreModel';
import publicIp from 'public-ip';
import { IGeoDataResultModel } from 'api/models/Domain/Services/Ipstack/GeoDataResultModel';

export const UserGeolocationModel = types
  .model('UserGeolocationModel', {
    cachedLatitude: types.maybe(types.number),
    cachedLongitude: types.maybe(types.number),
  })
  .extend(self => {
    function* updateGeolocation(timeout: number, exitEarlyWhenLocationNotAllowed = true) {
      if (exitEarlyWhenLocationNotAllowed && navigator.permissions) {
        const result: PermissionStatus = yield navigator.permissions.query({ name: 'geolocation' });
        if (result.state === 'prompt' || result.state === 'denied') return undefined;
      }

      const location = yield* getCurrentLocationFromSensor(timeout);
      if (location) {
        self.cachedLatitude = location[0];
        self.cachedLongitude = location[1];
        return location;
      }
      return undefined;
    }

    function* getCurrentLocation(timeout: number) {
      if (self.cachedLatitude && self.cachedLongitude) {
        return [self.cachedLatitude, self.cachedLongitude];
      }

      if (navigator.permissions) {
        const result: PermissionStatus = yield navigator.permissions.query({ name: 'geolocation' });
        if (result.state === 'prompt' || result.state === 'denied') return undefined;
      }

      const location = yield* getCurrentLocationFromSensor(timeout);
      return location ? [location[0], location[1]] : undefined;
    }

    function* getCurrentLocationFromSensor(timeout: number) {
      const r: [number, number] = yield new Promise(resolve => {
        navigator.geolocation.getCurrentPosition(
          result => resolve([result.coords.latitude, result.coords.longitude]),
          _ => resolve(undefined),
          { timeout: timeout, maximumAge: timeout }
        );
      });
      return r;
    }

    function* getCurrentLocationFromIpAddress() {
      try {
        const ipAddress = yield publicIp.v4();
        const location: IGeoDataResultModel = yield getAjax(self)
          .get(`api/geolocation?ipAddress=${ipAddress}`)
          .json();
        return [location.latitude, location.longitude];
      } catch {
        return undefined;
      }
    }

    return {
      actions: {
        getCurrentLocation: flow(getCurrentLocation),
        updateGeolocation: flow(updateGeolocation),
        getCurrentLocationFromIpAddress: flow(getCurrentLocationFromIpAddress),
      },
    };
  });
