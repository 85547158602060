import { CSSProperties } from 'react';

interface IAppCssProperties extends CSSProperties {
  '--error-rgb': string;
  '--on-error-rgb': string;
  '--warning-rgb': string;
  '--on-warning-rgb': string;
  '--information-rgb': string;
  '--on-information-rgb': string;
  '--success-rgb': string;
  '--on-success-rgb': string;
  '--background-rgb': string;
  '--on-background-rgb': string;
  '--map-marker-user-rgb': string;
  '--map-marker-new-poi-rgb': string;
  '--map-marker-poi-unrated-rgb': string;
  '--map-marker-poi-red-rgb': string;
  '--map-marker-poi-yellow-rgb': string;
  '--map-marker-poi-green-rgb': string;
  '--map-marker-text-rgb': string;
  '--on-map-marker-rgb': string;
  '--reveal-page-transition-time': string;
  '--reveal-action-transition-time': string;
  '--selected-star-rgb': string;
  '--image-gallery-filter': string;
  '--on-image-gallery-filter': string;
  '--deleted-image-overlay': string;
  '--on-deleted-image-overlay': string;
  '--empty-state-icon-border': string;
  '--image-placeholder-background': string;
  '--on-image-placeholder-background': string;
}

// Using raw RGB values so that different opacities can be applied in scss
// https://stackoverflow.com/a/41265350/4289902
export const siteDefault: IAppCssProperties = {
  '--error-rgb': '229, 115, 115',
  '--on-error-rgb': '17, 17, 17',
  '--warning-rgb': '255, 198, 53',
  '--on-warning-rgb': '17, 17, 17',
  '--information-rgb': '204, 204, 204 ',
  '--on-information-rgb': '17, 17, 17',
  '--success-rgb': '129, 199, 132',
  '--on-success-rgb': '17, 17, 17',
  '--background-rgb': '255, 255, 255',
  '--on-background-rgb': '0, 90, 131',
  '--map-marker-user-rgb': '0, 90, 131',
  '--map-marker-new-poi-rgb': '235, 103, 101',
  '--map-marker-poi-unrated-rgb': '0, 90, 131',
  '--map-marker-poi-red-rgb': '235, 103, 101',
  '--map-marker-poi-yellow-rgb': '255, 198, 53',
  '--map-marker-poi-green-rgb': '20, 158, 158',
  '--map-marker-text-rgb': '0, 0, 0',
  '--on-map-marker-rgb': '255, 255, 255',
  '--reveal-page-transition-time': '0.3s',
  '--reveal-action-transition-time': '0.3s',
  '--selected-star-rgb': '255, 200, 0',
  '--image-gallery-filter': '0, 90, 131',
  '--on-image-gallery-filter': '255, 255, 255',
  '--deleted-image-overlay': '255, 255, 255',
  '--on-deleted-image-overlay': '139, 0, 0',
  '--empty-state-icon-border': '218, 218, 218',
  '--image-placeholder-background': '255, 248, 246',
  '--on-image-placeholder-background': '0, 90, 131',
};

export function applyToDocumentBody(properties: IAppCssProperties) {
  Object.entries(properties).forEach(([name, value]) => {
    document.body.style.setProperty(name, value);
  });
}

export function rgbToCssColor(rgb: string) {
  return `RGB(${rgb})`;
}
