import { cast, flow, getRoot, types } from 'mobx-state-tree';
import { observable } from 'mobx';
import { getAjax, IRootStoreModel } from 'domain/store/RootStoreModel';
import {
  PointOfInterestDtoModel as SearchDtoModel,
  IPointOfInterestDtoModel as ISearchDtoInternal,
} from 'api/models/Domain/Queries/PointOfInterest/SearchPointsOfInterestQuery/PointOfInterestDtoModel';
import {
  PointOfInterestDtoModel as GetDtoModel,
  IPointOfInterestDtoModel as IGetDto,
} from 'api/models/Domain/Queries/PointOfInterest/GetPointOfInterestQuery/PointOfInterestDtoModel';
import { PointOfInterestCategory } from 'api/enums/PointOfInterestCategory';
import { IAddUserGeneratedPoiCommandModel } from 'api/models/Domain/Aggregates/PointOfInterest/Commands/AddUserGeneratedPoiCommandModel';
import ky from 'ky';

type ISearchPointsOfInterestQueryResults = Domain.Queries.PointOfInterest.SearchPointsOfInterestQuery.ISearchPointsOfInterestQueryResults;

export type ISearchDto = ISearchDtoInternal;

interface ILoadingAbortableProcess<TKey> {
  key: TKey;
  abortController: AbortController;
}

export const PointsOfInterestRepo = types
  .model('PointsOfInterestRepo', {
    nearbySearchResults: types.optional(types.array(SearchDtoModel), []),
    distantSearchResults: types.optional(types.array(SearchDtoModel), []),
    pointOfInterestDetails: types.maybe(GetDtoModel),
    isMaxNearbyResults: types.optional(types.boolean, false),
  })
  .extend(self => {
    const localState = observable({
      currentSearch: null as ILoadingAbortableProcess<{
        search: string;
        category: PointOfInterestCategory | undefined;
        minimumAccessibility: number | undefined;
        minimumService: number | undefined;
        searchLocation: { lat: Number; lng: Number };
      }> | null,
      loadingPointOfInterestDetails: true,
      submittingNewPointOfInterest: false,
      isReportingPoi: false,
    });

    function* getLatLong(): Generator {
      return yield getRoot<IRootStoreModel>(self).geolocation.getCurrentLocation(5000);
    }

    function clearSearch() {
      self.nearbySearchResults.clear();
      self.distantSearchResults.clear();
      self.pointOfInterestDetails = undefined;
      localState.currentSearch?.abortController.abort();
    }

    function* reportPoi(pointOfInterestId: string, reportReason: string) {
      try {
        const formData = new FormData();
        localState.isReportingPoi = true;

        formData.append('pointOfInterestId', pointOfInterestId);
        formData.append('reportReason', reportReason);

        yield getAjax(self).post('/api/points-of-interest/report', {
          body: formData,
        });
      } catch (error) {
        if (error instanceof ky.HTTPError && error.response.status === 400) {
          throw new Error('Report not submitted. Please try again.');
        }
        throw error;
      } finally {
        localState.isReportingPoi = false;
      }
    }

    function* search(
      search: string,
      category: PointOfInterestCategory | undefined,
      minimumAccessibility: number | undefined,
      minimumService: number | undefined,
      searchLat: Number,
      searchLng: Number,
      radius: Number,
      overrideUserLatLng?: [Number, Number]
    ) {
      if (localState.currentSearch) {
        const key = localState.currentSearch.key;
        if (
          key.search === search &&
          key.category === category &&
          key.minimumAccessibility === minimumAccessibility &&
          key.minimumService === minimumService &&
          key.searchLocation.lat === searchLat &&
          key.searchLocation.lng === searchLng
        ) {
          return;
        }
        localState.currentSearch.abortController.abort();
      }

      try {
        clearSearch();
        localState.currentSearch = {
          key: {
            search,
            category,
            minimumAccessibility,
            minimumService,
            searchLocation: { lat: searchLat, lng: searchLng },
          },
          abortController: new AbortController(),
        };

        const [userLat, userLng] = overrideUserLatLng || (yield* getLatLong()) || ['', ''];

        const data = {
          search: search,
          category: category !== undefined ? category.toString() : '',
          searchLat: searchLat.toString(),
          searchLng: searchLng.toString(),
          userLat: userLat.toString(),
          userLng: userLng.toString(),
          minAcc: minimumAccessibility ? minimumAccessibility.toString() : '',
          minService: minimumService ? minimumService.toString() : '',
          searchRadius: radius.toString(),
        };

        const searchParams = new URLSearchParams(data);
        const url = `/api/points-of-interest?${searchParams.toString()}`;
        try {
          const searchResults: ISearchPointsOfInterestQueryResults = yield getAjax(self)
            .get(url, {
              signal: localState.currentSearch.abortController.signal,
            })
            .json();

          if (
            self.pointOfInterestDetails &&
            !searchResults.nearbyResults
              .concat(searchResults.distantResults)
              .find(p => p.id === self.pointOfInterestDetails?.id)
          ) {
            searchResults.distantResults.push({
              ...self.pointOfInterestDetails,
              location: {
                latitude: self.pointOfInterestDetails.location.latitude,
                longitude: self.pointOfInterestDetails.location.longitude,
              },
            });
          }

          self.nearbySearchResults = cast(
            searchResults.nearbyResults.length ? searchResults.nearbyResults : []
          );
          self.distantSearchResults = cast(
            searchResults.distantResults.length ? searchResults.distantResults : []
          );
          self.isMaxNearbyResults = searchResults.isMaxNearbyResults;
        } catch (error) {
          if (error instanceof DOMException && error.name === 'AbortError') {
            // Do nothing when aborting a search as this is an expected occurrence
            return;
          }
          throw error;
        }
      } finally {
        if (
          localState.currentSearch?.key.search === search &&
          localState.currentSearch?.key.category === category &&
          localState.currentSearch?.key.minimumAccessibility === minimumAccessibility &&
          localState.currentSearch?.key.minimumService === minimumService &&
          localState.currentSearch?.key.searchLocation.lat === searchLat &&
          localState.currentSearch?.key.searchLocation.lng === searchLng
        ) {
          localState.currentSearch = null;
        }
      }
    }

    function* load(id: string) {
      const root: IRootStoreModel = getRoot(self);
      try {
        localState.loadingPointOfInterestDetails = true;
        const result: IGetDto = yield getAjax(self).get(`/api/points-of-interest/${id}`).json();
        self.pointOfInterestDetails = cast(result);

        // Users can create points of interest that are not part of the current search results so they wouldn't be visible on
        // the map. This forces any point of interest retrieved by id to be displayed on the map even if the search should not allow it
        if (
          !localState.currentSearch &&
          !self.nearbySearchResults.concat(self.distantSearchResults).find(s => s.id === id)
        ) {
          self.nearbySearchResults.push({
            ...result,
            distanceInMetres: undefined,
          } as ISearchDto);
        } else {
          const loadedPoiInSearchResults = self.nearbySearchResults
            .concat(self.distantSearchResults)
            .find(s => s.id === id);
          if (loadedPoiInSearchResults) {
            loadedPoiInSearchResults.averageAccessibilityRating = result.averageAccessibilityRating;
            loadedPoiInSearchResults.reviewCount = result.reviewCount;
          }
        }
        self.pointOfInterestDetails && root.title.setSelectedPoi(self.pointOfInterestDetails.name);
      } finally {
        localState.loadingPointOfInterestDetails = false;
      }
    }

    function* addNewPointOfInterest(
      pointOfInterestDetails: IAddUserGeneratedPoiCommandModel
    ): Generator<string | undefined> {
      try {
        localState.submittingNewPointOfInterest = true;
        return yield getAjax(self)
          .post('/api/points-of-interest', {
            json: pointOfInterestDetails,
          })
          .json();
      } finally {
        localState.submittingNewPointOfInterest = false;
      }
    }

    return {
      views: {
        get isLoadingSearch() {
          return !!localState.currentSearch;
        },
        get isLoadingPointOfInterestDetails() {
          return localState.loadingPointOfInterestDetails;
        },
        get isSubmittingNewPointOfInterest() {
          return localState.submittingNewPointOfInterest;
        },
        get isReportingPoi() {
          return localState.isReportingPoi;
        },
        get count() {
          return self.nearbySearchResults.length + self.distantSearchResults.length;
        },
        get searchResults() {
          return self.nearbySearchResults.concat(self.distantSearchResults);
        },
      },
      actions: {
        clearSearch,
        search: flow(search),
        load: flow(load),
        addNewPointOfInterest: flow(addNewPointOfInterest),
        reportPoi: flow(reportPoi),
      },
    };
  });
