import React from 'react';
import styles from './SubpageForm.module.scss';
import cn from 'classnames';
import { Icon, IconName } from 'views/components/Icon';
import { siteDefault } from 'views/themes';
import { getMillisecondsFromCssTime } from 'infrastructure/cssUtils';
import { Control } from 'react-hook-form';
import { useStore } from 'views/hooks';

function sleep(ms: number) {
  // This comes from: https://stackoverflow.com/a/39914235
  return new Promise(resolve => setTimeout(resolve, ms));
}

interface ISubpageProps {
  onSubmit: () => {};
  control: Control;
  className?: string;
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
  title?: string;
  titleLeftAligned?: boolean;
  containerClassName?: string;
  submitText?: string;
  submitIcon?: IconName;
  firstTagToFocus?: 'a' | 'input' | 'button';
  onEsc?: () => void;
  afterSubmit?: () => {} | void;
  encType?: 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'text/plain';
}

export const SubpageForm: React.FC<ISubpageProps> = function ({
  onSubmit,
  control,
  className,
  leftAction,
  rightAction,
  title,
  titleLeftAligned,
  containerClassName,
  children,
  submitText,
  submitIcon,
  firstTagToFocus,
  onEsc,
  afterSubmit,
  encType,
}) {
  const [state, setState] = React.useState<'idle' | 'submitting' | 'submitted'>('idle');
  const formRef = React.useRef<HTMLFormElement | null>(null);
  const store = useStore();
  const isSmallViewport = store.environment.isSmallViewport;

  React.useEffect(() => {
    if (firstTagToFocus && formRef.current && !isSmallViewport) {
      const inputs = formRef.current.getElementsByTagName(firstTagToFocus);
      if (inputs.length > 0) {
        setTimeout(() => {
          inputs[0].focus();
        }, getMillisecondsFromCssTime(siteDefault['--reveal-page-transition-time']));
      }
    }
  }, [firstTagToFocus, isSmallViewport]);

  React.useEffect(() => {
    function escKeyHandler(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onEsc && onEsc();
      }
    }

    const currentForm = formRef.current;
    if (currentForm && onEsc && !isSmallViewport) {
      currentForm.addEventListener('keydown', escKeyHandler, false);

      return () => {
        currentForm.removeEventListener('keydown', escKeyHandler, false);
      };
    } else {
      return undefined;
    }
  }, [onEsc, isSmallViewport]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState('submitting');

    const isValid = await control.triggerValidation();
    if (!isValid) {
      const focusableError = Object.values(control.errorsRef.current).filter(e => e.ref.focus)[0];

      if (focusableError) {
        focusableError.ref.focus();
        formRef.current?.scrollBy({ behavior: 'smooth', top: -50 });
      }

      setState('idle');
      return;
    }

    try {
      await onSubmit();
    } catch (e) {
      setState('idle');
      throw e;
    }

    if (!afterSubmit) {
      setState('idle');
    } else {
      setState('submitted');
      // the sleep call allows time for the user to see the success message
      await sleep(getMillisecondsFromCssTime(siteDefault['--reveal-page-transition-time']) * 2);

      afterSubmit();
    }
  };

  return (
    <form
      className={cn(className, styles.root)}
      onSubmit={handleSubmit}
      encType={encType}
      ref={formRef}>
      <div className={styles.actions}>
        <div className={styles.left}>{leftAction}</div>
        <div className={styles.right}>{rightAction}</div>
      </div>
      {title && (
        <h2 className={cn(styles.title, { [styles.leftAlign]: titleLeftAligned })}>{title}</h2>
      )}
      <div className={cn(styles.container, containerClassName)}>{children}</div>
      <div className={styles.submit}>
        <span className="visually-hidden" aria-live="polite" aria-atomic>
          {state === 'idle' ? '' : state}
        </span>
        <button
          type="submit"
          className={cn(styles.submitContent, {
            [styles.isSubmitting]: state === 'submitting',
            [styles.isSubmitted]: state === 'submitted',
          })}
          disabled={state === 'submitting' || state === 'submitted'}>
          <span>
            {state === 'submitted'
              ? 'Submitted Successfully!'
              : state === 'submitting'
              ? 'Submitting...'
              : submitText || 'Submit'}
          </span>
          <Icon
            name={state === 'submitting' ? 'loading' : submitIcon || 'check'}
            className={cn(styles.icon, { [styles.isSubmitting]: state === 'submitting' })}
          />
        </button>
      </div>
    </form>
  );
};
