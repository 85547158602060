import { cast, flow, types } from 'mobx-state-tree';
import { observable } from 'mobx';
import { getAjax } from 'domain/store/RootStoreModel';
import { AreaDtoModel } from 'api/models/Domain/Queries/PointOfInterest/SearchAreasQuery/AreaDtoModel';
import ky from 'ky';

type IAreaDto = Domain.Queries.PointOfInterest.SearchAreasQuery.IAreaDto;

interface ILoadingAbortableProcess<TKey> {
  key: TKey;
  abortController: AbortController;
}

export const AreasRepo = types
  .model('AreasRepo', {
    searchResults: types.optional(types.array(AreaDtoModel), []),
  })
  .extend(self => {
    const localState = observable({
      currentSearch: null as ILoadingAbortableProcess<{
        search: string;
      }> | null,
    });

    function clearSearch() {
      self.searchResults.clear();
      localState.currentSearch?.abortController.abort();
    }

    function* search(search: string) {
      if (
        !search ||
        // don't bother hitting the API with search terms that have
        // fewer than 3 chars since there won't be any results
        search.length < 3
      ) {
        clearSearch();
        return;
      }

      if (localState.currentSearch) {
        const key = localState.currentSearch.key;
        if (key.search === search) {
          return;
        }
        localState.currentSearch.abortController.abort();
      }

      try {
        clearSearch();
        localState.currentSearch = {
          key: { search },
          abortController: new AbortController(),
        };
        const searchText = encodeURIComponent(search);

        const url = `/api/areas?search=${searchText}`;

        try {
          const searchResults: IAreaDto[] = yield getAjax(self)
            .get(url, {
              signal: localState.currentSearch.abortController.signal,
            })
            .json();

          self.searchResults = cast(searchResults.length ? searchResults : []);
        } catch (error) {
          if (error instanceof DOMException && error.name === 'AbortError') {
            // Do nothing when aborting a search as this is an expected occurrence
            return;
          } else if (error instanceof ky.HTTPError) {
            // Ignore area search API errors. Since area results are considered secondary to normal
            // POI results, if there's an issue with getting areas, we behave as if the feature doesn't exist.
            return;
          }
          throw error;
        }
      } finally {
        if (localState.currentSearch?.key.search === search) {
          localState.currentSearch = null;
        }
      }
    }

    return {
      views: {
        get isLoadingSearch() {
          return !!localState.currentSearch;
        },
      },
      actions: {
        clearSearch,
        search: flow(search),
      },
    };
  });
