import React from 'react';
import { observer } from 'mobx-react-lite';
import { allMobilityAid, MobilityAid } from 'api/enums/MobilityAid';
import { useForm } from 'react-hook-form';
import styles from './Onboarding.module.scss';
import cn from 'classnames';
import { Select } from 'views/components/forms/select/Select';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { BackButton } from 'views/components/detail/backButton/BackButton';

interface IFormData {
  mobilityAid: MobilityAid;
}

interface ISetMobilityAidProps {
  className?: string;
  onBack: () => void;
  onNext: () => void;
  displayName: string;
  setMobilityAid: (mobilityAid: MobilityAid) => void;
}

export const Mobility: React.FC<ISetMobilityAidProps> = observer(function ({
  className,
  onBack,
  onNext,
  displayName,
  setMobilityAid
}) {
  const { register, handleSubmit, errors, control } = useForm<IFormData>();

  const onSubmit = (data: IFormData) => {
    setMobilityAid(Number(data.mobilityAid));
    onNext();
  };

  return (
    <SubpageForm
      leftAction={<BackButton onClick={onBack} />}
      className={cn(styles.root, className)}
      containerClassName={styles.subsection}
      title={`Hey ${displayName}!`}
      titleLeftAligned
      submitText="Next"
      submitIcon="arrow-right"
      onSubmit={handleSubmit(onSubmit)}
      control={control}>
      <p>And another thing, which mobility aid do you mostly use when you're out and about?</p>
      <Select
        name="mobilityAid"
        register={register({
          required: 'Please select a mobility aid',
        })}
        placeholder="Please select one..."
        selections={allMobilityAid.map(d => {
          return {
            value: String(d.value),
            description: d.description,
          };
        })}
        selectErrors={errors.mobilityAid}
        label="Mobility Aid"
      />
    </SubpageForm>
  );
});
