import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStore } from 'views/hooks';
import styles from './ReportPoi.module.scss';
import { Icon } from 'views/components/Icon';
import { useForm } from 'react-hook-form';
import { TextArea } from 'views/components/forms/textArea/TextArea';

interface IFormData {
  reportReason: string;
}

interface IReportPoiProps {
  poiId: string;
  isReportedByCurrentUser: boolean;
}

export const ReportPoi: React.FC<IReportPoiProps> = observer(function ({
  poiId,
  isReportedByCurrentUser,
}) {
  const store = useStore();
  const poisRepo = store.pointsOfInterestRepo;
  const security = store.security;

  const [hasClickedReportButton, setHasClickedReportButton] = React.useState(false);
  const [hasReported, setHasReported] = React.useState(false);

  const { register, handleSubmit, errors } = useForm<IFormData>({
    defaultValues: {
      reportReason: '',
    },
  });
  const onSubmit = (data: any) =>
    poisRepo.reportPoi(poiId, data.reportReason).then(() => {
      setHasClickedReportButton(false);
      setHasReported(true);
    });

  if (!security.isLoggedIn) return null;

  return (
    <>
      {isReportedByCurrentUser || hasReported ? (
        <>
          <div className={styles.reportButton}>
            {/* Extra span required for Safari */}
            <span>
              <Icon className={styles.submitted} name="exclamation-triangle" />
              <span className={styles.submittedText}>{'Report submitted'}</span>
            </span>
          </div>
          {hasReported && (
            <div className={styles.hasReported}>
              <span>The Accessible Australia team will review this place for removal.</span>
            </div>
          )}
        </>
      ) : hasClickedReportButton ? (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.reportForm}>
          <TextArea
            name="reportReason"
            label="Report Reason"
            textAreaErrors={errors.reportReason}
            placeholder="What do you think is wrong with this listing?"
            rows={4}
            required={true}
            maxLength={2500}
            register={register({
              required: { value: true, message: 'You must provide a reason for reporting' },
              pattern: { value: /\S+/, message: 'Report reason cannot be empty' },
            })}
            autoFocus={true}
          />
          <div className={styles.reportOptions}>
            <button
              type="submit"
              className={styles.submit}
              disabled={poisRepo.isReportingPoi}
              aria-label="Confirm report"
              aria-live="polite"
              aria-atomic={true}>
              {poisRepo.isReportingPoi ? (
                <>
                  <span className={styles.hidden}>Submitting</span>
                  <Icon className={styles.isLoading} name="loading" />
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
            <button
              className={cn(styles.cancel, { [styles.disabled]: poisRepo.isReportingPoi })}
              disabled={poisRepo.isReportingPoi}
              onClick={() => setHasClickedReportButton(false)}
              aria-label="cancel report">
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <button
          className={styles.reportButton}
          onClick={() => setHasClickedReportButton(true)}
          title="Report this listing if you believe it should be removed from Accessible Australia"
          aria-label="Report this listing if you believe it should be removed from Accessible Australia">
          <span>
            <Icon name="exclamation-triangle" />
            <span className={styles.buttonText}>{'Report'}</span>
          </span>
        </button>
      )}
    </>
  );
});
