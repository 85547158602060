import React from 'react';
import * as atlas from 'azure-maps-control';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { IPointOfInterestDtoModel } from 'api/models/Domain/Queries/PointOfInterest/SearchPointsOfInterestQuery/PointOfInterestDtoModel';
import styles from './markers.module.scss';
import { MutableRefObject } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { AaMapUserMarker, AaMapClusterMarker, AaMapPoiMarker, AaMapNewPoiMarker } from './markers';
import History from 'history';
import { getParamFromLocation } from 'infrastructure/locationUtils';

const poiDataSource = 'pointsOfInterest';
const layers = { labelToLeft: 'labelToLeft', labelToRight: 'labelToRight', cluster: 'cluster' };
export const markers = { user: 'user', overrideLocation: 'override-location', action: 'action' };

export function registerDataSource(map: atlas.Map) {
  // Syntax rules for Azure maps aggregate expressions available at https://docs.microsoft.com/en-us/azure/azure-maps/data-driven-style-expressions-web-sdk#boolean-expressions
  // Work derived from https://github.com/Azure-Samples/AzureMapsCodeSamples/blob/4b17fcfc1c9c5e29123339fd71987796037e7bd2/AzureMapsCodeSamples/HTML%20Markers/HtmlMarkerLayer/Clustered%20Pie%20Chart%20HTML%20Markers.html#L63
  const getUnratedPoiCount = () => [
    '+',
    ['case', ['<', ['get', 'averageAccessibilityRating'], 0], 1, 0],
  ];

  const getRedPoiCount = () => [
    '+',
    [
      'case',
      [
        'all',
        ['>=', ['get', 'averageAccessibilityRating'], 0],
        ['<', ['get', 'averageAccessibilityRating'], 3],
      ],
      1,
      0,
    ],
  ];

  const getYellowPoiCount = () => [
    '+',
    [
      'case',
      [
        'all',
        ['>=', ['get', 'averageAccessibilityRating'], 3],
        ['<', ['get', 'averageAccessibilityRating'], 4],
      ],
      1,
      0,
    ],
  ];

  const getGreenPoiCount = () => [
    '+',
    ['case', ['>=', ['get', 'averageAccessibilityRating'], 4], 1, 0],
  ];

  const dataSource = new atlas.source.DataSource(poiDataSource, {
    cluster: true,
    clusterRadius: 80,
    clusterProperties: {
      UnratedPoiCount: getUnratedPoiCount(),
      RedPoiCount: getRedPoiCount(),
      YellowPoiCount: getYellowPoiCount(),
      GreenPoiCount: getGreenPoiCount(),
    },
  });

  map.sources.add(dataSource);
  return dataSource;
}

export function createMapLayers(map: atlas.Map, dataSource: atlas.source.DataSource) {
  map.imageSprite.createFromTemplate('hidden', 'pin-round', 'transparent', 'transparent');

  const options: atlas.SymbolLayerOptions = {
    iconOptions: {
      image: 'hidden',
      size: 2,
      anchor: 'center',
      allowOverlap: false,
    },
    textOptions: {
      textField: ['concat', ['get', 'name'], ' Possibly Accessible'],
      size: 18,
      color: 'transparent',
    },
  };

  map.layers.add([
    // The order layers are added is important - the last layer added will be rendered first
    new atlas.layer.SymbolLayer(dataSource, layers.labelToLeft, {
      ...options,
      textOptions: {
        ...options.textOptions,
        offset: [-1.55, 0],
        anchor: 'right',
      },
      filter: ['!', ['has', 'point_count']],
    }),
    new atlas.layer.SymbolLayer(dataSource, layers.labelToRight, {
      ...options,
      textOptions: {
        ...options.textOptions,
        offset: [1.55, 0],
        anchor: 'left',
      },
      filter: ['!', ['has', 'point_count']],
    }),
    new atlas.layer.SymbolLayer(dataSource, layers.cluster, {
      ...options,
      textOptions: {
        ...options.textOptions,
        textField: '',
      },
      filter: ['has', 'point_count'],
    }),
  ]);
}

export function registerMapEvents(
  map: atlas.Map,
  history: History.History,
  htmlMarkers: Map<string, atlas.HtmlMarker>,
  selectedPoi: MutableRefObject<IPointOfInterestDtoModel | undefined>,
  pointsOfInterest: IPointOfInterestDtoModel[],
  mapPosChanged: () => void,
  setShowMapActionContextMenu: (value: string) => void,
  unsetShowMapActionContextMenu: () => void,
  updatePosition: (p: atlas.data.Position) => void
) {
  let timeout: number;
  let inputMoveStart: atlas.data.Position | undefined;

  function showMapActionContextMenuAfterLongPress(e: atlas.MapTouchEvent | atlas.MapMouseEvent) {
    window.clearTimeout(timeout);
    inputMoveStart = e.position;
    timeout = window.setTimeout(() => {
      setShowMapActionContextMenu('y');
      history.push(routePaths.pois.toKeepingExistingParams()(history.location));

      inputMoveStart && setMapActionMarker(e.map, inputMoveStart, updatePosition);
    }, 500);
  }

  map.events.add('touchstart', e => showMapActionContextMenuAfterLongPress(e));
  map.events.add('touchend', () => window.clearTimeout(timeout));

  map.events.add('mousedown', e => showMapActionContextMenuAfterLongPress(e));
  map.events.add('mouseup', () => window.clearTimeout(timeout));

  map.events.add('move', () => window.clearTimeout(timeout));

  map.events.add('zoom', () => window.clearTimeout(timeout));

  map.events.add('click', map.layers.getLayerById('base'), e => {
    // Check is necessary as maps doesn't seem to support preventing event propagation
    if (e.originalEvent?.target instanceof HTMLCanvasElement) {
      if (
        getParamFromLocation(
          history.location,
          urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU
        )
      ) {
        e.position && setMapActionMarker(e.map, e.position, updatePosition);
        return;
      }

      history.push(routePaths.pois.toKeepingExistingParams()(history.location));
    }
  });

  map.events.add('move', () => {
    mapPosChanged();
  });

  map.events.add('load', () => {
    setSelectedPoi(map, history, selectedPoi, pointsOfInterest);
  });

  map.events.add('render', () =>
    renderPointsOfInterest(
      map,
      history,
      htmlMarkers,
      selectedPoi.current,
      unsetShowMapActionContextMenu
    )
  );

  map.events.add('click', map.layers.getLayerById(layers.cluster), centreCameraOnCluster);
}

export function loadPointsOfInterest(map: atlas.Map, pointsOfInterest: IPointOfInterestDtoModel[]) {
  const dataSource = map?.sources.getById(poiDataSource) as atlas.source.DataSource;

  if (dataSource) {
    dataSource.setShapes(
      pointsOfInterest.map(p => {
        return new atlas.data.Feature(
          new atlas.data.Point([p.location.longitude, p.location.latitude]),
          // Calculations for pie chart clusters require us to use numbers, hence the use of -1 to indicate an absent accessibility rating
          { ...p, averageAccessibilityRating: p.averageAccessibilityRating || -1 }
        );
      })
    );
  }
}

export function reloadSinglePointOfInterest(
  map: atlas.Map,
  id: string,
  pointsOfInterest: IPointOfInterestDtoModel[]
) {
  const dataSource = map?.sources.getById(poiDataSource) as atlas.source.DataSource;

  if (dataSource) {
    const shape = dataSource.getShapes().find(s => s.getProperties().id === id);
    const poi = pointsOfInterest.find(p => p.id === id);

    shape?.setProperties({
      ...shape.getProperties(),
      // Calculations for pie chart clusters require us to use numbers, hence the use of -1 to indicate an absent accessibility rating
      averageAccessibilityRating: poi?.averageAccessibilityRating || -1,
      reviewCount: poi?.reviewCount,
      dirty: true,
    });
  }
}

export function setUserPin(
  map: atlas.Map,
  selectedPoi: IPointOfInterestDtoModel | undefined,
  userLocation: atlas.data.Point,
  unsetOverrideCoordinates: () => void
) {
  clearMapMarker(map, markers.user);
  clearMapMarker(map, markers.overrideLocation);
  map.markers.add(
    new atlas.HtmlMarker({
      htmlContent: renderToStaticMarkup(
        <AaMapUserMarker className={selectedPoi ? styles.shrunk : undefined} />
      ),
      position: userLocation.coordinates,
      anchor: 'center',
      text: markers.user,
    })
  );

  unsetOverrideCoordinates();
}

export function clearMapMarker(map: atlas.Map, text: string) {
  const marker = map.markers.getMarkers().find(m => m.getOptions().text === text);
  marker && map.markers.remove(marker);
}

export function setMapActionMarker(
  map: atlas.Map,
  location: atlas.data.Position,
  updatePosition: (p: atlas.data.Position) => void
) {
  clearMapMarker(map, markers.action);
  map.markers.add(
    new atlas.HtmlMarker({
      htmlContent: renderToStaticMarkup(<AaMapNewPoiMarker />),
      position: location,
      anchor: 'center',
      text: markers.action,
    })
  );
  updatePosition(location);
}

export function setOverrideLocationMarker(
  map: atlas.Map,
  setOverrideCoordinates: (value: string) => void
) {
  const mapActionMarker = map.markers
    .getMarkers()
    .find(m => m.getOptions().text === markers.action);
  const [lng, lat] = mapActionMarker?.getOptions().position;

  clearMapMarker(map, markers.user);
  clearMapMarker(map, markers.overrideLocation);
  map.markers.add(
    new atlas.HtmlMarker({
      htmlContent: renderToStaticMarkup(<AaMapUserMarker />),
      position: mapActionMarker?.getOptions().position,
      anchor: 'center',
      text: markers.overrideLocation,
    })
  );

  if (lat && lng) {
    setOverrideCoordinates(`${lat}_${lng}`);
  }

  clearMapMarker(map, markers.action);
}

export function initializeOverrideLocationMarkerAtPosition(
  map: atlas.Map,
  position: atlas.data.Position
) {
  clearMapMarker(map, markers.user);
  clearMapMarker(map, markers.overrideLocation);
  map.markers.add(
    new atlas.HtmlMarker({
      htmlContent: renderToStaticMarkup(<AaMapUserMarker />),
      position: position,
      anchor: 'center',
      text: markers.overrideLocation,
    })
  );
}

export function centreCameraOnUserLocation(
  map: atlas.Map,
  selectedPoi: IPointOfInterestDtoModel | undefined,
  userLocation: atlas.data.Point,
  options?: atlas.CameraOptions
) {
  centreCameraOnLocation(map, userLocation, options);
}

export function setSelectedPoi(
  map: atlas.Map,
  history: History.History,
  selectedPoi: MutableRefObject<IPointOfInterestDtoModel | undefined>,
  pointsOfInterest: IPointOfInterestDtoModel[]
) {
  const tokenizedUrl = history.location.pathname.split('/');
  const id = tokenizedUrl[tokenizedUrl.length - 1];

  if (id === 'pois') {
    selectedPoi.current = undefined;
    unselectAllMarkers();
  } else {
    selectedPoi.current = pointsOfInterest.find(p => p.id === id);
    selectedPoi.current && animateSelectedPoiMarker(selectedPoi.current);
  }
}

function centreCameraOnCluster(e: atlas.MapMouseEvent) {
  const cluster = e.shapes?.length && (e.shapes[0] as atlas.data.Feature<atlas.data.Geometry, any>);

  if (cluster)
    //Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart
    (e.map.sources.getById(poiDataSource) as atlas.source.DataSource)
      .getClusterExpansionZoom(cluster.properties.cluster_id)
      .then(zoom => {
        centreCameraOnLocation(e.map, cluster.geometry as atlas.data.Point, {
          zoom: zoom,
        });
      });
}

export function centreCameraOnLocation(
  map: atlas.Map,
  centre: atlas.data.Point,
  options?: atlas.CameraOptions
) {
  map.setCamera({
    type: 'ease',
    zoom: 14,
    duration: 500,
    center: centre.coordinates,
    ...options,
  });
}

function unselectAllMarkers() {
  [
    ...document.getElementsByClassName(styles.pin),
    ...document.getElementsByClassName(styles.cluster),
    ...document.getElementsByClassName(styles.user),
  ].forEach(pin => {
    pin.classList.remove(styles.shrunk);
    pin.classList.remove(styles.grown);
  });
}

function animateSelectedPoiMarker(selectedPoi: IPointOfInterestDtoModel) {
  [
    ...document.getElementsByClassName(styles.pin),
    ...document.getElementsByClassName(styles.cluster),
    ...document.getElementsByClassName(styles.user),
  ]
    .filter(e => e.hasAttribute('data-poi-id'))
    .forEach((m: Element) => {
      if (m.getAttribute('data-poi-id') === selectedPoi.id) {
        m.classList.remove(styles.shrunk);
        m.classList.add(styles.grown);
      } else {
        m.classList.remove(styles.grown);
        m.classList.add(styles.shrunk);
      }
    });
}

function renderPointsOfInterest(
  map: atlas.Map,
  history: History.History,
  htmlMarkers: Map<string, atlas.HtmlMarker>,
  selectedPoi: IPointOfInterestDtoModel | undefined,
  unsetShowMapActionContextMenu: () => void
) {
  const htmlMarkersWithinBounds: atlas.HtmlMarker[] = [];

  map.layers
    .getRenderedShapes(map.getCamera().bounds, [map.layers.getLayerById(layers.labelToRight)])
    .forEach(shape => {
      if (shape instanceof atlas.Shape) {
        const preRenderedHtmlMarker = htmlMarkers.get(shape.getProperties().id + '+right');

        if (preRenderedHtmlMarker) {
          if (shape.getProperties().dirty) {
            map.markers.remove(preRenderedHtmlMarker);
          } else {
            htmlMarkersWithinBounds.push(preRenderedHtmlMarker);
            return;
          }
        }

        const marker = createPoiMarkerFromShape(shape, 'right', selectedPoi);
        addClickEventToPoiMarker(map, marker, history, unsetShowMapActionContextMenu);
        map.markers.add(marker);

        htmlMarkers.set(shape.getProperties().id + '+right', marker);
        htmlMarkersWithinBounds.push(marker);
      }
    });

  map.layers
    .getRenderedShapes(map.getCamera().bounds, [map.layers.getLayerById(layers.labelToLeft)])
    .forEach(shape => {
      if (shape instanceof atlas.Shape) {
        const preRenderedHtmlMarker = htmlMarkers.get(shape.getProperties().id + '+left');

        if (preRenderedHtmlMarker) {
          if (shape.getProperties().dirty) {
            map.markers.remove(preRenderedHtmlMarker);
          } else {
            htmlMarkersWithinBounds.push(preRenderedHtmlMarker);
            return;
          }
        }

        const marker = createPoiMarkerFromShape(shape, 'left', selectedPoi);
        addClickEventToPoiMarker(map, marker, history, unsetShowMapActionContextMenu);
        map.markers.add(marker);

        htmlMarkers.set(shape.getProperties().id + '+left', marker);
        htmlMarkersWithinBounds.push(marker);
      }
    });

  map.layers
    .getRenderedShapes(map.getCamera().bounds, [map.layers.getLayerById(layers.cluster)])
    .forEach(shape => {
      const cluster = shape as atlas.data.Feature<atlas.data.Geometry, any>;
      const preRenderedHtmlMarker = htmlMarkers.get(`cluster-${cluster.id?.toString()}`);

      if (preRenderedHtmlMarker) {
        htmlMarkersWithinBounds.push(preRenderedHtmlMarker);
      } else {
        const marker = new atlas.HtmlMarker({
          htmlContent: renderToStaticMarkup(
            <AaMapClusterMarker
              cluster={cluster}
              className={selectedPoi ? styles.shrunk : undefined}
            />
          ),
          position: (cluster.geometry as atlas.data.Point).coordinates,
          anchor: 'center',
        });

        map.events.add('click', marker, e => {
          if (e.target) {
            unsetShowMapActionContextMenu();
            history.push(routePaths.pois.toKeepingExistingParams()(history.location));
          }
        });

        map.markers.add(marker);
        htmlMarkers.set(`cluster-${cluster.id?.toString()}`, marker);
        htmlMarkersWithinBounds.push(marker);
      }
    });

  removeMarkersOutsideRenderedBounds(map, htmlMarkers, htmlMarkersWithinBounds);
}

function createPoiMarkerFromShape(
  shape: atlas.Shape,
  labelPlacement: 'left' | 'right',
  selectedPoi: IPointOfInterestDtoModel | undefined
) {
  const poi = shape.getProperties();

  return new atlas.HtmlMarker({
    htmlContent: renderToStaticMarkup(
      <AaMapPoiMarker
        shape={shape}
        labelPlacement={labelPlacement}
        pinClass={
          !selectedPoi ? undefined : selectedPoi.id === poi.id ? styles.grown : styles.shrunk
        }
      />
    ),
    position: [...(shape.getCoordinates() as atlas.data.Position)],
    anchor: 'center',
    text: poi.id + `+${labelPlacement}`,
  });
}

function addClickEventToPoiMarker(
  map: atlas.Map,
  marker: atlas.HtmlMarker,
  history: History.History,
  unsetShowMapActionContextMenu: () => void
) {
  map.events.add('click', marker, e => {
    if (e.target) {
      unsetShowMapActionContextMenu();
      history.push(
        routePaths.poi.toKeepingExistingParams(e.target.getOptions().text.split('+')[0])(
          history.location
        )
      );
    }
  });
}

function removeMarkersOutsideRenderedBounds(
  map: atlas.Map,
  htmlMarkers: Map<string, atlas.HtmlMarker>,
  htmlMarkersWithinBounds: atlas.HtmlMarker[]
) {
  htmlMarkers.forEach((v, k) => {
    if (!htmlMarkersWithinBounds.find(m => m === v)) {
      map.markers.remove(v);
      htmlMarkers.delete(k);
    }
  });
}
