import {
  allPointOfInterestCategory,
  PointOfInterestCategory,
  pointOfInterestCategoryDescription,
} from 'api/enums/PointOfInterestCategory';
import { cast, types } from 'mobx-state-tree';
import escapeForRegex from 'escape-string-regexp';
import Pluralize from 'pluralize';

const groupTitles = {
  foodAndDrink: 'Food & Drink',
  infrastructure: 'Infrastructure',
  travel: 'Travel',
  entertainment: 'Entertainment',
  retail: 'Retail',
  transit: 'Transit',
  health: 'Health',
} as const;

const categorySynonyms = new Map([
  [PointOfInterestCategory.Bathroom, ['Restroom', 'Toilet', 'WC']],
  [PointOfInterestCategory.CafeRestaurant, ['Cafe', 'Café', 'Coffee Shop', 'Eatery']],
  [PointOfInterestCategory.Bar, ['Pub']],
  [PointOfInterestCategory.GeneralPractitioner, ['GP', 'Doctor']],
  [PointOfInterestCategory.Pharmacy, ['Chemist', 'Drugstore']],
  [PointOfInterestCategory.Grocer, ['Market', 'Supermarket']],
  [PointOfInterestCategory.Accommodation, ['Hotel', 'Motel', 'Resort', 'Bed and Breakfast']],
  [PointOfInterestCategory.ServiceStation, ['Petrol Station', 'Gas Station', 'Servo']],
  [PointOfInterestCategory.Gymnasium, ['Gym']],
  [PointOfInterestCategory.TaxiRank, ['Taxi', 'Taxies']],
  [PointOfInterestCategory.ChangingRoom, ['Change room']],
  [PointOfInterestCategory.BusStop, ['Bus', 'Busses']],
  [PointOfInterestCategory.TrainStation, ['Train']],
]);

interface ICategoryGroup {
  title: string;
  categories: PointOfInterestCategory[];
}

const allCategoryGroups: ICategoryGroup[] = [
  {
    title: groupTitles.foodAndDrink,
    categories: [PointOfInterestCategory.Bar, PointOfInterestCategory.CafeRestaurant],
  },
  {
    title: groupTitles.entertainment,
    categories: [
      PointOfInterestCategory.Activity,
      PointOfInterestCategory.Attraction,
      PointOfInterestCategory.Beach,
      PointOfInterestCategory.Museum,
      PointOfInterestCategory.Park,
      PointOfInterestCategory.Theatre,
      PointOfInterestCategory.Tour,
      PointOfInterestCategory.Venue,
    ],
  },
  {
    title: groupTitles.health,
    categories: [
      PointOfInterestCategory.Dentist,
      PointOfInterestCategory.GeneralPractitioner,
      PointOfInterestCategory.Gymnasium,
      PointOfInterestCategory.Hospital,
      PointOfInterestCategory.Pharmacy,
      PointOfInterestCategory.AlliedHealth,
      PointOfInterestCategory.Specialist,
      PointOfInterestCategory.OtherHealthService
    ],
  },
  {
    title: groupTitles.infrastructure,
    categories: [
      PointOfInterestCategory.Bathroom,
      PointOfInterestCategory.CarPark,
      PointOfInterestCategory.ChangingRoom,
    ],
  },
  {
    title: groupTitles.retail,
    categories: [
      PointOfInterestCategory.Bank,
      PointOfInterestCategory.Grocer,
      PointOfInterestCategory.OtherRetail,
    ],
  },
  {
    title: groupTitles.transit,
    categories: [
      PointOfInterestCategory.BusStop,
      PointOfInterestCategory.LightRailStation,
      PointOfInterestCategory.TaxiRank,
      PointOfInterestCategory.TrainStation,
    ],
  },
  {
    title: groupTitles.travel,
    categories: [
      PointOfInterestCategory.Accommodation,
      PointOfInterestCategory.EquipmentHire,
      PointOfInterestCategory.Information,
      PointOfInterestCategory.Services,
      PointOfInterestCategory.ServiceStation,
      PointOfInterestCategory.VehicleHire,
    ],
  },
];

export function parseCategoryName(value: string | null): PointOfInterestCategory | undefined {
  return allPointOfInterestCategory.find(c => c.name === value)?.value;
}

// Update this list with category specific plural rules
Pluralize.addUncountableRule('Other Retail');
Pluralize.addUncountableRule('Accommodation');
Pluralize.addUncountableRule('Vehicle Hire');
Pluralize.addUncountableRule('Equipment Hire');
Pluralize.addPluralRule('Café', 'Cafés');

export function pluraliseCategory(category: string) {
  const currentCategory = parseCategoryName(category);
  if (currentCategory) {
    const categoryDescription = pointOfInterestCategoryDescription(currentCategory);
    return categoryDescription
      .split(' /')
      .map(c => Pluralize(c.trim()))
      .join(' / ');
  }
  return undefined;
}

export const CategoriesRepo = types
  .model('CategoriesRepo', {
    searchResults: types.optional(types.array(types.frozen<ICategoryGroup>()), allCategoryGroups),
  })
  .extend(self => {
    function getFilteredCategories(search: string) {
      const searchValueRegex = new RegExp(escapeForRegex(search || '.+'), 'i');
      const result: ICategoryGroup = {
        title: 'Categories',
        categories: allPointOfInterestCategory
          .filter(c => {
            if (
              searchValueRegex.test(c.description) ||
              searchValueRegex.test(Pluralize(c.description))
            ) {
              return true;
            }

            const synonyms = categorySynonyms.get(c.value);
            return (
              synonyms &&
              synonyms.some(s => searchValueRegex.test(s) || searchValueRegex.test(Pluralize(s)))
            );
          })
          .map(c => c.value),
      };
      return [result].filter(g => g.categories.length);
    }

    function clearSearch() {
      self.searchResults = cast(allCategoryGroups);
    }

    function search(search: string, isCategorySet: boolean) {
      self.searchResults = cast(isCategorySet || !search ? [] : getFilteredCategories(search));
    }

    return { actions: { search, clearSearch } };
  });
