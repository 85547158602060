import { IMiddlewareHandler } from 'mobx-state-tree';
import { IRootStoreModel } from '../RootStoreModel';
import ky from 'ky';

const standardMessageSuffix = 'Please try again later.';

const messages = {
  unknownError: `An unknown error occurred. ${standardMessageSuffix}`,
  apiNotFound: `The connection to the server failed. ${standardMessageSuffix}`,
  apiServerError: `The server has experienced an error. ${standardMessageSuffix}`,
  apiBadRequest: `The server has rejected your request. ${standardMessageSuffix}`,
  unauthenticated: 'You are not currently signed in. Please sign in and try again.',
  unauthorised: 'You are not authorised to perform this action.',
  banned: 'Your account has been banned. Action not permitted.',
};

// Middleware docs: https://github.com/mobxjs/mobx-state-tree/blob/master/docs/middleware.md#call-attributes
export const globalErrorHandlerMiddleware: IMiddlewareHandler = (call, next, abort) => {
  // We only want to handle errors when we've reached the top of the action stack without being caught
  if (call.allParentIds.length <= 1) {
    const root = call.tree as IRootStoreModel;
    switch (call.type) {
      case 'action': {
        // Sync actions
        try {
          next(call);
          return;
        } catch (error) {
          handleError(root, error);
        }
        break;
      }
      case 'flow_throw': {
        // Async actions
        const error = call.args.length && call.args[0];
        handleError(root, error);
        break;
      }
    }
  }

  // Call next to allow the action flow to continue as normal, including errors continuing up the call stack
  // Keep error propagating up the stack, so that calling code does not always have to consider the possibility of
  // getting `undefined` returned.
  next(call);
};

function handleError(root: IRootStoreModel, error: unknown) {
  if (!error) {
    reportError(root, messages.unknownError);
  } else if (error instanceof ky.HTTPError) {
    handleAjaxError(root, error);
  } else if (error instanceof Error) {
    handleGeneralError(root, error);
  } else {
    handleUntypedError(root, error);
  }
}

function reportError(root: IRootStoreModel, message: string, error?: unknown) {
  // Diagnostic logging can be added here if required
  root.notifications.addError(message);
}

function handleAjaxError(root: IRootStoreModel, error: ky.HTTPError) {
  // Handle standard errors with standard messages
  switch (error.response.status) {
    case 400: {
      reportError(root, messages.apiBadRequest, error);
      break;
    }
    case 401: {
      reportError(root, messages.unauthenticated, error);
      break;
    }
    case 403: {
      const isUserBanned = error.response.headers.has('Banned');
      reportError(root, isUserBanned ? messages.banned : messages.unauthorised, error);
      break;
    }
    case 404: {
      reportError(root, messages.apiNotFound, error);
      break;
    }
    case 500: {
      reportError(root, messages.apiServerError, error);
      break;
    }
    default: {
      // Last resort is to use the error message
      reportError(root, error.message, error);
      break;
    }
  }
}

function handleGeneralError(root: IRootStoreModel, error: Error) {
  reportError(root, `${error.message}`, error);
}

function handleUntypedError(root: IRootStoreModel, error: any) {
  // Error is unrecognised, so look for standard messages, or just try to coerce to a string
  const message = error.message || error + '';
  reportError(root, message, error);
}
