import React from 'react';
import styles from './SearchFurther.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore, useUrlParam } from 'views/hooks';
import { Link } from 'react-router-dom';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { pluraliseCategory } from 'domain/store/repos/CategoriesRepo';

const searchRadii = [10000, 25000, 50000];
export const defaultSearchRadius = searchRadii[0];
export const maxSearchRadius = searchRadii[searchRadii.length - 1];

export const SearchFurther: React.FC = observer(function SearchFurther() {
  const store = useStore();
  const poisRepo = store.pointsOfInterestRepo;
  const poiResults = poisRepo.searchResults;
  const searchModel = store.search;
  const [radius] = useUrlParam(urlParamNames.search.SEARCH_RADIUS);
  const [categoryValue] = useUrlParam(urlParamNames.search.CATEGORY);

  if (!poiResults || searchModel.state === 'cleared' || poisRepo.isMaxNearbyResults) {
    return null;
  }

  const currentRadius = Number(radius);
  let newRadius = searchRadii[0];
  let searchFurther = false;

  for (let i = 0; i < searchRadii.length; i++) {
    if (currentRadius < searchRadii[i]) {
      newRadius = searchRadii[i];
      searchFurther = true;
      break;
    }
  }

  if (!searchFurther) {
    return null;
  }

  const displayMessage = `There are no ${
    poisRepo.nearbySearchResults.length ? 'more matching ' : 'matching '
  } 
  ${categoryValue ? pluraliseCategory(categoryValue) : 'results'} within 
  ${currentRadius / 1000}km, you can try `;

  return (
    <p className={styles.actions}>
      {displayMessage}
      <span className="visually-hidden">
        {poisRepo.distantSearchResults.length ? 'scroll down for results in other areas, or ' : ''}
        extend your search radius
      </span>
      <Link
        className={styles.searchFurther}
        aria-label={`Extend your search radius to ${newRadius / 1000}km`}
        to={routePaths.pois.toKeepingExistingParams({
          SEARCH_RADIUS: newRadius,
        })}>
        {`searching within ${newRadius / 1000}km`}
      </Link>
    </p>
  );
});
