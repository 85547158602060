import React from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import styles from './Onboarding.module.scss';
import cn from 'classnames';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { CheckBox } from 'views/components/forms/checkbox/Checkbox';
import { Input } from 'views/components/forms/input/Input';

interface IFormData {
    emailConsent: boolean;
    email: string;
  }

interface ISetEmailConsentProps {
  className?: string;
  onBack: () => void;
  onFinish: (email: string | undefined, emailConsent: boolean) => void;
  afterFinish: () => void;
  displayName: string;
}

export const EmailConsent: React.FC<ISetEmailConsentProps> = observer(function ({
  className,
  onBack,
  onFinish,
  afterFinish,
  displayName,
}) {
    const { register, handleSubmit, errors, watch, control } = useForm<IFormData>({
        defaultValues: {
            emailConsent: false
        },
      });
    
      const emailConsent = watch('emailConsent');
      const emailProvided = !!watch('email');

  const onSubmit = (data: IFormData) => {
    onFinish(data.email, data.emailConsent);
  };

  return (
    <SubpageForm
      leftAction={<BackButton onClick={onBack} />}
      className={cn(styles.root, className)}
      containerClassName={styles.subsection}
      title={`Hey ${displayName}!`}
      titleLeftAligned
      submitText="Finish"
      submitIcon="arrow-right"
      onSubmit={handleSubmit(onSubmit)}
      afterSubmit={afterFinish}
      control={control}>
      <p>We have big plans! Would you like to be kept up to date with improvements and events we are running?</p>
      <CheckBox
        register={register}
        name="emailConsent"
        label="Yes"
      />
      {emailConsent && (
        <>
          <p>Please provide your email address below</p>
          <Input
            inputErrors={errors.email}
            name="email"
            register={register({
              validate: () =>
                emailProvided || 'Please provide an email address',
            })}
            label="Email"
            placeholder="Enter your email"
            type="email"
          />
        </>
      )}
    </SubpageForm>
  );
});
