import React, { useContext } from 'react';
import styles from './MapActionContextMenu.module.scss';
import cn from 'classnames';
import { Icon } from 'views/components/Icon';
import { useStore, useUrlParam } from 'views/hooks';
import { urlParamNames } from 'views/routes/routePaths';
import { urlParams } from 'views/components/application/Shell';
import { MapContext } from '../Search';

interface IMapActionContextMenuProps {
  className?: string;
}

export const MapActionContextMenu: React.FC<IMapActionContextMenuProps> = function MapActionContextMenu({
  className,
}) {
  const store = useStore();
  const security = store.security;
  const environment = store.environment;
  const isSmallViewport = environment.isSmallViewport;
  const mapContext = useContext(MapContext);

  const [
    showMapActionContextMenu,
    setShowMapActionContextMenu,
    unsetShowMapActionContextMenu,
  ] = useUrlParam(urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU);

  const [, setShowNewPoiForm, unsetShowNewPoiForm] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_NEW_POI_FORM
  );

  const [, setShowProfile] = useUrlParam(urlParams.SHOW_PROFILE);

  return (
    <div
      className={cn(styles.root, className, {
        [styles.preventSelect]: isSmallViewport,
        [styles.pinchZoomDisabled]: !environment.isViewportZoomed,
      })}>
      {security.isLoggedIn ? (
        <button
          aria-label="Add A New Place"
          className={cn(styles.contextMenuOption)}
          onClick={() => {
            !showMapActionContextMenu ? setShowMapActionContextMenu('y') : setShowNewPoiForm('y');
          }}>
          <span>Add place</span>
          <Icon name="add" />
        </button>
      ) : (
        <button
          aria-label="Sign In To Add A New Place"
          className={cn(styles.contextMenuOption)}
          onClick={() => {
            unsetShowMapActionContextMenu();
            setShowProfile('y');
          }}>
          <span>Sign in to add a place</span>
          <Icon name="arrow-right" />
        </button>
      )}
      <button
        aria-label="Set my location"
        className={cn(styles.contextMenuOption)}
        onClick={() => {
          mapContext.setMapOverrideCoords && mapContext.setMapOverrideCoords();
          unsetShowMapActionContextMenu();
        }}>
        <span>Set as my current location</span>
        <Icon name="map-marker" />
      </button>
      {isSmallViewport && (
        <button
          aria-label="Close map actions menu"
          className={cn(styles.contextMenuOption)}
          onClick={() => {
            unsetShowMapActionContextMenu();
            unsetShowNewPoiForm();
          }}>
          <span>Cancel</span>
          <Icon name="times" />
        </button>
      )}
    </div>
  );
};
