import React from 'react';
import cn from 'classnames';
import styles from './BackButton.module.scss';
import { IconButton } from 'views/components/actions/IconButton';

interface IBackButtonProps {
  className?: string;
  onClick: () => void;
}

export function BackButton({ className, onClick }: IBackButtonProps) {
  return (
    <IconButton
      className={cn(styles.root, className)}
      onClick={onClick}
      title={'Go back'}
      icon={{ name: 'arrow-left' }}
    />
  );
}
