import { Search, Location } from 'history';
import { ObjectEntries } from 'infrastructure/typeUtils';

const SEARCH = 'search';
const CATEGORY = 'category';
const SHOW_LIST = 'showList';
const SHOW_FILTER = 'showFilter';
const ACCESSIBILITY_FILTER = 'minAcc';
const SERVICE_FILTER = 'minService';
const SEARCH_COORDINATES = 'loc';
const OVERRIDE_COORDINATES = 'overrideCoordinates';
const FULL_DETAIL = 'fullDetail';
const SEARCH_RADIUS = 'radius';
const SHOW_MAP_ACTION_CONTEXT_MENU = 'showMapActionContextMenu';
const SHOW_NEW_POI_FORM = 'showNewPoiForm';
const MOBILITY_AID = 'mobilityAid';
const SHOW_LANDING_PAGE = 'showLandingPage';
const SHOW_SHARE = 'showShare';
const SHOW_EMAIL_CONSENT_NOTIFICATION = 'showEmailConsentNotification';

export const urlParamNames = {
  search: {
    SEARCH,
    CATEGORY,
    SHOW_LIST,
    SHOW_FILTER,
    ACCESSIBILITY_FILTER,
    SERVICE_FILTER,
    SEARCH_COORDINATES,
    OVERRIDE_COORDINATES,
    FULL_DETAIL,
    SEARCH_RADIUS,
    SHOW_SHARE,
  },
  review: {
    MOBILITY_AID,
  },
  mapContextMenu: {
    SHOW_MAP_ACTION_CONTEXT_MENU,
    SHOW_NEW_POI_FORM,
  },
  landingPage: {
    SHOW_LANDING_PAGE,
  },
  emailConsentNotification: {
    SHOW_EMAIL_CONSENT_NOTIFICATION,
  },
};

type UrlParamsToRetain<T> = [Search, Array<T> | '*'];

type SearchUrlParams = Partial<{
  SEARCH: string;
  CATEGORY: string;
  SHOW_LIST: boolean;
  SHOW_FILTER: boolean;
  ACCESSIBILITY_FILTER: string;
  SERVICE_FILTER: string;
  SEARCH_COORDINATES: [number, number];
  OVERRIDE_COORDINATES: [number, number];
  FULL_DETAIL: boolean; // consider if this should be in a SearchResultUrlParams type
  SEARCH_RADIUS: number;
  SHOW_SHARE: boolean;
}>;

function boolToParamString(value?: boolean) {
  return value ? 'y' : undefined;
}

function numberToParamString(value?: number) {
  return value ? value.toString() : undefined;
}

function isNumberPair(value?: unknown): value is [number, number] {
  return Array.isArray(value) && value.length === 2 && value.every(v => typeof v === 'number');
}

function numberPairToParamString(value?: [number, number]) {
  if (!value) {
    return undefined;
  }
  const [a, b] = value;
  return `${a}_${b}`;
}

function searchUrlParamsToNameValuePairs(urlParams: SearchUrlParams) {
  const entries = Object.entries(urlParams) as ObjectEntries<SearchUrlParams>;
  return entries.map(
    ([n, v]) =>
      [
        urlParamNames.search[n],
        typeof v === 'boolean'
          ? boolToParamString(v)
          : typeof v === 'number'
          ? numberToParamString(v)
          : isNumberPair(v)
          ? numberPairToParamString(v)
          : v,
      ] as const
  );
}

export const routePaths = {
  pois: {
    template: '/pois',
    to: poisTo,
    toKeepingExistingParams: (urlParams?: SearchUrlParams) => (l: Location) => {
      return poisTo(urlParams, [l.search, '*']);
    },
  },
  poi: {
    template: '/pois/:id',
    to: poiTo,
    toKeepingExistingParams: (id: string, urlParams?: SearchUrlParams) => (l: Location) => {
      return poiTo(id, urlParams, [l.search, '*']);
    },
  },
  createReview: {
    template: '/pois/:id/createReview',
    to: createReviewTo,
    toKeepingExistingParams: (id: string, urlParams?: SearchUrlParams) => (l: Location) => {
      return createReviewTo(id, urlParams, [l.search, '*']);
    },
  },
  updateReview: {
    template: '/pois/:id/updateReview/:reviewId',
    to: createReviewTo,
    toKeepingExistingParams: (id: string, reviewId: string, urlParams?: SearchUrlParams) => (
      l: Location
    ) => {
      return updateReviewTo(id, reviewId, urlParams, [l.search, '*']);
    },
  },
};

function poisTo(
  urlParams?: SearchUrlParams,
  existingUrlParamsToRetain?: UrlParamsToRetain<keyof SearchUrlParams>
) {
  const params = searchUrlParamsToNameValuePairs(urlParams || {});
  return getNewLocation('/pois', params, existingUrlParamsToRetain);
}

function poiTo(
  id: string,
  urlParams?: SearchUrlParams,
  existingUrlParamsToRetain?: UrlParamsToRetain<keyof SearchUrlParams>
) {
  const params = searchUrlParamsToNameValuePairs(urlParams || {});
  return getNewLocation(`/pois/${id}`, params, existingUrlParamsToRetain);
}

function createReviewTo(
  id: string,
  urlParams?: SearchUrlParams,
  existingUrlParamsToRetain?: UrlParamsToRetain<keyof SearchUrlParams>
) {
  const params = searchUrlParamsToNameValuePairs(urlParams || {});
  return getNewLocation(`/pois/${id}/createReview`, params, existingUrlParamsToRetain);
}

function updateReviewTo(
  id: string,
  reviewId: string,
  urlParams?: SearchUrlParams,
  existingUrlParamsToRetain?: UrlParamsToRetain<keyof SearchUrlParams>
) {
  const params = searchUrlParamsToNameValuePairs(urlParams || {});
  return getNewLocation(`/pois/${id}/updateReview/${reviewId}`, params, existingUrlParamsToRetain);
}

function getNewLocation(
  pathname: string,
  newUrlParams: (readonly [string, string | undefined])[],
  existingUrlParamsToRetain?: UrlParamsToRetain<string>
) {
  const [existingSearch, paramNamesToRetain] = existingUrlParamsToRetain || [];
  const existingParams = new URLSearchParams(existingSearch);

  const params = new URLSearchParams();

  if (paramNamesToRetain === '*') {
    existingParams.forEach((v, n) => params.set(n, v));
  } else {
    paramNamesToRetain?.forEach(n => {
      const value = existingParams.get(n);
      value && params.set(n, value);
    });
  }

  // Have the newParams overwrite the existing ones in the case of both existing
  newUrlParams.forEach(([n, v]) => (v === undefined ? params.delete(n) : params.set(n, v)));

  return {
    pathname,
    search: params.toString(),
  };
}
