import { IAreaDtoModel } from 'api/models/Domain/Queries/PointOfInterest/SearchAreasQuery/AreaDtoModel';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Icon } from 'views/components/Icon';
import { useStore, useUrlParam } from 'views/hooks';
import { MapContext } from 'views/routes/pois/Search';
import { urlParamNames } from 'views/routes/routePaths';
import styles from './AreasPanel.module.scss';

interface IAreaItem {
  area: IAreaDtoModel;
}

const AreaItem: React.FC<IAreaItem> = observer(function AreaItem({ area }) {
  const { centreMapOnLocation } = React.useContext(MapContext);
  const setSearchCoordinates = useUrlParam(urlParamNames.search.SEARCH_COORDINATES)[1];
  const unsetSearchValue = useUrlParam(urlParamNames.search.SEARCH)[2];
  const unsetShowList = useUrlParam(urlParamNames.search.SHOW_LIST)[2];

  const clickAreaItem = () => {
    setSearchCoordinates(`${area.centerCoordinates.latitude}_${area.centerCoordinates.longitude}`);
    centreMapOnLocation &&
      centreMapOnLocation(area.centerCoordinates.latitude, area.centerCoordinates.longitude);
    unsetSearchValue();
    unsetShowList();
  };

  return (
    <button
      className={styles.action}
      aria-label={`Center map on ${area.name}`}
      onClick={clickAreaItem}>
      <span className={styles.item}>
        <span className={styles.name}>{area.name}</span>
        <Icon className={styles.icon} name="map-marker" />
      </span>
    </button>
  );
});

export const AreasPanel: React.FC = observer(function AreasPanel() {
  const store = useStore();
  const areaResults = store.areasRepo.searchResults;

  if (!areaResults.length) {
    return null;
  }

  return (
    <section className={styles.root}>
      <h2 className={styles.title}>Areas</h2>
      <ul aria-label={'Area results list'}>
        {areaResults.map((a, i) => (
          <li key={i}>
            <AreaItem area={a} />
          </li>
        ))}
      </ul>
    </section>
  );
});
