import React, { useEffect, useRef, useState } from 'react';
import styles from './LandingPage.module.scss';
import { Link } from 'react-router-dom';
import { addParamToLocation } from 'infrastructure/locationUtils';
import { urlParams as shellUrlParams } from 'views/components/application/Shell';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { observer } from 'mobx-react-lite';
import { getMillisecondsFromCssTime } from 'infrastructure/cssUtils';
import { siteDefault } from 'views/themes';
import cn from 'classnames';
import { Icon } from 'views/components/Icon';
import { FloatingBackButton } from 'views/components/detail/floatingBackButton/FloatingBackButton';
import { useStore } from 'views/hooks';

interface LandingPageProps {
  closeEvent: () => void;
}

export const LandingPage: React.FC<LandingPageProps> = observer(function({ closeEvent }) {
  const store = useStore();
  const tinyScreen = store.environment.isTinyViewport;

  const contentContainer = useRef<HTMLElement | null>(null);
  const overlay = useRef<HTMLDivElement | null>(null);
  const [floatingCloseHidden, setFloatingCloseHidden] = React.useState(true);

  const onScrollEvent = () => {
    if (contentContainer.current) {
      setFloatingCloseHidden(contentContainer.current.scrollTop < 250);
    }
  };

  const setDoNotShowLandingPageCookie = (doNotShow: boolean) => {
    document.cookie = doNotShow
      ? 'doNotShowLandingPage=true; expires=Fri, 31 Dec 9999 23:59:59 UTC; path=/;'
      : 'doNotShowLandingPage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  const closePage = () => {
    if (!contentContainer.current || !overlay.current) return;
    contentContainer.current.style.opacity = '0';
    overlay.current.style.opacity = '0';
    setTimeout(() => {
      closeEvent();
    }, getMillisecondsFromCssTime(siteDefault['--reveal-page-transition-time']));
  };

  document.addEventListener('keyup', e => {
    e.key === 'Escape' && closePage();
  });

  useEffect(() => {
    contentContainer.current && contentContainer.current.getElementsByTagName('button')[0].focus();
  }, []);

  return (
    <>
      <div className={styles.overlay} onClick={closePage} ref={overlay} />
      <article ref={contentContainer} className={styles.root} onScroll={onScrollEvent}>
        <BackButton
          className={styles.closeButton}
          onClick={() => {
            closePage();
          }}
        />
        <h2>Accessible Australia – Know before you go!</h2>
        <p>
          Welcome to Spinal Life’s Accessible Australia app, a free online resource that gives you
          the ability to <i>know before you go</i> and leave personal reviews of places that you
          visit. We want to put the control in your hands and give you the tools to help others in
          the community explore with freedom and confidence.
        </p>

        <h3>Search</h3>

        <p>
          Use the search bar at the top of the screen to search for destinations by name or
          category. Apply filters to your search to show only those results with your chosen
          accessibility or service rating.
        </p>

        <details className={styles.video}>
          <summary>Click here to watch video demonstrations</summary>
          <div>
            <iframe
              title="Search"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/AEOezOL8sbw"
              frameBorder="0"
              allow="encrypted-media; modestbranding"
              allowFullScreen></iframe>
          </div>

          <div>
            <iframe
              title="Filters"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/zjgyW27c4PU"
              frameBorder="0"
              allow="encrypted-media; modestbranding"
              allowFullScreen></iframe>
          </div>
        </details>

        <h3>Review</h3>
        <p>
          <Link
            onClick={() => closePage()}
            className={styles.action}
            title="Sign in"
            aria-label="Sign in"
            to={l => addParamToLocation(l, shellUrlParams.SHOW_PROFILE, 'y')}>
            Sign in
          </Link>{' '}
          so you can leave your own reviews on accessibility and level of service for other users to
          learn from.
        </p>

        <details className={styles.video}>
          <summary>Click here to watch a video demonstration</summary>
          <div>
            <iframe
              title="Leaving reviews"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/zjWh6peTIC8"
              frameBorder="0"
              allow="encrypted-media; modestbranding"
              allowFullScreen></iframe>
          </div>
        </details>

        <h3>Add Places</h3>
        <p>
          Can't find a destination you are looking for?{' '}
          <Link
            onClick={() => closePage()}
            className={styles.action}
            title="Sign in"
            aria-label="Sign in"
            to={l => addParamToLocation(l, shellUrlParams.SHOW_PROFILE, 'y')}>
            Sign in
          </Link>{' '}
          and add a new location! Look for the Map Actions button at the bottom of your screen.
        </p>

        <details className={styles.video}>
          <summary>Click here to watch a video demonstration</summary>
          <div>
            <iframe
              title="Adding places"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/VzhBkkfoVT8"
              frameBorder="0"
              allow="encrypted-media; modestbranding"
              allowFullScreen></iframe>
          </div>
        </details>

        <h3>Add to your home screen in three easy steps</h3>
        <h4>iPhone and iPad</h4>
        <ol>
          <li>
            <div className={styles.inlineIconContainer}>
              <span>Tap the share button on the browser’s toolbar</span>
              <Icon name="share-icon" className={styles.inlineIcon} />
            </div>
          </li>
          <li>Scroll down to add to home screen</li>
          <li>Tap add. The browser will close and the app will be on your home screen</li>
        </ol>
        <h4>Android Phone and Tablet</h4>
        <ol>
          <li>
            <div className={styles.inlineIconContainer}>
              <span>On the top right hand corner of your screen, tap menu</span>
              <Icon name="more-vert" className={styles.inlineIcon} />
            </div>
          </li>
          <li>Tap "Add to home screen"</li>
          <li>
            An image of the app will come up. Hold down on the image and it will add to screen
          </li>
        </ol>

        <p className={styles.whereToFindTip}>
          If you close this window and would like to view it again, you can find it using the Help
          link at the bottom of the Profile page.
        </p>

        <details>
          <summary>
            <h3 className={styles.summaryHeader}>Frequently Asked Questions</h3>
          </summary>
          <dl>
            <CollapsableDescriptionListItem
              term={
                'What destinations should I review and what should I base my accessibility review on?'
              }
              description={
                <>
                  <p>
                    We’re looking for reviews on any venues and destinations in your community or
                    the communities you visit, including restaurants, cafes, bars, hotels,
                    accommodation options, shopping centres, public bathrooms, beaches, parks and
                    more.
                  </p>
                  <p>
                    Reviews can be left for accessibility (which is mandatory to leave a review) and
                    overall experience (which is optional) of the location you visit.
                  </p>
                  <p>When reviewing accessibility, please consider:</p>

                  <ol>
                    <li>How accessible it was to get in and out of the venue</li>
                    <li>How accessible it was to navigate around the venue</li>
                    <li>Did the venue have bathrooms? Was there an accessible option?</li>
                    <li>
                      Did I have to ask a staff member for assistance, or could I access the venue
                      independently?
                    </li>
                    <li>Are there accessible parking options nearby?</li>
                  </ol>

                  <p>
                    The accessibility rating should be exclusively about the venue’s accessibility
                    and not the quality of services or products offered.
                  </p>
                  <p>Ratings are out of 5 stars, which denote:</p>
                  <ul>
                    <li>
                      <span className={styles.bold}>1 Star</span> – venue could not be accessed at
                      all
                    </li>
                    <li>
                      <span className={styles.bold}>2 Stars</span> – venue could be accessed and
                      navigated with great difficulty
                    </li>
                    <li>
                      <span className={styles.bold}>3 Stars</span> – venue could be accessed and
                      navigated with mild difficulty
                    </li>
                    <li>
                      <span className={styles.bold}>4 Stars</span> – venue could be accessed and
                      navigated with little difficulty, however there was room for improvement
                    </li>
                    <li>
                      <span className={styles.bold}>5 Stars</span> – venue is completely accessible
                      and could be accessed and navigated with ease.
                    </li>
                  </ul>
                  <p>
                    The overall experience review is about exactly that, your overall experience at
                    the venue. This can include quality of service, quality of your food or drinks,
                    quality of your accommodation and facilities etc.
                  </p>
                </>
              }
            />

            <CollapsableDescriptionListItem
              term={'How do I leave a review?'}
              description={
                <ol>
                  <li>
                    Use the Search bar or Categories list to find your destination (note: if your
                    chosen destination is not on Accessible Australia, please go to the next section
                    before returning here).
                  </li>
                  <li>
                    Once your destination has been selected, at the bottom of the left-hand menu,
                    under Reviews, click the link to leave a new review (if you have not yet logged
                    in, you’ll be prompted to do so here).
                  </li>
                  <li>
                    Give the venue an Accessibility rating out of 5 stars (with 1 star being
                    inaccessible and 5 stars being fully accessible).
                  </li>
                  <li>
                    Optional: Give the venue a Service rating out of 5 stars (with 1 star being
                    unhelpful service and 5 stars being very helpful service)
                  </li>
                  <li>Select the mobility aid used during your visit.</li>
                  <li>
                    Under <span className={styles.bold}>Review</span>, leave a short text review
                    giving more information about the venue’s accessibility. Make sure you consider
                    things like:
                  </li>
                  <ul>
                    <li>How accessible it was to get in and out of the venue?</li>
                    <li>How accessible it was to navigate around the venue?</li>
                    <li>Did the venue have bathrooms? Was there an accessible option?</li>
                    <li>Is there accessible parking options nearby?</li>
                  </ul>
                  <li>
                    Under Photos, you can also select the “Choose Files” button to upload up to five
                    images taken of the venue.{' '}
                  </li>
                  <li>Click “Post Review” and you’re done! </li>
                </ol>
              }
            />

            <CollapsableDescriptionListItem
              term={
                'What happens if the destination I’m visiting isn’t already listed on Accessible Australia?'
              }
              description={
                <>
                  <ol>
                    <li>On the bottom right corner, select the button </li>
                    <li>
                      Select <span className={styles.bold}>Add Place</span>.{' '}
                    </li>
                    <li>
                      Complete the details about the destination you are creating, including name,
                      category, address, and, if know, phone number, email address and website.{' '}
                    </li>
                    <li>
                      Click <span className={styles.bold}>Submit</span>.{' '}
                    </li>
                    <li>A new destination has now been created in Accessible Australia. </li>
                  </ol>
                </>
              }
            />

            <CollapsableDescriptionListItem
              term={'Do I have to advise the venue owner that I’m reviewing their premises?'}
              description={
                <>
                  <p>
                    This is up to you – if you are patronising a venue as a regular customer, you
                    may choose to not advise them.
                  </p>
                  <p>
                    If you plan to conduct a full accessibility tour of the venue, or take images to
                    upload with your review, you may like to advise the venue staff or manager/owner
                    on entry.
                  </p>
                  <p>
                    Here is some suggested wording if you’d like to discuss the review with the
                    venue staff or manager/owner:
                  </p>
                  <blockquote>
                    “My name is &lt;your name&gt; and I’m a beta tester and reviewer for Spinal Life
                    Australia’s Accessible Australia app. I’m conducting accessibility reviews of
                    local venues and businesses to advise Accessible Australia users if a venue is
                    accessible for users of wheelchairs and other mobility devices. Would you be
                    okay if I had a quick look around the premises?”
                  </blockquote>
                  <p>
                    Please only continue with the review if the venue representative is comfortable
                    with it.
                  </p>
                  <p>
                    If they have any questions about Accessible Australia or the review process,
                    they can contact
                    <a href="mailto:accessibleaustralia@spinal.com.au">
                      {' '}
                      accessibleaustralia@spinal.com.au
                    </a>
                    .
                  </p>
                </>
              }
            />

            <CollapsableDescriptionListItem
              term={'What should I do if I cannot enter a destination at all?'}
              description={
                <p>
                  While this may seem like a one star review, it may be worth asking a staff member
                  first if they have any alternative entry options, such as a lift or ramp, before
                  leaving a review. If they do not, or you cannot get the attention of a staff
                  member, you are welcome to provide a one-star review for accessibility. An overall
                  experience review would not be required.
                </p>
              }
            />

            <CollapsableDescriptionListItem
              term={'Do I have to tell Spinal Life Australia that my review has been completed?'}
              description={
                <>
                  No – your review will automatically be uploaded to Accessible Australia. Thank you
                  for your input!
                </>
              }
            />

            <CollapsableDescriptionListItem
              term={
                'What if I notice an error with Accessible Australia, or have a suggestion for improvement?'
              }
              description={
                <>
                  Please contact{' '}
                  <a href="mailto:accessibleaustralia@spinal.com.au">
                    accessibleaustralia@spinal.com.au
                  </a>{' '}
                  with your feedback.
                </>
              }
            />
          </dl>
        </details>

        <footer>
          <label>
            <input
              type={'checkbox'}
              className={styles.doNotShowAgainCheckOption}
              defaultChecked={document.cookie
                .split(';')
                .map(cookie => cookie.trim())
                .includes('doNotShowLandingPage=true')}
              onChange={e => {
                setDoNotShowLandingPageCookie(e.target.checked);
              }}
            />
            Do not show again
          </label>

          <div className={styles.largeCloseButtonContainer}>
            <button className={styles.largeCloseButton} onClick={() => closePage()}>
              Close
            </button>
          </div>
        </footer>
        {tinyScreen && (
          <FloatingBackButton
            className={styles.floatingBackButton}
            hidden={floatingCloseHidden}
            closePage={closePage}
          />
        )}
      </article>
    </>
  );
});

interface ICollapsableDescriptionListItemProps {
  term: string;
  description: string | React.ReactNode;
}

const CollapsableDescriptionListItem: React.FC<ICollapsableDescriptionListItemProps> = function({
  term,
  description
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <>
      <button
        type={'button'}
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={styles.faqDefinition}
        aria-expanded={!isCollapsed}>
        <dt>
          <Icon
            name={'chevron-down'}
            className={cn({ [styles.rotated]: isCollapsed }, styles.faqExpandedIcon)}
          />{' '}
          {term}
        </dt>
      </button>
      <dd
        className={cn({ [styles.collapsed]: isCollapsed })}
        onClick={() => setIsCollapsed(!isCollapsed)}
        aria-hidden={isCollapsed}>
        {description}
      </dd>
    </>
  );
};
