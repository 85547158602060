import React from 'react';
import cn from 'classnames';
import styles from './StarRatingInput.module.scss';
import { ValidationOptions, FieldError } from 'react-hook-form';
import { Icon } from 'views/components/Icon';

interface IStarRatingInputProps {
  inputErrors: FieldError | undefined;
  name: string;
  register: (Ref: HTMLInputElement, validateRule?: ValidationOptions) => void;
  label: string;
  required?: boolean;
  getCurrentValue: () => string;
  resetCurrentValue: () => void;
  selected: string;
}

export const StarRatingInput: React.FC<IStarRatingInputProps> = ({
  register,
  inputErrors,
  name,
  label,
  required,
  getCurrentValue,
  resetCurrentValue,
  selected,
}) => {
  function toggleSelected(currentValue: string, selectedValue: string) {
    if (currentValue !== undefined && currentValue === selectedValue) {
      resetCurrentValue();
    }
  }

  return (
    <fieldset className={styles.root}>
      <legend className={cn(styles.label, { [styles.error]: inputErrors })}>
        <span>{label}</span>
        {required && <span className={styles.required}>Required</span>}
      </legend>
      <div className={cn(styles.rating, { [styles.error]: inputErrors })}>
        {[1, 2, 3, 4, 5].map(i => (
          <label key={i}>
            <span className={styles.hidden}>{i} out of 5 stars</span>
            <input
              className={styles.hidden}
              name={name}
              type="radio"
              value={i}
              ref={register}
              onClick={() => toggleSelected(selected, i.toString())}
            />
            <Icon
              className={cn(styles.star, {
                [styles.selected]: i <= Number(getCurrentValue()),
              })}
              name="star"
            />
          </label>
        ))}
        <button
          className={styles.hidden}
          aria-label={'clear rating'}
          onClick={() => resetCurrentValue()}>
          Clear Rating
        </button>
      </div>
      {inputErrors && <span className={styles.errorMessage}>{inputErrors.message}</span>}
    </fieldset>
  );
};
