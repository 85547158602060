import React from 'react';
import styles from './RadioSlider.module.scss';

interface IRadioSliderProps {
  register: (ref: Element | null) => void;
  name: string;
  options: IOption[];
  selected: string;
  label: string;
}

interface IOption {
  display: string;
  value: string;
  icon?: JSX.Element;
}

export const RadioSlider: React.FC<IRadioSliderProps> = ({
  register,
  name,
  options,
  selected,
  label,
}) => {
  let selectedIndex = options.findIndex(o => o.value === selected);
  if (selectedIndex === -1) {
    throw new Error(
      `RadioSlider: "selected" must be a member of "options.value"\nSelected: <${selected}>\nValues: ${options.map(
        o => `<${o.value}>`
      )}`
    );
  }

  const width = Number(((1 / options.length) * 100).toFixed(2));
  const offset = selectedIndex * width;

  return (
    <fieldset className={styles.root}>
      <legend className={styles.label}>{label}</legend>
      <div className={styles.radioSlider}>
        <span
          className={styles.selectionIndicator}
          style={{ width: `${width}%`, left: `${offset}%` }}
        />
        {options.map(option => (
          <label key={option.value}>
            <input
              className="visually-hidden"
              type="radio"
              name={name}
              value={option.value}
              ref={register}
              aria-label={`${option.value} stars or above ${label} filter`}
            />
            <span>{option.display}</span>
            {!!option.icon && option.icon}
          </label>
        ))}
      </div>
    </fieldset>
  );
};
