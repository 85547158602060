import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Icon } from 'views/components/Icon';
import { LoadingPane } from 'views/components/LoadingPane';
import { Drawer } from 'views/components/layout/drawer/Drawer';
import { useStore, useUrlParam } from 'views/hooks';
import { EmailConsentNotification } from 'views/routes/emailConsentNotification/EmailConsentNotification';
import { LandingPage } from 'views/routes/landingPage/LandingPage';
import { urlParamNames } from 'views/routes/routePaths';
import { Profile } from 'views/routes/welcome/Profile';
import { Welcome } from 'views/routes/welcome/Welcome';
import { Feedback } from 'views/routes/welcome/feedback/Feedback';
import { LegalInformationPage } from 'views/routes/welcome/legal/LegalInformationPage';
import { PrivacyPolicy } from 'views/routes/welcome/legal/PrivacyPolicy';
import { TermsOfUse } from 'views/routes/welcome/legal/TermsOfUse';
import { DeleteFacebookData } from 'views/routes/welcome/legal/deleteFacebookData/DeleteFacebookData';
import { UpdateUserProfile } from 'views/routes/welcome/updateUserProfile/UpdateUserProfile';
import { LandscapeDeviceWarning } from './LandscapeDeviceWarning';
import styles from './Shell.module.scss';

const urlParamsInternal = {
  SHOW_PROFILE: 'showProfile',
  SHOW_TERMS_OF_USE: 'showTermsOfUse',
  SHOW_PRIVACY_POLICY: 'showPrivacyPolicy',
  SHOW_FEEDBACK: 'showFeedback',
  SHOW_UPDATE_PROFILE: 'showUpdateProfile',
  SHOW_DELETE_FACEBOOK_DATA: 'showDeleteFacebookData',
};

export const urlParams: Readonly<typeof urlParamsInternal> = urlParamsInternal;

export const Shell: React.FC = observer(function ({ children }) {
  const store = useStore();
  const security = store.security;
  const isSmallViewport = store.environment.isSmallViewport;

  const modalRef = React.useRef<HTMLDivElement | null>(null);
  const [modalElement, setModalElement] = React.useState<HTMLDivElement | null>(null);

  const [showProfile, , unsetShowProfile] = useUrlParam(urlParams.SHOW_PROFILE);
  const [showTermsOfUse, , unsetShowTermsOfUse] = useUrlParam(urlParams.SHOW_TERMS_OF_USE);
  const [showPrivacyPolicy, , unsetShowPrivacyPolicy] = useUrlParam(urlParams.SHOW_PRIVACY_POLICY);
  const [showFeedback, , unsetShowFeedback] = useUrlParam(urlParams.SHOW_FEEDBACK);
  const [showUpdateProfile, , unsetShowUpdateProfile] = useUrlParam(urlParams.SHOW_UPDATE_PROFILE);
  const [showDeleteFacebookData, , unsetShowDeleteFacebookData] = useUrlParam(
    urlParams.SHOW_DELETE_FACEBOOK_DATA
  );
  const [showLandingPage, setShowLandPage, unsetShowLandingPage] = useUrlParam(
    urlParamNames.landingPage.SHOW_LANDING_PAGE
  );
  const [
    showEmailConsentNotification,
    setShowEmailConsentNotification,
    unsetShowEmailConsentNotification,
  ] = useUrlParam(urlParamNames.emailConsentNotification.SHOW_EMAIL_CONSENT_NOTIFICATION);
  const overlappingPageIsOpen =
    !!showTermsOfUse ||
    !!showPrivacyPolicy ||
    !!showFeedback ||
    !!showUpdateProfile ||
    !!showDeleteFacebookData;

  const closeModal = () => {
    unsetShowTermsOfUse();
    unsetShowProfile();
    unsetShowPrivacyPolicy();
    unsetShowFeedback();
    unsetShowUpdateProfile();
    unsetShowDeleteFacebookData();
  };

  const closeIcon = (
    <span aria-label="Go back" title="Go back">
      <Icon name="arrow-left" />
    </span>
  );

  React.useEffect(() => {
    setModalElement(modalRef.current);
  }, []);

  React.useEffect(() => {
    if (store.security.isLoading) return;
    const landingPageShouldRender =
      !document.cookie
        .split(';')
        .map(cookie => cookie.trim())
        .includes('doNotShowLandingPage=true') && !store.security.isLoggedIn;

    const emailConsentNotificationShouldRender =
      store.security.isLoggedIn && store.security.userProfile?.shouldSeeEmailConsentPrompt;

    if (landingPageShouldRender) setShowLandPage('y');
    if (emailConsentNotificationShouldRender) setShowEmailConsentNotification('y');
  }, [
    setShowLandPage,
    setShowEmailConsentNotification,
    store.security.isLoading,
    store.security.isLoggedIn,
    store.security.userProfile,
  ]);

  return (
    <main className={styles.root} data-testid="shell-main">
      <div className={styles.content}>{children}</div>
      <div className={styles.modalContainer} ref={modalRef} />
      {modalElement && (
        <Modal
          open={!!(showProfile || showTermsOfUse)}
          onClose={closeModal}
          container={modalElement}
          focusTrapped={!isSmallViewport}
          closeIcon={closeIcon}
          classNames={{
            overlay: styles.modalOverlay,
            modal: cn(styles.modal, { [styles.overlappingPageOpen]: overlappingPageIsOpen }),
            closeButton: cn(styles.closeButton, { [styles.hidden]: overlappingPageIsOpen }),
            animationIn: styles.fadeIn,
            animationOut: styles.fadeOut,
          }}>
          <LoadingPane isLoading={!security.userProfile && security.isLoading}>
            {!security.isLoggedIn && <Welcome />}
            {security.isLoggedIn && (
              <>
                <Profile hidden={overlappingPageIsOpen} />
                <Drawer
                  className={styles.feedbackPage}
                  isActive={!!showFeedback}
                  transition="slide-in-right">
                  <Feedback />
                </Drawer>
                <Drawer
                  className={styles.updateUserProfilePage}
                  isActive={!!showUpdateProfile}
                  transition="slide-in-right">
                  <UpdateUserProfile />
                </Drawer>
              </>
            )}
          </LoadingPane>
          <LegalInformationPage pageType={'SHOW_TERMS_OF_USE'}>
            <TermsOfUse />
          </LegalInformationPage>
          <LegalInformationPage pageType={'SHOW_PRIVACY_POLICY'}>
            <PrivacyPolicy />
          </LegalInformationPage>
          <LegalInformationPage pageType={'SHOW_DELETE_FACEBOOK_DATA'}>
            <DeleteFacebookData />
          </LegalInformationPage>
        </Modal>
      )}
      {showLandingPage && <LandingPage closeEvent={unsetShowLandingPage} />}
      {showEmailConsentNotification && (
        <EmailConsentNotification closeEvent={unsetShowEmailConsentNotification} />
      )}
      <LandscapeDeviceWarning />
    </main>
  );
});
