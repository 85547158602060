import React from 'react';
import styles from './Welcome.module.scss';
import { useStore } from 'views/hooks';
import { Icon } from 'views/components/Icon';
import WelcomeSplash from 'images/welcome-splash.png';
import { FooterLinks } from './legal/FooterLinks';

export const Welcome: React.FC = function () {
  const store = useStore();
  const security = store.security;

  return (
    <article className={styles.root}>
      <section className={styles.welcome}>
        <h2>Welcome</h2>
        <p>
          Accessible Australia is on a mission to make the world more accessible and give everyone
          the confidence to explore.
        </p>
        <img src={WelcomeSplash} alt="" aria-hidden="true" />
      </section>
      <section className={styles.signInButtons}>
        <h3 className={styles.heading}>Sign in with</h3>
        <button
          onClick={() => security.signIn('facebook')}
          className={styles.facebookButton}
          aria-label={'Sign in with Facebook'}>
          Facebook
          <Icon name={'facebook-sign-in-icon'} className={styles.socialIcon} />
        </button>
        <button
          onClick={() => security.signIn('google')}
          className={styles.googleButton}
          aria-label={'Sign in with Google'}>
          Google
          <Icon name={'google-sign-in-icon'} className={styles.socialIcon} />
        </button>
        <div className={styles.legalLinks}>
          <FooterLinks />
        </div>
      </section>
    </article>
  );
};
