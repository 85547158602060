import cn from 'classnames';
import React from 'react';
import { Icon } from 'views/components/Icon';
import { urlParams } from 'views/components/application/Shell';
import { useStore, useUrlParam } from 'views/hooks';
import styles from './DeleteFacebookData.module.scss';

export const DeleteFacebookData: React.FC = function () {
  const store = useStore();
  const security = store.security;
  const facebookId = security.userProfile?.facebookId;
  const [, , unsetShowDeleteFacebookData] = useUrlParam(urlParams.SHOW_DELETE_FACEBOOK_DATA);
  const [state, setState] = React.useState<'idle' | 'submitting' | 'submitted'>('idle');

  if ((!security.isLoggedIn || !facebookId) && state === 'idle')
    return (
      <article className={styles.root}>
        <h2>Delete Facebook Data</h2>
        <p>
          Only users who are signed in with Facebook are able to request Accessible Australia to
          delete their personal data provided by Facebook.
        </p>
      </article>
    );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!security.isLoggedIn || !facebookId) return;
    try {
      setState('submitting');
      await security.deleteFacebookUserData(facebookId);
      setState('submitted');
      unsetShowDeleteFacebookData();
      store.notifications.addSuccess(
        'Your Facebook Data has been deleted and you have been signed out'
      );
    } catch (error) {
      setState('idle');
      throw error;
    }
  };

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    unsetShowDeleteFacebookData();
  };

  return (
    <article className={styles.root}>
      <h2>Delete Facebook Data</h2>
      <p>Request Accessible Australia to delete your personal data provided by Facebook.</p>
      <p>
        Deletion of your data is permanent. You will still be able to view and search Accessible
        Australia, but you will not be able to add new Points of Interest or leave reviews.
      </p>
      <p>
        If you’d like to start using Accessible Australia, you’ll need to Sign In again using either
        Facebook or Google.
      </p>
      <form onSubmit={handleSubmit} className={styles.deleteForm} autoComplete="off">
        <div className={styles.deleteOptions}>
          <button
            type="submit"
            aria-label="Confirm Deletion Request"
            aria-live="polite"
            aria-atomic={true}
            className={cn(styles.submit, {
              [styles.isSubmitting]: state === 'submitting',
              [styles.isSubmitted]: state === 'submitted',
            })}
            disabled={state === 'submitting' || state === 'submitted'}>
            <span>
              {state === 'submitted'
                ? 'Deleted'
                : state === 'submitting'
                ? 'Deleting...'
                : 'Delete'}
            </span>
            <Icon
              name={state === 'submitting' ? 'loading' : 'exclamation-triangle'}
              className={cn(styles.icon, { [styles.isSubmitting]: state === 'submitting' })}
            />
          </button>
          <button
            className={cn(styles.cancel, { [styles.disabled]: state !== 'idle' })}
            onClick={handleCancel}
            disabled={state === 'submitting' || state === 'submitted'}
            aria-label="Cancel Deletion Request">
            Cancel
          </button>
        </div>
      </form>
    </article>
  );
};
