import React from 'react';
import styles from './ReviewItem.module.scss';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { IReviewDtoModel } from 'api/models/Domain/Queries/Reviews/GetReviewsQuery/ReviewDtoModel';
import { DateTime } from 'luxon';
import { MobilityAid, mobilityAidDescription } from 'api/enums/MobilityAid';
import { StarRating } from 'views/components/detail/starRating/StarRating';
import { Icon } from 'views/components/Icon';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'views/routes/routePaths';
import { AaGallery } from 'views/components/aaGallery/AaGallery';
import { IReviewImageUrlsDtoModel } from 'api/models/Domain/Queries/SharedDtos/Reviews/ReviewImageUrlsDtoModel';

interface IReviewImageProps {
  images: IReviewImageUrlsDtoModel[];
}

const ReviewImages: React.FC<IReviewImageProps> = observer(function ReviewImages({ images }) {
  const [showFullscreenGallery, setShowFullscreenGallery] = React.useState(false);
  const [galleryIndex, setGalleryIndex] = React.useState(0);
  const spaceCharCode = 32;
  const enterCharCode = 13;

  function goFullScreen(index: number) {
    setShowFullscreenGallery(true);
    setGalleryIndex(index);
  }

  if (images.length === 0) return null;

  return (
    <div className={styles.images}>
      <img
        src={images[0].imageUrl}
        alt=""
        className={styles.image}
        onClick={() => goFullScreen(0)}
        tabIndex={0}
        onKeyPress={event => {
          if (event.charCode === spaceCharCode || event.charCode === enterCharCode) {
            goFullScreen(0);
          }
        }}
      />
      {images.length > 1 && (
        <div
          className={styles.imageWrapper}
          tabIndex={0}
          onKeyPress={event => {
            if (event.charCode === spaceCharCode || event.charCode === enterCharCode) {
              goFullScreen(0);
            }
          }}>
          <img
            className={styles.image}
            src={images[1].imageUrl}
            alt=""
            onClick={() => goFullScreen(1)}
          />
          {images.length > 2 && <div className={styles.imageOverlay}>+{images.length - 2}</div>}
        </div>
      )}
      {showFullscreenGallery && (
        <AaGallery
          items={images}
          className={styles.gallery}
          setShowFullscreenGallery={setShowFullscreenGallery}
          startIndex={galleryIndex}
        />
      )}
    </div>
  );
});

interface IReviewProps {
  review: IReviewDtoModel;
  poiID: string;
}

export const ReviewItem: React.FC<IReviewProps> = observer(function ReviewItem({ review, poiID }) {
  let newLineRegex = /[\r\n]+/g;
  const history = useHistory();

  const mobilityAidText =
    ' using ' +
    (review.mobilityAidUsed === MobilityAid.None
      ? 'no mobility aid'
      : (review.mobilityAidUsed !== MobilityAid.Crutches ? 'a ' : '') +
        mobilityAidDescription(review.mobilityAidUsed));

  return (
    <section className={styles.root}>
      <span className={styles.date}>
        {DateTime.fromISO(review.createdAt).setLocale('en-au').toLocaleString()}
      </span>
      <span className={styles.userName}>{review.userDisplayName}</span>
      <span title={'Edit Review'} className={styles.editReview}>
        {review.editable && (
          <button
            className={styles.editButton}
            onClick={() => {
              history.push(
                routePaths.updateReview.toKeepingExistingParams(poiID, review.id)(history.location)
              );
            }}>
            Edit
          </button>
        )}
      </span>
      <span className={styles.userName}>{review.userDisplayName}</span>
      <span className={styles.mobilityAidUsed}>{mobilityAidText}</span>
      <div className={styles.ratingGroup}>
        <Icon aria-label={'Accessibility Rating'} name="wheelchair" className={styles.icon} />
        <StarRating
          rating={review.accessibilityRating}
          className={cn(styles.rating, styles.accessibilityRating)}
          titlePrefix="Accessibility"
        />
      </div>
      <div className={styles.ratingGroup}>
        {review.serviceRating && (
          <>
            <Icon aria-label={'Service Rating'} name="service" className={styles.icon} />
            <StarRating
              rating={review.serviceRating}
              className={cn(styles.rating, styles.serviceRating)}
              titlePrefix="Service"
            />
          </>
        )}
      </div>
      <div className={styles.reviewText}>
        {review.reviewText.split(newLineRegex).map((text, i) => (
          <p key={i}>{text}</p>
        ))}
      </div>
      {review.hasBeenEdited && <span className={styles.edited}>(edited)</span>}
      <ReviewImages images={review.reviewImageUrls} />
    </section>
  );
});
