import React from 'react';
import styles from './CategoriesPanel.module.scss';
import { observer } from 'mobx-react-lite';
import {
  PointOfInterestCategory,
  pointOfInterestCategoryDescription,
  getPointOfInterestCategoryDescriptor,
} from 'api/enums/PointOfInterestCategory';
import { useStore } from 'views/hooks';
import { CategoryIcon } from 'views/routes/pois/CategoryIcon';
import { Link } from 'react-router-dom';
import { routePaths } from 'views/routes/routePaths';
import { defaultSearchRadius } from 'views/routes/pois/searchPanel/poisPanel/searchFurther/SearchFurther';

interface ICategoryItemProps {
  category: PointOfInterestCategory;
}

const CategoryItem: React.FC<ICategoryItemProps> = observer(function CategoryItem({ category }) {
  return (
    <Link
      className={styles.item}
      to={routePaths.pois.toKeepingExistingParams({
        SEARCH: undefined,
        SEARCH_COORDINATES: undefined,
        CATEGORY: getPointOfInterestCategoryDescriptor(category).name,
        SEARCH_RADIUS: defaultSearchRadius,
      })}>
      <span className={styles.name}>{pointOfInterestCategoryDescription(category)}</span>
      <CategoryIcon className={styles.icon} category={category} />
    </Link>
  );
});

export const CategoriesPanel: React.FC = observer(function CategoriesPanel() {
  const store = useStore();
  const categoriesRepo = store.categoriesRepo;

  if (!categoriesRepo.searchResults.length) {
    return null;
  }

  return (
    <section className={styles.root}>
      <h2 className={'visually-hidden'} aria-label={'Search by Category'}>
        Search by Category
      </h2>
      <ul data-testid="categories" aria-label={'Categories list'}>
        {categoriesRepo.searchResults.map(({ title, categories }) => (
          <li key={title}>
            <h3 className={styles.title}>{title}</h3>
            <ul>
              {categories.map(c => (
                <li key={c}>
                  <CategoryItem category={c} />
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </section>
  );
});
