import React from 'react';
import styles from './Search.module.scss';
import { observer } from 'mobx-react-lite';
import { Route, useParams } from 'react-router-dom';
import { observable } from 'mobx';
import atlas from 'azure-maps-control';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { SearchMap } from './searchMap/SearchMap';
import { SearchPanel } from './searchPanel/SearchPanel';
import { Navigation } from './navigation/Navigation';
import { PointOfInterest } from './pointOfInterest/PointOfInterest';
import { Filters } from 'views/routes/pois/filters/Filters';
import { NewPointOfInterestForm } from './newPointOfInterestForm/NewPointOfInterestForm';
import { useStore, useUrlParam, useFullUrlWithoutParam } from 'views/hooks';
import { Drawer } from 'views/components/layout/drawer/Drawer';
import cn from 'classnames';
import { PointOfInterestDetailsLoader } from './pointOfInterest/PointOfInterestDetailsLoader';
import { ShareToSocials } from 'views/routes/pois/share/ShareToSocials';

interface IMapContextValue {
  centreMapOnUser?: () => Promise<void>;
  centreMapOnLocation?: (lat: number, lng: number, options?: atlas.CameraOptions) => void;
  focusSearchField?: () => void;
  getMapFocusCoords?: () => atlas.data.Position | null;
  setMapOverrideCoords?: () => void;
  getMapNewPoiCoords?: () => atlas.data.Position | null;
  mapHasMoved?: boolean;
  forceReloadSingleMapMarker?: (id: string) => void;
  zoom?: (amount: number) => void;
  setMapNewPoiCoords?: (lat: number, lng: number) => void;
}

export const MapContext = React.createContext<IMapContextValue>({});
MapContext.displayName = 'MapContext';

export const Search: React.FC = observer(function Search() {
  return (
    <article className={styles.root}>
      <MapContext.Provider value={observable<IMapContextValue>({})}>
        <h1 className="visually-hidden">Search Points of Interest</h1>
        <Route path={routePaths.poi.template}>{() => <SearchRoutes />}</Route>
      </MapContext.Provider>
    </article>
  );
});

const SearchRoutes: React.FC = function () {
  const store = useStore();

  const { id } = useParams();

  const [showFilter] = useUrlParam(urlParamNames.search.SHOW_FILTER);
  const [showNewPoiForm] = useUrlParam(urlParamNames.mapContextMenu.SHOW_NEW_POI_FORM);
  const [showFullDetail] = useUrlParam(urlParamNames.search.FULL_DETAIL);
  const [showShare] = useUrlParam(urlParamNames.search.SHOW_SHARE);

  const showFullPoiDetail = !!(id && (!store.environment.isSmallViewport || showFullDetail));

  const currentUrl = useFullUrlWithoutParam(urlParamNames.search.SHOW_SHARE);

  return (
    <>
      <SearchPanel className={styles.searchPanel} />
      <PointOfInterestDetailsLoader />
      <Drawer
        className={cn(styles.filters, {
          [styles.preventSelect]: store.environment.isSmallViewport && !showFilter,
        })}
        isActive={!!showFilter}
        transition="slide-in-left">
        <Filters />
      </Drawer>
      <Navigation className={styles.navigation} />
      <SearchMap className={styles.map} />
      <Drawer className={styles.newPoiForm} isActive={!!showNewPoiForm} transition="slide-in-left">
        <NewPointOfInterestForm />
      </Drawer>
      <Drawer isActive={showFullPoiDetail} transition="slide-in-left" className={styles.poi}>
        <PointOfInterest />
      </Drawer>
      <Drawer isActive={!!showShare} transition="slide-in-left" className={styles.shareToSocials}>
        <ShareToSocials shareUrl={currentUrl} />
      </Drawer>
    </>
  );
};
