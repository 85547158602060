import React, { useContext } from 'react';
import styles from './PoisPanel.module.scss';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { pointOfInterestCategoryDescription } from 'api/enums/PointOfInterestCategory';
import { IPointOfInterestDtoModel } from 'api/models/Domain/Queries/PointOfInterest/SearchPointsOfInterestQuery/PointOfInterestDtoModel';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { CategoryIcon } from 'views/routes/pois/CategoryIcon';
import { MapContext } from 'views/routes/pois/Search';
import { StarRating } from 'views/components/detail/starRating/StarRating';
import { useUrlParam } from 'views/hooks';

interface IPoiResultItem {
  className?: string;
  item: IPointOfInterestDtoModel;
  showFullDetail?: boolean;
  disableZoom?: boolean;
}

export const PoiResultItem: React.FC<IPoiResultItem> = observer(function PoiResultItem({
  className,
  item,
  showFullDetail,
  disableZoom,
}) {
  const mapContext = useContext(MapContext);
  const [, , unsetShowMapActionContextMenu] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU
  );

  const to = routePaths.poi.toKeepingExistingParams(item.id, {
    SHOW_LIST: undefined,
    FULL_DETAIL: showFullDetail || undefined,
  });

  return (
    <Link
      className={cn(className, styles.itemContent)}
      to={to}
      onClick={() => {
        unsetShowMapActionContextMenu();

        !disableZoom &&
          mapContext.centreMapOnLocation &&
          mapContext.centreMapOnLocation(item.location.latitude, item.location.longitude, {
            zoom: 18,
          });
      }}>
      <h3 className={styles.name}>{item.name}</h3>
      <CategoryIcon className={styles.icon} category={item.primaryCategory} />
      <p className={styles.line1}>
        {pointOfInterestCategoryDescription(item.primaryCategory)}・{item.shortAddress}
      </p>
      <div className={styles.line2}>
        <StarRating rating={item.averageAccessibilityRating} titlePrefix="Accessibility" />
        {item.reviewCount > 0 && (
          <span
            title={`${item.reviewCount} ${item.reviewCount > 1 ? 'reviews' : 'review'}`}
            className={styles.reviewCount}>
            {`(${item.reviewCount})`}
          </span>
        )}
        {item.distanceInMetres === undefined ? null : (
          <span aria-label={`${formatDistance(item.distanceInMetres)} from me`}>
            ・{formatDistance(item.distanceInMetres)}
          </span>
        )}
      </div>
    </Link>
  );
});

function formatDistance(distanceInMetres: number) {
  if (distanceInMetres < 1000) {
    return `${distanceInMetres.toLocaleString(undefined, { maximumFractionDigits: 0 })} m`;
  } else if (distanceInMetres < 10000) {
    return `${(distanceInMetres / 1000).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })} km`;
  } else {
    return `${(distanceInMetres / 1000).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    })} km`;
  }
}
