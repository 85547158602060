import cn from 'classnames';
import { getMillisecondsFromCssTime } from 'infrastructure/cssUtils';
import React, { useRef } from 'react';
import { urlParams } from 'views/components/application/Shell';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { FloatingBackButton } from 'views/components/detail/floatingBackButton/FloatingBackButton';
import { useUrlParam } from 'views/hooks';
import { siteDefault } from 'views/themes';
import styles from './LegalInformationPage.module.scss';

interface legalInformationProps {
  pageType: 'SHOW_TERMS_OF_USE' | 'SHOW_PRIVACY_POLICY' | 'SHOW_DELETE_FACEBOOK_DATA';
  children: React.ReactNode;
}

export const LegalInformationPage: React.FC<legalInformationProps> = function ({
  pageType,
  children,
}) {
  const [pageActive, , unsetPage] = useUrlParam(urlParams[pageType]);
  const articleContainer = useRef<HTMLElement | null>(null);
  const [floatingCloseHidden, setFloatingCloseHidden] = React.useState(true);

  const pageOpen = !!pageActive;

  const onScrollEvent = () => {
    articleContainer.current && setFloatingCloseHidden(articleContainer.current.scrollTop < 250);
  };

  const closePage = () => {
    unsetPage();
    setTimeout(() => {
      articleContainer.current!.scrollTop = 0;
    }, getMillisecondsFromCssTime(siteDefault['--reveal-page-transition-time']));
  };

  return (
    <article
      ref={articleContainer}
      className={cn(styles.root, !pageOpen && styles.hidden)}
      aria-hidden={!pageOpen}
      onScroll={onScrollEvent}>
      <BackButton
        onClick={closePage}
        className={cn(styles.closeButton, !pageOpen && styles.hidden)}
      />
      {children}
      <FloatingBackButton hidden={floatingCloseHidden} closePage={closePage} />
    </article>
  );
};
