export enum MobilityAid {
    None = 1,
    Cane = 2,
    Crutches = 3,
    Walker = 4,
    ManualWheelchair = 5,
    ManualWheelchairWithPowerAssist = 6,
    PowerWheelchair = 7,
    MobilityScooter = 8
}
export function mobilityAidDescription(value: MobilityAid) {
    switch (value) {
        case MobilityAid.None:
            return `None`;
        case MobilityAid.Cane:
            return `Cane`;
        case MobilityAid.Crutches:
            return `Crutches`;
        case MobilityAid.Walker:
            return `Walker`;
        case MobilityAid.ManualWheelchair:
            return `Manual Chair`;
        case MobilityAid.ManualWheelchairWithPowerAssist:
            return `Manual Chair (Power Assist)`;
        case MobilityAid.PowerWheelchair:
            return `Power Chair`;
        case MobilityAid.MobilityScooter:
            return `Mobility Scooter`;
    }
}
export interface IMobilityAidDescriptor { value: MobilityAid; name: string; description: string; }
export var allMobilityAid: IMobilityAidDescriptor[] = [
    { value: MobilityAid.None, name: `None`, description: `None` },
    { value: MobilityAid.Cane, name: `Cane`, description: `Cane` },
    { value: MobilityAid.Crutches, name: `Crutches`, description: `Crutches` },
    { value: MobilityAid.Walker, name: `Walker`, description: `Walker` },
    { value: MobilityAid.ManualWheelchair, name: `ManualWheelchair`, description: `Manual Chair` },
    { value: MobilityAid.ManualWheelchairWithPowerAssist, name: `ManualWheelchairWithPowerAssist`, description: `Manual Chair (Power Assist)` },
    { value: MobilityAid.PowerWheelchair, name: `PowerWheelchair`, description: `Power Chair` },
    { value: MobilityAid.MobilityScooter, name: `MobilityScooter`, description: `Mobility Scooter` }
];

export function getMobilityAidDescriptor(value: MobilityAid) {
    switch (value) {
        case MobilityAid.None:
            return { value: MobilityAid.None, name: `None`, description: `None` };
        case MobilityAid.Cane:
            return { value: MobilityAid.Cane, name: `Cane`, description: `Cane` };
        case MobilityAid.Crutches:
            return { value: MobilityAid.Crutches, name: `Crutches`, description: `Crutches` };
        case MobilityAid.Walker:
            return { value: MobilityAid.Walker, name: `Walker`, description: `Walker` };
        case MobilityAid.ManualWheelchair:
            return { value: MobilityAid.ManualWheelchair, name: `ManualWheelchair`, description: `Manual Chair` };
        case MobilityAid.ManualWheelchairWithPowerAssist:
            return { value: MobilityAid.ManualWheelchairWithPowerAssist, name: `ManualWheelchairWithPowerAssist`, description: `Manual Chair (Power Assist)` };
        case MobilityAid.PowerWheelchair:
            return { value: MobilityAid.PowerWheelchair, name: `PowerWheelchair`, description: `Power Chair` };
        case MobilityAid.MobilityScooter:
            return { value: MobilityAid.MobilityScooter, name: `MobilityScooter`, description: `Mobility Scooter` };
    }
}
