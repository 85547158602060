import { types, flow } from 'mobx-state-tree';
import { getAjax } from '../RootStoreModel';

type IConfigDto = Web.Controllers.ConfigController.IConfigDto;

const AzureConfigModel = types.model('AzureConfigModel', {
  key: types.maybe(types.string),
});

export const GoogleAnalyticsConfigModel = types.model('GoogleAnalyticsConfigModel', {
  trackingKey: types.maybe(types.string),
});

export const ConfigModel = types
  .model('ConfigModel', {
    azure: types.optional(AzureConfigModel, {}),
    googleAnalytics: types.optional(GoogleAnalyticsConfigModel, {}),
    enableWebBeacons: false,
    hasLoaded: false,
    isLoading: false,
  })
  .actions(self => {
    const load = function* () {
      try {
        self.isLoading = true;
        const config: IConfigDto = yield getAjax(self).get('/api/config').json();
        self.azure.key = config.azureMapKey;
        self.enableWebBeacons = config.enableWebBeacons;
        self.googleAnalytics.trackingKey = config.googleAnalyticsTrackingKey;
        self.hasLoaded = true;
      } finally {
        self.isLoading = false;
      }
    };
    return {
      load: flow(load),
    };
  });
