import React from 'react';
import styles from './TermsOfUse.module.scss';
import { Link } from 'react-router-dom';
import { addParamToLocation } from 'infrastructure/locationUtils';
import { urlParams as shellUrlParams } from 'views/components/application/Shell';

export const TermsOfUse: React.FC = function () {
  return (
    <article className={styles.root}>
      <section>
        <h2>Terms of Use – Accessible Australia app</h2>

        <p>
          In these Terms of Use, reference to “<span className={styles.bold}>we</span>”, “
          <span className={styles.bold}>our</span>”, “<span className={styles.bold}>us</span>”, or “
          <span className={styles.bold}>Spinal Life</span>” is a reference to Spinal Life Australia
          Ltd ACN 167 906 256.
        </p>

        <p>
          These Terms of Use govern your use of the Accessible Australia app (
          <span className={styles.bold}>App</span>). Our app aims to support users to be able to
          more easily navigate to features of the community, such as bars, cafes, restaurants and
          hotels, that are accessible when using a mobility device like a wheelchair.
        </p>

        <p>By continuing to use our App, you agree to comply with these Terms of Use.</p>
      </section>
      <ol>
        <section>
          <li>
            <h3>Services</h3>
          </li>
          <ol className={styles.resetSublistNumbering}>
            <li>
              <p>
                You may need to create an account in order to use our services, or an account may be
                created via third party providers using your pre-existing account details (e.g.
                Facebook, Instagram or Google).
              </p>
            </li>
            <li>
              <p>
                To protect your account, it is important that you keep your password and all other
                account access details confidential and private. You are responsible for the
                activity that happens through your account.
              </p>
            </li>
            <li>
              <p>
                These Terms of Use are effective immediately upon you requesting an account with us,
                or upon an account being allocated to you.
              </p>
            </li>
            <li>
              <p>
                You must use the services provided by Spinal Life and our App in accordance with all
                of our terms, rules and policies, as we may update from time to time, and in
                accordance with all laws.
              </p>
            </li>
          </ol>
        </section>
        <li>
          <h3>Privacy and intellectual property</h3>
        </li>
        <ol className={styles.resetSublistNumbering}>
          <li>
            <p>
              These Terms of Use apply in conjunction with our{' '}
              <Link to={l => addParamToLocation(l, shellUrlParams.SHOW_PRIVACY_POLICY, 'y')}>
                Privacy Policy
              </Link>{' '}
              for our App (available on our website at:{' '}
              <a
                target={'_blank'}
                href={'https://www.spinal.com.au/privacy'}
                rel="noopener noreferrer">
                https://www.spinal.com.au/privacy
              </a>
              ), as updated by us from time to time. You acknowledge and accept that we may collect
              personal information from you in accordance with the terms of our Privacy Policy.
            </p>
          </li>
          <li>
            <p>
              Spinal Life grants to you a personal, royalty-free, non-transferable and non-exclusive
              licence to use the App and related services. This licence is for the sole purpose of
              enabling you to use the App and related services. You may not copy, modify,
              reverse-engineer, distribute, sell or lease any part of our services, the App or
              intellectual property without our prior written consent.
            </p>
          </li>
        </ol>

        <li>
          <h3>Warranties</h3>
        </li>
        <ol className={styles.resetSublistNumbering}>
          <li>
            <p>
              To the maximum extent permitted by law and except as expressly provided to the
              contrary in these Terms of Use, we make no representations or warranties in relation
              to the products or services we provide, including in relation to the App. You accept
              and acknowledge that:
            </p>
          </li>
          <ol className={styles.alphaList}>
            <li>
              our services are provided “as-is” and no condition or warranty in relation to any
              goods or services supplied by us is to be implied in these Terms of Use, including any
              implied warranties of title, merchantability, fitness for a particular purpose and
              non-infringement;{' '}
            </li>
            <li>
              we do not guarantee continuous, uninterrupted or secure access to the App or any part
              of our services or systems; and
            </li>
            <li>
              we make no representations or warranties regarding the accuracy of the information
              available through our App.
            </li>
          </ol>

          <li>
            <p>
              You represent and warrant, and it is a condition of using our products and services,
              that:
            </p>
          </li>

          <ol className={styles.alphaList}>
            <li>
              all information you provide to Spinal Life is accurate and is not, whether by omission
              of information or otherwise, misleading; and
            </li>

            <li>
              you do not rely upon any representation made to you by Spinal Life or any related body
              corporate of Spinal Life (if any) before entry into these Terms of Use.
            </li>
          </ol>

          <li>
            <p>
              Spinal Life relies on the warranties and representations made by you under these Terms
              of Use.
            </p>
          </li>
        </ol>

        <li>
          <h3>Limitation of Liability</h3>
        </li>
        <ol>
          <li>
            <p>
              To the maximum extent permitted by law, in no event will Spinal Life be liable to you
              for any loss or damage of any kind (including Consequential Loss) arising out of or in
              connection with:
            </p>
          </li>

          <ol className={styles.alphaList}>
            <li>your use of, or your inability to use, our services or the App;</li>

            <li>delays or disruptions in our services or the App;</li>

            <li>
              viruses or other malicious software obtained by accessing the App or any website or
              service linked to our App, websites, software or services;
            </li>
            <li>
              glitches, bugs, errors, or inaccuracies of any kind in our software, systems or
              services or in the information and graphics obtained from them;
            </li>
            <li>the content, actions, or inactions of third parties; or</li>
            <li>a suspension or other action taken with respect to your account.</li>
          </ol>

          <li>
            <p>
              To the maximum extent permitted by law and except as expressly provided to the
              contrary in these Terms of Use, any liability of Spinal Life for any loss or damage,
              however caused (including by negligence of Spinal Life), suffered by you in connection
              with these Terms of Use is limited to the greater of $100 or the amount of the fees
              paid by you to Spinal Life under these Terms of Use (if any). This limitation is an
              aggregate limit for all claims, wherever made.
            </p>
          </li>
          <li>
            <p>
              Nothing in these Terms of Use is intended to exclude any liability that is unlawful to
              be excluded and any such term should be read as excluding such liability only to the
              extent permitted by law.
            </p>
          </li>
          <li>
            <p>
              Subject to clause 4.1, Spinal Life is not liable for any Consequential Loss however
              caused (including by the negligence of Spinal Life), suffered or incurred by you in
              connection with these Terms of Use, where Consequential Loss in this clause 4.3 means:
            </p>
          </li>

          <ol className={styles.alphaList}>
            <li>loss of revenue;</li>

            <li>loss of reputation;</li>

            <li>loss of profits;</li>

            <li>loss of bargain;</li>

            <li>loss of actual anticipated savings;</li>

            <li>
              lost opportunities, including opportunities to enter into arrangements with third
              parties;
            </li>

            <li>loss of connection with claims by third parties; and</li>

            <li>loss or corruption of data.</li>
          </ol>

          <li>
            <p>
              If the <span className={styles.italics}>Competition and Consumer Act 2010</span> (Cth)
              or any other legislation does not permit Spinal Life to exclude or limit its liability
              as contemplated by these Terms of Use, those clauses in these Terms of Use do not
              apply to that liability and instead Spinal Life’s liability for such breach is limited
              to, at Spinal Life’s option:
            </p>
          </li>

          <ol className={styles.alphaList}>
            <li>the supplying of the services again; or</li>

            <li>the payment of the reasonable cost of having the services supplied again.</li>
          </ol>

          <li>
            <p>
              If you have a dispute with any other party using a Spinal Life account (including
              third party service providers), you release us from any and all claims, demands and
              damages (actual and consequential) of every kind and nature, known and unknown,
              arising out of or in any way connected with such disputes.
            </p>
          </li>
        </ol>

        <li>
          <h3>Indemnification</h3>
        </li>
        <ol className={styles.resetSublistNumbering}>
          <li>
            <p>
              You indemnify and hold us and each of our officers, directors and employees harmless
              from any claim or loss (including legal fees) arising out of or in connection with:
            </p>
          </li>
          <ol className={styles.alphaList}>
            <li>
              your breach of these Terms of Use or any other agreement between you and Spinal Life;
            </li>

            <li>your breach of any law;</li>

            <li>your breach of any right of a third party;</li>

            <li>an act or omission of a person you authorise to access your account; or</li>

            <li>
              an act or omission of a person not authorised to access your account as a result of
              your negligence.
            </li>
          </ol>

          <li>
            <p>
              Your liability under this clause will be proportionally reduced to the extent that
              Spinal Life caused or contributed to the relevant claim or loss.
            </p>
          </li>
        </ol>

        <li>
          <h3>Termination</h3>
        </li>

        <ol className={styles.resetSublistNumbering}>
          <li>
            <p>You can stop using our services and App at any time without cost.</p>
          </li>

          <li>
            <p>
              We reserve the right to stop providing our services and access to our App to you at
              any time without notice to you.
            </p>
          </li>
        </ol>

        <li>
          <h3>General</h3>
        </li>

        <ol className={styles.resetSublistNumbering}>
          <li>
            <p>
              The laws of Queensland, Australia govern these Terms of Use. Each party irrevocably
              submits to the non-exclusive jurisdiction of the courts of Queensland, Australia and
              courts competent to hear appeals from those courts.
            </p>
          </li>
          <li>
            <p>
              Where Spinal Life may exercise any right or discretion or make any decision under
              these Terms of Use, Spinal Life may do so in its absolute discretion, conditionally or
              unconditionally, and without being required to give reasons or act reasonably. This
              clause applies unless these Terms of Use expressly require otherwise.
            </p>
          </li>
          <li>
            <p>
              Spinal Life may assign its interest under these Terms of Use without requiring the
              Customer’s consent.
            </p>
          </li>
          <li>
            <p>
              A clause or part of a clause of these Terms of Use that is illegal or unenforceable
              may be severed from these Terms of Use and the remaining clauses or parts of the
              clause of these Terms of Use continue in force.
            </p>
          </li>
          <li>
            <p>
              A right under these Terms of Use may only be waived in writing signed by the party
              granting the waiver, and is effective only to the extent specifically set out in the
              waiver.
            </p>
          </li>
          <li>
            <p>
              We may amend these Terms of Use from time to time with notice to you. The revised
              version will be effective at the time we specify, or if no time is specified,
              immediately.
            </p>
          </li>
          <li>
            <p>
              You agree to receive notices or updates from us in relation to the Services
              electronically.
            </p>
          </li>
          <li>
            <p>
              If there is a conflict between these Terms of Use, and any other terms and conditions
              or agreements you may have with Spinal Life, those additional terms, conditions and
              agreements will prevail to the extent of the conflict (unless otherwise stated).
            </p>
          </li>
        </ol>
      </ol>
    </article>
  );
};
