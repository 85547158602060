import { flow, types } from 'mobx-state-tree';
import * as atlas from 'azure-maps-control';
import { getAjax } from 'domain/store/RootStoreModel';
import { IAddressDtoModel } from 'api/models/Domain/Queries/Address/ReverseGeocodeQuery/AddressDtoModel';

interface ILoadingAbortableProcess<TKey> {
  key: TKey;
  abortController: AbortController;
}

export const ReverseGeocodeModel = types
  .model('ReverseGeocodeModel', {
    lat: types.maybeNull(types.number),
    lng: types.maybeNull(types.number),
    isLoading: types.optional(types.boolean, false),
  })
  .extend(self => {
    const localState = {
      currentQuery: null as ILoadingAbortableProcess<{ lat: number; lng: number }> | null,
      address: null as IAddressDtoModel | null,
    };

    function updatePosition(p: atlas.data.Position) {
      self.lng = p[0];
      self.lat = p[1];
    }

    function* loadAddress(lat: number | null = self.lat, lng: number | null = self.lng) {
      if (!lat || !lng) return;

      if (localState.currentQuery) {
        localState.currentQuery.abortController.abort();
      }

      try {
        clearAddress();
        localState.currentQuery = {
          key: {
            lat,
            lng,
          },
          abortController: new AbortController(),
        };
        self.isLoading = true;

        localState.address = yield getAjax(self)
          .get(`api/reverse-geocode?lat=${lat}&lng=${lng}`)
          .json();
      } finally {
        if (localState.currentQuery?.key.lat === lat && localState.currentQuery?.key.lng === lng) {
          self.isLoading = false;
          localState.currentQuery = null;
        }
      }
    }

    function clearAddress() {
      localState.address = null;
    }

    return {
      actions: { updatePosition, loadAddress: flow(loadAddress) },
      views: {
        get address() {
          return localState.address;
        },
      },
    };
  });
