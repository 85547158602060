import React from 'react';
import styles from './PointOfInterest.module.scss';
import cn from 'classnames';
import { useParams, useHistory, Route, useRouteMatch, Link } from 'react-router-dom';
import { useStore, useUrlParam } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import {
  allPointOfInterestCategory,
  pointOfInterestCategoryDescription,
} from 'api/enums/PointOfInterestCategory';
import { Subpage, Subsection } from 'views/components/layout/Subpage';
import { IconList } from 'views/components/layout/IconList';
import { Icon } from 'views/components/Icon';
import { LoadingPane } from 'views/components/LoadingPane';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { getCategoryIconName } from 'views/routes/pois/CategoryIcon';
import { formatAsPhoneNumber } from 'infrastructure/stringFormatUtils';
import { IItem } from 'views/components/layout/IconList';
import { CreateReview } from './createReview/CreateReview';
import { Reviews } from './reviews/Reviews';
import { StarRating } from 'views/components/detail/starRating/StarRating';
import { AaGallery } from 'views/components/aaGallery/AaGallery';
import { IPointOfInterestDtoModel } from 'api/models/Domain/Queries/PointOfInterest/GetPointOfInterestQuery/PointOfInterestDtoModel';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { UpdateReview } from './UpdateReview/UpdateReview';
import { ReportPoi } from './ReportPoi';
import { Drawer } from 'views/components/layout/drawer/Drawer';
import { addParamToLocation } from 'infrastructure/locationUtils';
import { urlParams as shellUrlParams } from 'views/components/application/Shell';

interface IPointOfInterestDetailProps {
  item: IPointOfInterestDtoModel;
}

const PointOfInterestDetail: React.FC<IPointOfInterestDetailProps> = observer(
  function PointOfInterestDetail({ item }) {
    const store = useStore();
    const [, , unsetFullDetails] = useUrlParam(urlParamNames.search.FULL_DETAIL);
    const config = store.configuration;
    const isSmallViewport = store.environment.isSmallViewport;
    const security = store.security;
    const isViewingCreateReviewPage = !!useRouteMatch({
      path: routePaths.createReview.template,
      strict: true,
      sensitive: true,
    });

    const isViewingUpdateReviewPage = !!useRouteMatch({
      path: routePaths.updateReview.template,
      strict: true,
      sensitive: true,
    });

    const history = useHistory();

    const goBack = (
      <BackButton
        onClick={() => {
          isSmallViewport
            ? unsetFullDetails()
            : history.push(routePaths.pois.toKeepingExistingParams()(history.location));
        }}
      />
    );

    const allCategories =
      pointOfInterestCategoryDescription(item.primaryCategory) +
      allPointOfInterestCategory.reduce((currentValue, category) => {
        if ((category.value & item.alternateCategories) !== 0) {
          return currentValue + `, ${category.description}`;
        }
        return currentValue;
      }, '');

    const getAccessibilityRatingSelfReportedOrReviewCount = () => {
      if (item.averageAccessibilityRating) {
        return item.reviewCount
          ? `${item.reviewCount} ${item.reviewCount > 1 ? 'reviews' : 'review'}`
          : 'self-reported';
      }
      return undefined;
    };

    const imagePlaceholder = () => {
      return (
        <Link
          className={styles.imagePlaceholder}
          to={
            security.isLoggedIn
              ? routePaths.createReview.toKeepingExistingParams(item.id)
              : l => addParamToLocation(l, shellUrlParams.SHOW_PROFILE, 'y')
          }>
          <Icon name="add-photo" className={styles.addPhotoIcon} />
          <span>{security.isLoggedIn ? 'Add photos in a review' : 'Sign in to add photos'}</span>
        </Link>
      );
    };

    return (
      <Subpage
        title={item.name}
        leftAction={goBack}
        firstTagToFocus={!isViewingCreateReviewPage ? 'button' : undefined}>
        {item.images.length > 0 ? (
          <AaGallery
            className={styles.gallery}
            items={item.images}
            hideNav={isViewingCreateReviewPage || isViewingUpdateReviewPage}
          />
        ) : (
          imagePlaceholder()
        )}
        <Subsection className={styles.actions}>
          {security.isLoggedIn && (
            <button
              title={'Add a review'}
              aria-label={'Add a review'}
              onClick={() => {
                history.push(
                  routePaths.createReview.toKeepingExistingParams(item.id)(history.location)
                );
              }}
              className={styles.actionButton}>
              <Icon className={styles.icon} name={'review'} />
              <span>Review</span>
            </button>
          )}
          <button
            className={styles.actionButton}
            aria-label="Search nearby"
            title="Search nearby"
            onClick={() => {
              history.push(
                routePaths.pois.toKeepingExistingParams({
                  SEARCH: '',
                  SEARCH_COORDINATES: [item.location.latitude, item.location.longitude],
                  CATEGORY: undefined,
                  SHOW_LIST: undefined,
                })(history.location)
              );
            }}>
            <Icon className={styles.icon} name="nearby" />
            <span>Nearby</span>
          </button>
          <button className={styles.actionButton}
            aria-label="Share to socials"
            title="Share to socials"
            onClick={() => {
              history.push(
                routePaths.poi.toKeepingExistingParams(item.id, {
                  SEARCH: undefined,
                  SEARCH_COORDINATES: [item.location.latitude, item.location.longitude],
                  CATEGORY: undefined,
                  SHOW_LIST: undefined,
                  SHOW_SHARE: true
                })(history.location)
              );
            }}>
            <Icon className={styles.icon} name="share-icon" />
            <span>Share</span>
          </button>
        </Subsection>
        <Subsection>
          <IconList
            items={[
              {
                label: 'Category',
                value: allCategories,
                iconProps: { name: getCategoryIconName(item.primaryCategory) },
              },
              {
                label: 'Address',
                value: item.fullAddress,
                iconProps: { name: 'map-marker' },
              },
              {
                label: 'Accessibility Rating',
                value: (
                  <StarRating
                    rating={item.averageAccessibilityRating}
                    titlePrefix="Accessibility"
                  />
                ),
                valueSuffix: getAccessibilityRatingSelfReportedOrReviewCount(),
                iconProps: { name: 'wheelchair' },
              },
              {
                label: 'Service Rating',
                value: <StarRating rating={item.averageServiceRating} titlePrefix="Service" />,
                iconProps: { name: 'service' },
              },
              {
                label: 'Phone Number',
                value: item.phoneNumber && formatAsPhoneNumber(item.phoneNumber),
                iconProps: { name: 'phone' },
                linkTo: `tel:${item.phoneNumber}`,
                externalLink: true,
              },
              {
                label: 'Email Address',
                value: item.emailAddress,
                iconProps: { name: 'envelope' },
                linkTo: `mailto:${item.emailAddress}`,
                externalLink: true,
              },
              {
                label: 'Website',
                value: item.websiteUrl && new URL(item.websiteUrl).hostname,
                iconProps: { name: 'web-design' },
                linkTo: item.websiteUrl,
                externalLink: true,
              },
              ...item.attributes.map((a: string) => {
                return {
                  label: a,
                  value: a,
                  iconProps: { name: 'check' },
                } as IItem;
              }),
            ]}
          />
          {item.webBeaconUrl && (
            <img
              src={config.enableWebBeacons ? item.webBeaconUrl : ''}
              alt=""
              aria-hidden="true"
              className="visually-hidden"
              data-testid="beacon"
            />
          )}
          <ReportPoi poiId={item.id} isReportedByCurrentUser={item.isReportedByCurrentUser} />
          {item.sourcePoiUrl && (
            <a
              className={styles.attribution}
              href={item.sourcePoiUrl}
              rel="noopener noreferrer"
              target="_blank">
              <span>A friend shared this data</span>
              <Icon name="external-link-alt" />
            </a>
          )}
        </Subsection>
        <Reviews poiID={item.id} />
      </Subpage>
    );
  }
);

interface IPointOfInterestProps {
  className?: string;
}

export const PointOfInterest: React.FC<IPointOfInterestProps> = observer(function PointOfInterest({
  className,
}) {
  const { id } = useParams();
  const store = useStore();
  const security = store.security;
  const poisRepo = store.pointsOfInterestRepo;
  const item =
    poisRepo.pointOfInterestDetails?.id === id ? poisRepo.pointOfInterestDetails : undefined;

  const getContent = () => {
    return (
      <LoadingPane isLoading={poisRepo.isLoadingPointOfInterestDetails}>
        {item && <PointOfInterestDetail item={item} />}
      </LoadingPane>
    );
  };

  const isViewingCreateReviewPage = !!useRouteMatch({
    path: routePaths.createReview.template,
    strict: true,
    sensitive: true,
  });

  const isViewingUpdateReviewPage = !!useRouteMatch({
    path: routePaths.updateReview.template,
    strict: true,
    sensitive: true,
  });

  return (
    <section
      className={cn(styles.root, className, {
        [styles.reviewPanelOpen]:
          security.isLoggedIn && (isViewingCreateReviewPage || isViewingUpdateReviewPage),
      })}>
      {getContent()}
      {security.isLoggedIn && (
        <>
          <Route path={routePaths.createReview.template}>
            {() => (
              <Drawer
                isActive={isViewingCreateReviewPage}
                transition="slide-in-left"
                className={cn(styles.reviewPanels)}>
                <CreateReview />
              </Drawer>
            )}
          </Route>
          <Route path={routePaths.updateReview.template}>
            {() => (
              <Drawer
                isActive={isViewingUpdateReviewPage}
                transition="slide-in-left"
                className={cn(styles.reviewPanels)}>
                <UpdateReview />
              </Drawer>
            )}
          </Route>
        </>
      )}
    </section>
  );
});
