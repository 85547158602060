import React from 'react';
import styles from './App.module.scss';
import { History } from 'history';
import { Routes } from 'views/routes/Routes';
import { Router } from 'react-router-dom';
import { IRootStoreModel } from 'domain/store/RootStoreModel';
import { Notifications } from 'views/components/application/notifications/Notifications';
import * as themes from './themes';

export const StoreContext = React.createContext<IRootStoreModel | undefined>(undefined);
StoreContext.displayName = 'StoreContext';

interface IAppProps {
  history: History;
  store: IRootStoreModel;
}

export const App: React.FC<IAppProps> = function ({ history, store }) {
  React.useEffect(() => {
    themes.applyToDocumentBody(themes.siteDefault);
  }, []);

  return (
    <div className={styles.app}>
      <StoreContext.Provider value={store}>
        <Router history={history}>
          <Routes />
        </Router>
        <Notifications />
      </StoreContext.Provider>
    </div>
  );
};
