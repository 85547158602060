import React from 'react';
import { StoreContext } from './App';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getParamFromLocation,
  setParamInLocation,
  removeParamFromLocation,
} from 'infrastructure/locationUtils';

export function useStore() {
  const store = React.useContext(StoreContext);
  if (!store) throw new Error('Store context has not been initialised');
  return store;
}

export function useUrlParam(name: string): [string | null, (value: string) => void, () => void] {
  const history = useHistory();

  const getValue = React.useCallback(() => getParamFromLocation(history.location, name), [
    history,
    name,
  ]);

  const setParam = React.useCallback(
    (value: string) => {
      const currentValue = getValue();
      if (value !== currentValue) {
        history.push(setParamInLocation(history.location, name, value));
      }
    },
    [getValue, history, name]
  );

  const unsetParam = React.useCallback(() => {
    const currentValue = getValue();
    if (currentValue !== null) {
      history.push(removeParamFromLocation(history.location, name));
    }
  }, [getValue, history, name]);

  return [getValue(), setParam, unsetParam];
}

export function useFullUrlWithoutParam(name: string) {
  const baseUrl = window.location.origin;
  let location = useLocation();

  location = removeParamFromLocation(location, name);

  return baseUrl + location.pathname + '?' + location.search + location.hash
}
