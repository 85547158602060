import cn from 'classnames';
import React from 'react';
import { FieldError, ValidationOptions } from 'react-hook-form';
import styles from './Input.module.scss';

interface IInputProps {
  inputErrors: FieldError | undefined;
  name: string;
  register: (Ref: HTMLInputElement, validateRule?: ValidationOptions) => void;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  label: string;
  highlightOnFocus?: boolean;
  type?: 'text' | 'email' | 'tel' | 'url';
  onBlur?: () => void;
  required?: boolean;
  autoComplete?: boolean;
}

export const Input: React.FC<IInputProps> = function ({
  register,
  inputErrors,
  name,
  placeholder,
  defaultValue,
  label,
  highlightOnFocus,
  type = 'text',
  onBlur,
  required,
  autoComplete = true,
  disabled,
}) {
  const onFocus = highlightOnFocus
    ? (e: React.FocusEvent<HTMLInputElement>) => e.target.select()
    : () => {};

  return (
    <div className={styles.root}>
      <label htmlFor={name} className={cn({ [styles.error]: inputErrors })}>
        {label}
      </label>
      {required && (
        <span className={cn(styles.required, { [styles.error]: inputErrors })}>Required</span>
      )}
      <input
        ref={register}
        name={name}
        id={name}
        placeholder={placeholder}
        className={cn({ [styles.error]: inputErrors, [styles.disabled]: disabled })}
        defaultValue={defaultValue}
        onBlur={onBlur}
        type={type}
        onFocus={onFocus}
        autoComplete={autoComplete ? 'on' : 'off'}
        disabled={disabled}
      />
      {inputErrors && <span className={styles.errorMessage}>{inputErrors.message}</span>}
    </div>
  );
};
