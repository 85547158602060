import { pluraliseCategory } from 'domain/store/repos/CategoriesRepo';
import { History } from 'history';
import { types } from 'mobx-state-tree';

const poiSelectedPattern = /\/pois\/[a-zA-Z0-9-]+/;

// Items in this map should appear in the order they render. E.g. privacy policy
// renders on top of terms of use, which renders on top of profile.
const urlTitleMap: Array<[string, string]> = [
  ['showPrivacyPolicy', 'Privacy Policy'],
  ['showTermsOfUse', 'Terms of Use'],
  ['showUpdateProfile', 'Update Profile'],
  ['showFeedback', 'Send Us Feedback'],
  ['showProfile', 'Welcome'],
  ['showNewPoiForm', 'Add a New Place'],
  ['showDeleteFacebookData', 'Delete Facebook Data'],
];

export const PageTitleModel = types.model('TitleModel', {}).actions(self => {
  const internalState = {
    selectedPoiName: '',
  };

  function init(history: History) {
    update(history.location.search, history.location.pathname);
    history.listen(e => {
      update(e.search, e.pathname);
    });
  }

  function update(searchString: string, pathName: string) {
    const urlParams = new URLSearchParams(searchString);

    // if the right panel is open
    for (const mapping of urlTitleMap) {
      if (urlParams.get(mapping[0])) {
        setTitle(mapping[1]);
        return;
      }
    }

    // there is a selected point of interest
    if (pathName.match(poiSelectedPattern) && internalState.selectedPoiName) {
      setTitle(
        pathName.includes('createReview')
          ? `Add Review - ${internalState.selectedPoiName}`
          : `${internalState.selectedPoiName}`
      );
      return;
    }

    // Search term and/or category are set
    const searchTerm = urlParams.get('search');
    const category = urlParams.get('category');
    if (searchTerm || category) {
      setTitle(getTitleFromSearchParams(searchTerm, category));
      return;
    }

    setTitle('Accessible Australia', false);
  }

  function getTitleFromSearchParams(searchTerm: string | null, category: string | null): string {
    let title = searchTerm !== null && searchTerm.trim().length > 0 ? `${searchTerm} ` : '';

    if (category) {
      title += title.length > 0 ? ' - ' : '';
      title += `${pluraliseCategory(category)}`;
    }
    return title;
  }

  function setTitle(title: string, appendSuffix = true) {
    const titleSuffix = ` | Accessible Australia`;
    document.title = title + (appendSuffix ? titleSuffix : '');
  }

  function setSelectedPoi(poiName: string) {
    internalState.selectedPoiName = poiName;
    setTitle(poiName);
  }

  return {
    init,
    setSelectedPoi,
  };
});
