import React from 'react';
import styles from './UpdateUserProfile.module.scss';
import { allMobilityAid, MobilityAid } from 'api/enums/MobilityAid';
import { useStore, useUrlParam } from 'views/hooks';
import { useForm } from 'react-hook-form';
import { IUpdateUserProfileCommandModel } from 'api/models/Domain/Aggregates/User/Commands/UpdateUserProfileCommandModel';
import { Input } from 'views/components/forms/input/Input';
import { Select } from 'views/components/forms/select/Select';
import { urlParams } from 'views/components/application/Shell';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { removeParamFromLocation } from 'infrastructure/locationUtils';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { CheckBox } from 'views/components/forms/checkbox/Checkbox';

interface IUpdateUserProfileProps {
  className?: string;
}

export const UpdateUserProfile: React.FC<IUpdateUserProfileProps> = observer(function ({
  className,
}) {
  const store = useStore();
  const history = useHistory();
  const security = store.security;
  const [, , unsetShowUpdateProfile] = useUrlParam(urlParams.SHOW_UPDATE_PROFILE);

  const { register, handleSubmit, errors, setValue, reset, watch, control } = useForm<
    IUpdateUserProfileCommandModel
  >({
    defaultValues: {
      displayName: security.userProfile?.displayName,
      mobilityAid: security.userProfile?.mobilityAid,
      emailAddress: security.userProfile?.emailAddress,
      emailConsent: security.userProfile?.emailConsent
    },
  });

  React.useEffect(() => {
    if (security.userProfile?.displayName !== undefined)
      setValue('displayName', security.userProfile?.displayName);
      setValue('mobilityAid', security.userProfile?.mobilityAid || MobilityAid.None);
      setValue('emailAddress', security.userProfile?.emailAddress);
      setValue('emailConsent', security.userProfile?.emailConsent);
  }, [reset, setValue, security]);

  const onSubmit = async (data: IUpdateUserProfileCommandModel) => {
    await security.updateUserProfile(data);
    await security.getUserProfile();
  };

  const dismissPanelButton = (
    <BackButton
      onClick={() => {
        history.push(removeParamFromLocation(history.location, urlParams.SHOW_UPDATE_PROFILE));
      }}
      className={styles.goBack}
    />
  );

  const emailConsent = watch('emailConsent');
  const emailProvided = !!watch('emailAddress');

  return (
    <SubpageForm
      title="Profile"
      titleLeftAligned
      className={className}
      leftAction={dismissPanelButton}
      onSubmit={handleSubmit(onSubmit)}
      afterSubmit={unsetShowUpdateProfile}
      control={control}
      submitText="Save Changes">
      <Input
        inputErrors={errors.displayName}
        name="displayName"
        register={register({
          required: 'Please enter a display name',
          maxLength: { value: 100, message: 'A display name cannot be more than 100 characters' },
          pattern: { value: /\S+/, message: 'Display name cannot be empty' },
        })}
        label="Name"
        defaultValue={security.userProfile && security.userProfile.displayName}
        placeholder="Please enter a display name"
        highlightOnFocus
        autoComplete={false}
      />
      <Select
        name="mobilityAid"
        register={register({
          required: 'Please select a mobility aid',
        })}
        selections={allMobilityAid.map(d => {
          return {
            value: String(d.value),
            description: d.description,
          };
        })}
        selectErrors={errors.mobilityAid}
        label="Mobility Aid"
      />
      <CheckBox
        register={register}
        name="emailConsent"
        label="Yes"
        title="Would you like to be kept up to date with improvements and events?"
        checked={security.userProfile?.emailConsent}
      />
      {emailConsent && (
        <>
          <p>Please provide your email address below</p>
          <Input
            inputErrors={errors.emailAddress}
            name="emailAddress"
            register={register({
              validate: () =>
                emailProvided || 'Please provide an email address',
            })}
            label="Email"
            placeholder="Enter your email"
            type="email"
            defaultValue={security.userProfile && security.userProfile.emailAddress}
          />
        </>
      )}
    </SubpageForm>
  );
});
