import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.scss';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import 'typeface-m-plus-rounded-1c';
import { createBrowserHistory } from 'history';
import { isDevelopment } from 'appSettings';
import { getDefaultStore } from 'domain/store/RootStoreModel';
import { AppLoadFailed } from 'views/components/application/AppLoadFailed';
import { App } from 'views/App';
import * as atlas from 'azure-maps-control';

async function init() {
  const history = createBrowserHistory();
  const store = getDefaultStore(history);

  if (isDevelopment) {
    const { enable } = await import('devtools');
    enable(store);
  }

  // If an unsupported browser, the container will not exist
  const container = document.getElementById('root');
  if (!container) return;
  if (!atlas.isSupported()) {
    // @ts-ignore - required as getMessage() is implicitly global from browserSupportCheck.js
    container.innerHTML = getMessage();
    return;
  }

  ReactDOM.render(<App history={history} store={store} />, container);

  await store.init();
  store.startGeolocationPolling(5000);
}

init().catch(e => {
  console.error('App initialization failed', e);
  ReactDOM.render(<AppLoadFailed />, document.getElementById('root'));
});
