import React from 'react';

export const NotFound: React.FC = () => {
  return (
    <>
      <h1>Not Found</h1>
      <p>The page you were looking for cannot be found.</p>
    </>
  );
};
