import React, { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { useForm } from 'react-hook-form';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { useStore, useUrlParam } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import { allMobilityAid, MobilityAid } from 'api/enums/MobilityAid';
import { Select } from 'views/components/forms/select/Select';
import { TextArea } from 'views/components/forms/textArea/TextArea';
import { StarRatingInput } from 'views/components/forms/starRatingInput/StarRatingInput';
import { MapContext } from 'views/routes/pois/Search';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import {
  ImageFilePicker,
  ImageFile,
  ImageUrl,
} from 'views/components/forms/imageFilePicker/ImageFilePicker';
import { IReviewImageUrlsDtoModel } from 'api/models/Domain/Queries/SharedDtos/Reviews/ReviewImageUrlsDtoModel';

interface IUpdateReviewProps {
  className?: string;
}

interface IFormData {
  reviewText: string;
  mobilityAidUsed: MobilityAid;
  accessibilityRating: string;
  serviceRating: string;
  photos: Array<ImageFile | ImageUrl>;
}

export const UpdateReview: React.FC<IUpdateReviewProps> = observer(function CreateReview({
  className,
}) {
  const { id, reviewId } = useParams();

  const store = useStore();
  const reviewsRepo = store.reviewsRepo;
  const pointsOfInterestRepo = store.pointsOfInterestRepo;
  const history = useHistory();
  const [mobilityAidFilter] = useUrlParam(urlParamNames.review.MOBILITY_AID);
  const mapContext = useContext(MapContext);

  const reviewData = reviewsRepo.reviews.find(r => r.id === reviewId);

  let reviewDataChanged = false;

  const { register, handleSubmit, errors, setValue, watch, getValues, control } = useForm<
    IFormData
  >({
    defaultValues: {
      reviewText: reviewData?.reviewText,
      mobilityAidUsed: reviewData?.mobilityAidUsed,
      accessibilityRating: reviewData?.accessibilityRating.toString(),
      serviceRating: reviewData?.serviceRating ? reviewData?.serviceRating.toString() : undefined,
      photos: [],
    },
  });

  useEffect(() => {
    if (id !== undefined && !reviewData?.editable) {
      history.push(routePaths.poi.toKeepingExistingParams(id)(history.location));
    }
  }, [history, id, reviewData]);

  const goBack = (
    <BackButton
      onClick={() => {
        history.push(routePaths.poi.toKeepingExistingParams(id || '')(history.location));
      }}
    />
  );

  // Watching the specified form elements for changes
  const selectedAccessibilityRating = watch('accessibilityRating');
  const selectedServiceRating = watch('serviceRating');

  async function onSubmit(data: IFormData) {
    if (id === undefined || !reviewData) return;

    const photosToAdd = data.photos.filter(p => p.isFile && !p.isDeleted).map(p => p as File);
    const photosToRemove = data.photos
      .filter(p => !p.isFile && p.isDeleted)
      .map(p => p as IReviewImageUrlsDtoModel);

    if (
      reviewData.reviewText === data.reviewText &&
      reviewData.mobilityAidUsed.toString() === data.mobilityAidUsed.toString() &&
      reviewData.accessibilityRating.toString() === data.accessibilityRating &&
      (reviewData.serviceRating !== undefined
        ? reviewData.serviceRating.toString() === data.serviceRating
        : data.serviceRating === '') &&
      !photosToAdd.length &&
      !photosToRemove.length
    ) {
      return;
    }

    reviewDataChanged = true;
    await reviewsRepo.updateReview(
      reviewData.id,
      data.reviewText,
      Number(data.mobilityAidUsed),
      data.accessibilityRating,
      data.serviceRating,
      photosToAdd,
      photosToRemove
    );
  }

  async function afterSubmit() {
    if (id === undefined) return;
    if (reviewDataChanged) {
      reviewsRepo.loadReviews(id, 1, mobilityAidFilter ? Number(mobilityAidFilter) : undefined);
      await pointsOfInterestRepo.load(id);
      mapContext.forceReloadSingleMapMarker && mapContext.forceReloadSingleMapMarker(id);
    }
    history.push(routePaths.poi.toKeepingExistingParams(id)(history.location));
  }

  return (
    <SubpageForm
      className={className}
      control={control}
      leftAction={goBack}
      title="Edit Review"
      titleLeftAligned
      onSubmit={handleSubmit(onSubmit)}
      afterSubmit={afterSubmit}
      encType="multipart/form-data"
      submitText="Update Review"
      firstTagToFocus="button">
      <StarRatingInput
        register={register({
          required: 'Please rate accessibility',
        })}
        name="accessibilityRating"
        inputErrors={errors.accessibilityRating}
        required={true}
        label="Accessibility"
        getCurrentValue={() => getValues().accessibilityRating}
        resetCurrentValue={() => setValue('accessibilityRating', undefined)}
        selected={selectedAccessibilityRating}
      />
      <StarRatingInput
        register={register()}
        name="serviceRating"
        inputErrors={errors.serviceRating}
        required={false}
        label="Service"
        getCurrentValue={() => getValues().serviceRating}
        resetCurrentValue={() => setValue('serviceRating', undefined)}
        selected={selectedServiceRating}
      />
      <Select
        selectErrors={errors.mobilityAidUsed}
        name="mobilityAidUsed"
        required={true}
        register={register({
          required: 'Please select a mobility aid',
        })}
        selections={[
          { value: '', description: 'Select one...' },
          ...allMobilityAid.map(d => {
            return {
              value: String(d.value),
              description: d.description,
            };
          }),
        ]}
        label="Mobility Aid Used"
      />
      <TextArea
        textAreaErrors={errors.reviewText}
        name="reviewText"
        register={register({
          required: { value: true, message: 'Review text is required' },
          pattern: { value: /\S+/, message: 'Review text cannot be empty' },
        })}
        label="Review"
        maxLength={2500}
        placeholder="Enter your review here. Remember to highlight accessibility features!"
        rows={4}
        required={true}
      />
      <ImageFilePicker
        name="photos"
        maxImages={15}
        label="Add photos"
        register={register}
        setValue={setValue}
        watch={watch}
        existingImages={reviewData?.reviewImageUrls}
        errors={errors}
      />
    </SubpageForm>
  );
});
