import React from 'react';
import styles from './Navigation.module.scss';
import cn from 'classnames';
import { Link, LinkProps, useParams, useHistory } from 'react-router-dom';
import { MapContext } from 'views/routes/pois/Search';
import { Icon, IIconProps } from 'views/components/Icon';
import { addParamToLocation, removeParamFromLocation } from 'infrastructure/locationUtils';
import { urlParams as shellUrlParams } from 'views/components/application/Shell';
import { useStore, useUrlParam } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import { PoiResultItem } from 'views/routes/pois/searchPanel/poisPanel/PoiResultItem';
import { urlParamNames, routePaths } from 'views/routes/routePaths';
import { ISearchDto } from 'domain/store/repos/PointsOfInterestRepo';
import { IconButton } from 'views/components/actions/IconButton';
import { MapActionContextMenu } from './MapActionContextMenu';

interface ISearchActiveActionProps {
  label: string;
  to: LinkProps['to'];
  iconName: IIconProps['name'];
  tabIndex?: number;
}

const SearchActiveAction: React.FC<ISearchActiveActionProps> = function ({
  label,
  to,
  iconName,
  tabIndex,
}) {
  return (
    <Link className={styles.searchActiveAction} aria-label={label} to={to} tabIndex={tabIndex}>
      <span>{label}</span>
      <Icon name={iconName} />
    </Link>
  );
};

interface IZoomButtonsProps {
  className?: string;
  zoom?: (amount: number) => void;
  hidden: boolean;
  smallScreen?: boolean;
}

const ZoomButtons: React.FC<IZoomButtonsProps> = function ({
  className,
  zoom,
  hidden,
  smallScreen,
}) {
  return (
    <ol
      className={cn(className, styles.zoom, {
        [styles.hidden]: hidden,
        [styles.smallScreen]: smallScreen,
      })}>
      <li className={styles.zoomButton}>
        <button className={styles.zoomIn} onClick={() => zoom && zoom(1)}>
          <Icon name="plus" />
        </button>
      </li>
      <li className={styles.zoomButton}>
        <button className={styles.zoomOut} onClick={() => zoom && zoom(-1)}>
          <Icon name="minus" />
        </button>
      </li>
    </ol>
  );
};

interface INavigationProps {
  className?: string;
}

export const Navigation: React.FC<INavigationProps> = observer(function Navigation({ className }) {
  const store = useStore();
  const history = useHistory();
  const environment = store.environment;
  const poisRepo = store.pointsOfInterestRepo;
  const search = store.search;
  const { centreMapOnUser, zoom } = React.useContext(MapContext);
  const [showList] = useUrlParam(urlParamNames.search.SHOW_LIST);
  const [showMapActionContextMenu, setShowMapActionContextMenu] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU
  );
  const [showNewPoiForm, setShowNewPoiForm] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_NEW_POI_FORM
  );
  const searchContext = React.useContext(MapContext);

  const searchSet =
    search.state === 'loading' || search.state === 'some-found' || search.state === 'none-found';

  const isSmallViewport = environment.isSmallViewport;

  const { id } = useParams();
  const item = poisRepo.searchResults.find(i => i.id === id) as ISearchDto;

  const showPoiShortDetail = isSmallViewport && item && !showList;
  const showSearchResultsToolbar =
    !showPoiShortDetail && searchSet && !item && !showMapActionContextMenu;
  const showNewPoiToolbar = !showMapActionContextMenu || showNewPoiForm;
  const showMainToolbar =
    !showSearchResultsToolbar && !searchSet && !showList && !item && !showMapActionContextMenu;

  const [resettingLocation, setResettingLocation] = React.useState(false);

  return (
    <nav className={cn(className, styles.root)}>
      <ol
        className={cn(styles.searchActiveToolbar, {
          [styles.hidden]: !showSearchResultsToolbar,
          [styles.showingMap]: !showList,
          [styles.mapHasMoved]: searchContext.mapHasMoved,
        })}>
        <li
          className={cn(styles.showFilter, {
            [styles.pinchZoomDisabled]: !showList && !environment.isViewportZoomed,
          })}>
          <SearchActiveAction
            label="Filter"
            iconName="sliders-h"
            to={l => addParamToLocation(l, urlParamNames.search.SHOW_FILTER, 'y')}
            tabIndex={showSearchResultsToolbar ? 3 : -1}
          />
        </li>
        <li
          className={cn(styles.list, {
            [styles.pinchZoomDisabled]: !environment.isViewportZoomed,
          })}>
          <SearchActiveAction
            label="List"
            iconName="list"
            to={l => addParamToLocation(l, urlParamNames.search.SHOW_LIST, 'y')}
          />
        </li>
        <li className={cn(styles.map)}>
          <SearchActiveAction
            label="Map"
            iconName="map-marked"
            to={l => removeParamFromLocation(l, urlParamNames.search.SHOW_LIST)}
          />
        </li>
      </ol>
      <ol
        className={cn(styles.toolbar, {
          [styles.hidden]: !showMainToolbar,
          [styles.pinchZoomDisabled]: !environment.isViewportZoomed,
        })}>
        <li className={cn(styles.profile)}>
          <Link
            className={styles.action}
            title="Profile"
            aria-label="Profile"
            to={l => addParamToLocation(l, shellUrlParams.SHOW_PROFILE, 'y')}>
            <Icon name="user" />
          </Link>
        </li>
        <li className={cn(styles.showUser)}>
          <button
            className={styles.action}
            title="Reset and move to current location"
            aria-label="Reset and move to current location"
            onClick={async () => {
              setResettingLocation(true);
              centreMapOnUser && (await centreMapOnUser());
              setResettingLocation(false);
            }}>
            <Icon
              name={resettingLocation ? 'loading' : 'map-marker'}
              className={cn({ [styles.resettingLocation]: resettingLocation })}
            />
          </button>
        </li>
        <li className={styles.mapActionsContextMenuButton}>
          <IconButton
            title={'Map actions'}
            icon={{ name: 'angle-up' }}
            className={cn(styles.action, {
              [styles.rotate]: showMapActionContextMenu && !showNewPoiForm,
            })}
            onClick={() => {
              setShowMapActionContextMenu(!!showNewPoiForm || !showMapActionContextMenu ? 'y' : '');
              setShowNewPoiForm(showNewPoiForm ? (!showMapActionContextMenu ? 'y' : '') : '');
              history.push(routePaths.pois.toKeepingExistingParams()(history.location));
            }}
          />
        </li>
        {!isSmallViewport && (
          <>
            <li
              className={cn(styles.largeMapActionContextMenu, {
                [styles.hidden]: showNewPoiToolbar,
              })}>
              <MapActionContextMenu />
            </li>
            <ZoomButtons
              zoom={zoom}
              hidden={!!(showMapActionContextMenu || showPoiShortDetail || showList)}
            />
          </>
        )}
      </ol>
      {isSmallViewport && (
        <section
          className={cn(styles.poiShortDetail, {
            [styles.hidden]: !showPoiShortDetail,
          })}>
          <h2 className={'visually-hidden'}>Selected Place</h2>
          {item && (
            <PoiResultItem
              className={cn(styles.poiShortDetailContent, {
                [styles.pinchZoomDisabled]: !environment.isViewportZoomed,
              })}
              item={item}
              showFullDetail
              disableZoom
            />
          )}
        </section>
      )}
      {isSmallViewport && (
        <>
          <MapActionContextMenu
            className={cn(styles.smallMapActionContextMenu, {
              [styles.hidden]: showNewPoiToolbar,
              [styles.pinchZoomDisabled]: !environment.isViewportZoomed,
            })}
          />
          <ZoomButtons
            zoom={zoom}
            className={cn({ [styles.pinchZoomDisabled]: !environment.isViewportZoomed })}
            hidden={!!(showMapActionContextMenu || showPoiShortDetail || showList)}
            smallScreen
          />
        </>
      )}
    </nav>
  );
});
