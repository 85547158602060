import React, { useRef } from 'react';
import 'azure-maps-control/dist/atlas.css';
import styles from './AaMap.module.scss';
import { IPointOfInterestDtoModel } from 'api/models/Domain/Queries/PointOfInterest/SearchPointsOfInterestQuery/PointOfInterestDtoModel';
import { observer } from 'mobx-react-lite';
import {
  useAzureMap,
  useSearchCoordinates,
  usePointsOfInterest,
  useSelectedPoiFromUrl,
  useShowMapActionContextMenuParam,
} from './aaMapHooks';
import atlas from 'azure-maps-control';

interface IAaMapProps {
  apiKey: string;
}

export const AaMap: React.FC<IAaMapProps> = observer(function AaMap({ apiKey }) {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const selectedPoi = useRef<IPointOfInterestDtoModel | undefined>(undefined);
  const searchPosition = useRef<atlas.data.Position | undefined>(undefined);

  const map = useAzureMap(mapContainer, apiKey, selectedPoi, searchPosition);
  usePointsOfInterest(map.current);
  useSearchCoordinates(searchPosition);
  useSelectedPoiFromUrl(map.current, selectedPoi);
  useShowMapActionContextMenuParam(map.current);

  return <div ref={mapContainer} className={styles.mapContainer} />;
});
