import React, { useRef, useState } from 'react';
import cn from 'classnames';
import styles from './Drawer.module.scss';
import { getMillisecondsFromCssTime } from 'infrastructure/cssUtils';
import { siteDefault } from 'views/themes';

interface IDrawerProps {
  children: React.ReactNode;
  className?: string;
  isActive: boolean;
  transition: 'slide-in-left' | 'slide-in-right';
}

export const Drawer: React.FC<IDrawerProps> = function ({
  children,
  className,
  isActive,
  transition,
}) {
  const [renderChildren, setRenderChildren] = useState(false);
  const activeRef = useRef(isActive);

  React.useEffect(() => {
    activeRef.current = isActive;
    if (!isActive) {
      setTimeout(() => {
        !activeRef.current && setRenderChildren(false);
      }, getMillisecondsFromCssTime(siteDefault['--reveal-page-transition-time']));
    } else {
      setRenderChildren(true);
    }
  }, [isActive]);

  return (
    <div
      className={cn(styles.root, className, {
        [styles.hidden]: !isActive,
        [styles.slideInRight]: transition === 'slide-in-right',
        [styles.slideInLeft]: transition === 'slide-in-left',
      })}>
      {renderChildren && children}
    </div>
  );
};
