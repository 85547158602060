import React, { useContext, useEffect } from 'react';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { useStore, useUrlParam } from 'views/hooks';
import { IAddUserGeneratedPoiCommandModel } from 'api/models/Domain/Aggregates/PointOfInterest/Commands/AddUserGeneratedPoiCommandModel';
import {
  allPointOfInterestCategory,
  PointOfInterestCategory,
} from 'api/enums/PointOfInterestCategory';
import { useForm } from 'react-hook-form';
import { urlParamNames, routePaths } from 'views/routes/routePaths';
import { Input } from 'views/components/forms/input/Input';
import { Select } from 'views/components/forms/select/Select';
import { MapContext } from '../Search';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { Autocomplete } from './Autocomplete'
import { IAddressDtoModel } from 'api/models/Domain/Queries/Address/FuzzyAddressSearchQuery/AddressDtoModel';
import styles from './NewPointOfInterestForm.module.scss';

interface IFormData {
  name: string;
  primaryCategory: PointOfInterestCategory;
  secondaryCategory?: PointOfInterestCategory;
  tertiaryCategory?: PointOfInterestCategory;
  street: string;
  city: string;
  state: string;
  postcode?: string;
  phoneNumber?: string;
  emailAddress?: string;
  websiteUrl?: string;
}

export const NewPointOfInterestForm: React.FC = observer(function () {
  const store = useStore();
  const pointsOfInterestRepo = store.pointsOfInterestRepo;
  const reverseGeocode = store.reverseGeocode;
  const history = useHistory();

  const mapContext = useContext(MapContext);

  const [, , unsetShowMapActionContextMenu] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU
  );
  const [, , unsetShowNewPoiForm] = useUrlParam(urlParamNames.mapContextMenu.SHOW_NEW_POI_FORM);

  let addedPoiId: string | undefined = undefined;
  const [shouldSetAddressToSearchString, setShouldSetAddressToSearchString] = React.useState(true);

  const { register, handleSubmit, errors, watch, getValues, setValue, control } = useForm<
    IFormData
  >({
    mode: 'onChange',
    defaultValues: {
      name: '',
      primaryCategory: undefined,
      secondaryCategory: undefined,
      tertiaryCategory: undefined,
      street: '',
      city: '',
      state: '',
      postcode: '',
      phoneNumber: undefined,
      emailAddress: undefined,
      websiteUrl: undefined,
    },
  });

  useEffect(() => {
    // If a user reloads a page with showNewPoiForm=y, it will show the new page with no eligible coordinates
    // in this case, dismiss the new point of interest panel
    if (!mapContext || !mapContext.getMapNewPoiCoords || !mapContext.getMapNewPoiCoords()) {
      unsetShowMapActionContextMenu();
      unsetShowNewPoiForm();
    }
  }, [unsetShowNewPoiForm, unsetShowMapActionContextMenu, mapContext]);

  React.useEffect(() => {
    reverseGeocode.loadAddress().then(() => {
      const addressDetails = reverseGeocode.address;
      setValue('street', addressDetails?.streetAddress);
      setValue('city', addressDetails?.city);
      setValue('postcode', addressDetails?.postcode);
      setValue('state', addressDetails?.state);

      if (shouldSetAddressToSearchString) {
        setValue('autocomplete', `${addressDetails?.streetAddress} ${addressDetails?.city}`.trim())
      }
      setShouldSetAddressToSearchString(false);
    });
    // watch reverseGeocode lat and lon and update address fields accordingly
  }, [reverseGeocode, reverseGeocode.lat, reverseGeocode.lng, setValue, shouldSetAddressToSearchString, setShouldSetAddressToSearchString]);

  const primaryCategory = watch('primaryCategory');
  const secondaryCategory = watch('secondaryCategory');

  const onSubmit = async (data: IFormData) => {
    const dto: IAddUserGeneratedPoiCommandModel = {
      name: data.name,
      primaryCategory: data.primaryCategory,
      secondaryCategory:
        data.secondaryCategory?.toString() !== 'None' ? data.secondaryCategory : undefined,
      tertiaryCategory:
        data.tertiaryCategory?.toString() !== 'None' ? data.tertiaryCategory : undefined,
      street: data.street,
      city: data.city,
      state: data.state,
      postcode: data.postcode || '',
      latitude: 0,
      longitude: 0,
      phoneNumber: data.phoneNumber,
      emailAddress: data.emailAddress,
      websiteUrl: data.websiteUrl,
    };

    if (mapContext.getMapNewPoiCoords && mapContext.getMapNewPoiCoords()) {
      const [longitude, latitude] = mapContext.getMapNewPoiCoords();
        dto.longitude = longitude;
        dto.latitude = latitude;
    }

    addedPoiId = await pointsOfInterestRepo.addNewPointOfInterest(dto);
  };

  const afterSubmit = function () {
    if (!addedPoiId) {
      return;
    }

    unsetShowMapActionContextMenu();
    unsetShowNewPoiForm();

    history.push(
      routePaths.poi.toKeepingExistingParams(addedPoiId, {
        FULL_DETAIL: true,
      })(history.location)
    );
  };

  const validCategorySelections = allPointOfInterestCategory
    .map(p => {
      return { value: p.name, description: p.description };
    })
    .sort((a, b) => a.description.localeCompare(b.description));

  function shuffleCategoryFieldsUpwards() {
    const { primaryCategory, secondaryCategory, tertiaryCategory } = getValues();

    if (primaryCategory === secondaryCategory) {
      setValue('secondaryCategory', tertiaryCategory);
      setValue('tertiaryCategory', undefined);
    }

    if (primaryCategory === tertiaryCategory) {
      setValue('tertiaryCategory', undefined);
    }

    if (secondaryCategory === tertiaryCategory) {
      setValue('tertiaryCategory', undefined);
    }

    if (tertiaryCategory?.toString() === 'None') {
      setValue('tertiaryCategory', undefined);
    }

    if (secondaryCategory?.toString() === 'None') {
      if (tertiaryCategory) {
        setValue('secondaryCategory', tertiaryCategory);
        setValue('tertiaryCategory', undefined);
      } else {
        setValue('secondaryCategory', undefined);
      }
    }
  }

  const closeButton = (
    <BackButton
      onClick={() => {
        unsetShowMapActionContextMenu();
        unsetShowNewPoiForm();
      }}
    />
  );

  const nameField = (
    <Input
      required
      label="Name"
      name="name"
      inputErrors={errors.name}
      register={register({
        required: 'Please enter a name',
        maxLength: { value: 250, message: 'Maximum 250 characters' },
        pattern: { value: /\S+/, message: 'Name cannot be blank' },
      })}
      placeholder="Type name here..."
    />
  );

  const primaryCategoryField = (
    <Select
      required={true}
      label="Category"
      name="primaryCategory"
      selectErrors={errors.primaryCategory}
      register={register({
        required: 'Please select a category',
      })}
      selections={validCategorySelections}
      placeholder="Select one..."
      onChange={shuffleCategoryFieldsUpwards}
    />
  );

  const secondaryCategoryField = (
    <Select
      label="Additional Category"
      name="secondaryCategory"
      selectErrors={errors.secondaryCategory}
      register={register()}
      selections={[
        { value: undefined, description: 'None' },
        ...validCategorySelections.filter(c => getValues().primaryCategory?.toString() !== c.value),
      ]}
      placeholder="Select one..."
      onChange={shuffleCategoryFieldsUpwards}
    />
  );

  const tertiaryCategoryField = (
    <Select
      label="Additional Category"
      name="tertiaryCategory"
      selectErrors={errors.tertiaryCategory}
      register={register()}
      selections={[
        { value: undefined, description: 'None' },
        ...validCategorySelections.filter(
          c =>
            getValues().primaryCategory?.toString() !== c.value &&
            getValues().secondaryCategory?.toString() !== c.value
        ),
      ]}
      placeholder="Select one..."
      onChange={shuffleCategoryFieldsUpwards}
    />
  );

  const streetField = (
    <Input
      label="Street Address"
      name="street"
      inputErrors={errors.street}
      register={register({
        maxLength: { value: 150, message: 'Maximum 150 characters' },
        pattern: { value: /\S+/, message: 'Street Address cannot be blank' },
      })}
      placeholder="Type street here..."
      disabled={reverseGeocode.isLoading}
    />
  );

  const cityField = (
    <Input
      required
      label="City/Suburb"
      name="city"
      inputErrors={errors.city}
      register={register({
        required: 'Please enter a city or suburb',
        maxLength: { value: 50, message: 'Maximum 50 characters' },
        pattern: { value: /\S+/, message: 'City/Suburb cannot be blank' },
      })}
      placeholder="Type city/suburb here..."
      disabled={reverseGeocode.isLoading}
    />
  );

  const stateField = (
    <Select
      label="State"
      name="state"
      selectErrors={errors.state}
      register={register()}
      selections={[
        { value: 'ACT', description: 'Australian Capital Territory' },
        { value: 'NSW', description: 'New South Wales' },
        { value: 'NT', description: 'Northern Territory' },
        { value: 'QLD', description: 'Queensland' },
        { value: 'SA', description: 'South Australia' },
        { value: 'TAS', description: 'Tasmania' },
        { value: 'VIC', description: 'Victoria' },
        { value: 'WA', description: 'Western Australia' },
      ]}
      placeholder="Select one..."
      disabled={reverseGeocode.isLoading}
    />
  );

  const postcodeField = (
    <Input
      label="Postcode"
      name="postcode"
      inputErrors={errors.postcode}
      register={register({
        maxLength: { value: 4, message: 'Maximum 4 characters' },
        pattern: { value: /\d{4}/, message: 'Please enter a valid postcode' },
      })}
      placeholder="Type postcode here..."
      disabled={reverseGeocode.isLoading}
    />
  );

  const phoneNumberField = (
    <Input
      type="tel"
      label="Phone Number"
      name="phoneNumber"
      inputErrors={errors.phoneNumber}
      register={register({
        maxLength: { value: 15, message: 'Maximum 15 characters' },
      })}
      placeholder="Type phone number here..."
    />
  );

  const emailAddressField = (
    <Input
      type="email"
      label="Email Address"
      name="emailAddress"
      inputErrors={errors.emailAddress}
      register={register({
        maxLength: { value: 320, message: 'Maximum 320 characters' },
      })}
      placeholder="Type email address here..."
    />
  );

  const websiteUrlField = (
    <Input
      type="url"
      label="Website"
      name="websiteUrl"
      inputErrors={errors.websiteUrl}
      register={register()}
      placeholder="Type website here..."
      onBlur={() => {
        const { websiteUrl } = getValues();
        if (websiteUrl?.length && !websiteUrl.match(/^https?:\/{2}/)) {
          setValue('websiteUrl', `http://${websiteUrl}`);
        }
      }}
    />
  );

  if (!store.security.isLoggedIn) {
    return null;
  }

  const fuzzySearchRepo = store.fuzzySearchRepo;

  const [defaultSuggestion, ] = React.useState<IAddressDtoModel>({
    businessName: undefined,
    businessPhone: undefined,
    businessUrl: undefined,
    city: '',
    freeFormAddress: '',
    key: 'Not here? Enter details manually...',
    latitude: 0,
    longitude: 0,
    postcode: '',
    state: '',
    streetAddress: '',
  })

  const [suggestions, setSuggestions] = React.useState<IAddressDtoModel[]>();
  const [overrideAddressDetails, setOverrideAddressDetails] = React.useState(false);

  const setSearch = (search: string) => {
    getFuzzySearchSuggestions(search);
  }

  function getFuzzySearchSuggestions(search: string) {
    if (mapContext.getMapNewPoiCoords && mapContext.getMapNewPoiCoords()) {
      const [longitude, latitude] = mapContext.getMapNewPoiCoords();
      fuzzySearchRepo.search(search, latitude, longitude).then(() => {
        // searches with less than 3 characters don't hit the api so clear suggestions
        if (search.length < 3) {
          setSuggestions(undefined);
        }
        else {
          setSuggestions(fuzzySearchRepo.searchResults);
        }
      }); 
    }
  }

  function setActiveSuggestion(suggestion: IAddressDtoModel) {
    if (suggestion === defaultSuggestion) {
      setOverrideAddressDetails(true);
    }
    else {
      setValue('name', suggestion.businessName);
      setValue('street', suggestion.streetAddress);
      setValue('city', suggestion.city);
      setValue('postcode', suggestion.postcode);
      setValue('phoneNumber', suggestion.businessPhone);
      setValue('state', suggestion.state);

      let websiteUrl = suggestion.businessUrl;
      if (websiteUrl?.length && !websiteUrl.match(/^https?:\/{2}/)) {
        websiteUrl = `http://${websiteUrl}`;
      }
      setValue('websiteUrl', websiteUrl);

      if (mapContext.setMapNewPoiCoords) {
        mapContext.setMapNewPoiCoords(suggestion.latitude, suggestion.longitude);
      }
      
      setValue('autocomplete', suggestion.key);
      setOverrideAddressDetails(false);
    }

    setSuggestions(undefined);
  }

  const autocompleteField = (
    <Autocomplete
      register={register}
      name="autocomplete"
      label="Address"
      placeholder="Search business/address"
      setActiveSuggestion={setActiveSuggestion}
      setSearch={setSearch}
      suggestions={suggestions}
      defaultSuggestion={defaultSuggestion}
    />
  )

  function addressErrorsExist() {
    return errors.street || errors.city || errors.state || errors.postcode
  }

  const addressFields = (
    <div className={overrideAddressDetails || addressErrorsExist()  ? "" : styles.hidden}>
      {streetField}
      {cityField}
      {stateField}
      {postcodeField}
    </div>
  )

  return (
    <section>
      {/*
       * Note: The section wrapper above is to stop a bug in Safari where the user can no longer type in
       * inputs. If you want to remove it, make sure you don't reintroduce that bug.
       */}
      <SubpageForm
        title="New Place"
        titleLeftAligned={true}
        leftAction={closeButton}
        onSubmit={handleSubmit(onSubmit)}
        afterSubmit={afterSubmit}
        control={control}
        firstTagToFocus="button"
        onEsc={unsetShowNewPoiForm}>
        {autocompleteField}
        {nameField}
        {primaryCategoryField}
        {primaryCategory && secondaryCategoryField}
        {secondaryCategory && tertiaryCategoryField}
        {addressFields}
        {phoneNumberField}
        {emailAddressField}
        {websiteUrlField}
      </SubpageForm>
    </section>
  );
});
