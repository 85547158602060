import React from 'react';
import { DisplayName } from './DisplayName';
import { Mobility } from './Mobility';
import { EmailConsent } from './EmailConsent';
import { useStore } from 'views/hooks';
import cn from 'classnames';
import styles from './Onboarding.module.scss';

interface IOnboardingProps {
  className?: string;
  onCancelOnboarding: () => void;
}

export const Onboarding: React.FC<IOnboardingProps> = function ({ className, onCancelOnboarding }) {
  const security = useStore().security;

  const [page, setPage] = React.useState<'closed' | '1' | '2' | '3'>('1');
  const [displayName, setDisplayName] = React.useState(
    security.userProfile && security.userProfile.displayName
  );

  const [mobilityAid, setMobilityAid] = React.useState(
    security.userProfile && security.userProfile.mobilityAid
  )

  const onFinish = (emailAddress: string | undefined, emailConsent: boolean) => {
    if (!displayName) {
      setPage('1');
      throw new Error('Display name not set, please try again');
    }

    if (!mobilityAid) {
      setPage('2');
      throw new Error('Mobility Aid not set, please try again');
    }

    security.onboardUser(displayName, mobilityAid, emailAddress, emailConsent);
  };

  const cancel = () => {
    setPage('closed');
    onCancelOnboarding();
  };

  return (
    <>
      <DisplayName
        className={cn(className, styles.page, { [styles.hidden]: page !== '1' })}
        onNext={() => setPage('2')}
        onBack={cancel}
        setDisplayName={setDisplayName}
      />
      <Mobility
        className={cn(className, styles.page, { [styles.hidden]: page !== '2' })}
        onBack={() => setPage('1')}
        onNext={() => setPage('3')}
        displayName={displayName || ''}
        setMobilityAid={setMobilityAid}
      />
      <EmailConsent
      className={cn(className, styles.page, { [styles.hidden]: page !== '3' })}
      onBack={() => setPage('2')}
      onFinish={onFinish}
      afterFinish={() => setPage('closed')}
      displayName={displayName || ''}
      />
    </>
  );
};
