import React from 'react';
import styles from './ImageFilePicker.module.scss';
import { useDropzone } from 'react-dropzone';
import { IReviewImageUrlsDtoModel } from 'api/models/Domain/Queries/SharedDtos/Reviews/ReviewImageUrlsDtoModel';
import cn from 'classnames';
import { useForm } from 'react-hook-form';

interface IInputProps {
  name: string;
  register: ReturnType<typeof useForm>['register'];
  label: string;
  maxImages: number;
  setValue: ReturnType<typeof useForm>['setValue'];
  watch: ReturnType<typeof useForm>['watch'];
  existingImages?: IReviewImageUrlsDtoModel[];
  errors: ReturnType<typeof useForm>['errors'];
}

export interface ImageFile extends File {
  preview: string;
  isFile: true;
  isDeleted: boolean;
}

export interface ImageUrl extends IReviewImageUrlsDtoModel {
  preview: string;
  name: string;
  isFile: false;
  isDeleted: boolean;
}

export const ImageFilePicker: React.FC<IInputProps> = function ({
  register,
  name,
  maxImages,
  setValue,
  watch,
  existingImages,
  errors,
}) {
  const [files, setFiles] = React.useState<Array<ImageFile | ImageUrl>>([]);
  const fileInputValues: Array<ImageFile | ImageUrl> = watch(name);

  React.useEffect(() => {
    register(
      { name: name },
      {
        validate: (value: Array<ImageFile | ImageUrl>) => {
          return value.filter(v => !v.isDeleted).length <= maxImages || `Maximum ${maxImages} images`;
        },
      }
    );
    setFiles(existingImages?.map(i => newImageUrl(i)) || []);
    setValue('photos', existingImages?.map(i => newImageUrl(i)) || []);
  }, [name, register, setValue, maxImages, existingImages]);

  React.useEffect(() => setFiles(fileInputValues || []), [fileInputValues]);

  const newImageUrl = (image: IReviewImageUrlsDtoModel): ImageUrl => {
    return {
      ...image,
      name: image.thumbnailUrl,
      preview: image.thumbnailUrl,
      isFile: false,
    } as ImageUrl;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const filesAggregate = [
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isFile: true,
          })
        ),
      ];
      setValue(name, filesAggregate);
    },
  });

  const markFileAsDeleted = (file: ImageFile | ImageUrl) => {
    file.isDeleted = !file.isDeleted;
    setValue(name, files);
  };

  const thumbs = files.map((file, i) => {
    const title = file.isDeleted ? 'Un-mark image to be removed' : 'Mark image to be removed';

    return (
      <button
        type="button"
        className={cn(styles.thumbnail, { [styles.deleted]: file.isDeleted })}
        key={i}
        onClick={() => markFileAsDeleted(file)}
        aria-label={`${title} ${file.name}`}
        title={title}>
        <img
          className={styles.thumbnailInner}
          src={file.preview}
          alt=""
          onLoad={() => URL.revokeObjectURL(file.preview)}
        />
      </button>
    );
  });

  const addImageButton = (
    <button
      type="button"
      {...getRootProps()}
      key="addImageButton"
      className={styles.thumbnail}
      aria-label="Add image"
      title="Add image">
      <div className={cn(styles.thumbnailInner, styles.addImagesButton)}>&#65291;</div>
    </button>
  );

  return (
    <div className={styles.root}>
      <label htmlFor={name} className={cn({ [styles.error]: errors.photos })}>
        Photos
      </label>
      <div className={cn(styles.container, { [styles.error]: errors.photos })}>
        <input name={name} {...getInputProps()} />
        {<div className={styles.thumbnailContainer}>{[...thumbs, addImageButton]}</div>}
        <p {...getRootProps()} className={styles.dropzone}>
          Photos of entries, circulation space, seating areas, and bathrooms will be of most help.
        </p>
      </div>
      {errors.photos && <span className={styles.errorMessage}>{errors?.photos.message}</span>}
    </div>
  );
};
