import { types, Instance, IStateTreeNode, getEnv, addMiddleware, flow } from 'mobx-state-tree';
import ky from 'ky';
import * as H from 'history';
import { globalErrorHandlerMiddleware } from './middlewares/globalErrorHandler';
import { PointsOfInterestRepo } from './repos/PointsOfInterestRepo';
import { NotificationsModel } from './singletons/NotificationsModel';
import { ConfigModel } from './singletons/ConfigModel';
import { EnvironmentModel } from './singletons/EnvironmentModel';
import { AreasRepo } from './repos/AreasRepo';
import { CategoriesRepo } from './repos/CategoriesRepo';
import { SearchModel } from './singletons/SearchModel';
import { ReviewsRepo } from './repos/ReviewsRepo';
import { SecurityModel } from './singletons/SecurityModel';
import { AnalyticsModel } from './singletons/AnalyticsModel';
import { FeedbackModel } from './singletons/FeedbackModel';
import { PageTitleModel } from './singletons/PageTitleModel';
import { ReverseGeocodeModel } from './singletons/ReverseGeocodeModel';
import { UserGeolocationModel } from './singletons/UserGeolocationModel';
import { FuzzySearchRepo } from './repos/FuzzySearchRepo';

const RootStoreModel = types
  .model(`RootStoreModel`, {
    notifications: types.optional(NotificationsModel, {}),
    configuration: types.optional(ConfigModel, {}),
    environment: types.optional(EnvironmentModel, {}),
    pointsOfInterestRepo: types.optional(PointsOfInterestRepo, {}),
    areasRepo: types.optional(AreasRepo, {}),
    categoriesRepo: types.optional(CategoriesRepo, {}),
    reviewsRepo: types.optional(ReviewsRepo, {}),
    search: types.optional(SearchModel, {}),
    security: types.optional(SecurityModel, {}),
    analytics: types.optional(AnalyticsModel, {}),
    feedback: types.optional(FeedbackModel, {}),
    title: types.optional(PageTitleModel, {}),
    reverseGeocode: types.optional(ReverseGeocodeModel, {}),
    geolocation: types.optional(UserGeolocationModel, {}),
    fuzzySearchRepo: types.optional(FuzzySearchRepo, {}),
  })
  .actions(self => {
    function* init() {
      self.environment.init();
      yield Promise.all([self.security.getUserProfile(), self.configuration.load()]);
      self.analytics.init(getEnv(self).history, self.configuration.googleAnalytics.trackingKey);
      self.title.init(getEnv(self).history);
    }

    async function startGeolocationPolling(interval: number) {
      await self.geolocation.updateGeolocation(interval, false);
      setInterval(self.geolocation.updateGeolocation, interval);
    }

    return { init: flow(init), startGeolocationPolling };
  });

export interface IRootStoreModel extends Instance<typeof RootStoreModel> {}

interface IStoreEnvironment {
  ajax: typeof ky;
  history: H.History;
}

export function getDefaultStore(history: H.History): IRootStoreModel {
  const storeEnv: IStoreEnvironment = {
    ajax: ky.create({ retry: { limit: 1 } }),
    history: history,
  };
  const store = RootStoreModel.create({}, storeEnv);
  addMiddleware(store, globalErrorHandlerMiddleware);
  return store;
}

export function getAjax(model: IStateTreeNode) {
  return getEnv(model).ajax;
}
