type CoordinateArgument = string | number | undefined | null;
export function isValidCoordinate(
  latitude: CoordinateArgument,
  longitude: CoordinateArgument
): boolean {
  if (!latitude || !longitude) return false;

  const lat = Number(latitude);
  const lng = Number(longitude);

  if (isNaN(lat) || isNaN(lng)) return false;

  if (lat > 90 || lat < -90) return false;

  if (lng > 180 || lng < -180) return false;

  return true;
}
