import { types, Instance } from 'mobx-state-tree';
import { ReviewImageUrlsDtoModel as DomainQueriesSharedDtosReviewsReviewImageUrlsDtoModel } from './../../../../Domain/Queries/SharedDtos/Reviews/ReviewImageUrlsDtoModel';

export const ReviewDtoModel = types.model('ReviewDtoModel', {
  accessibilityRating: types.integer,
  createdAt: types.string,
  editable: types.boolean,
  hasBeenEdited: types.boolean,
  id: types.identifier,
  mobilityAidUsed: types.frozen<Domain.Aggregates.User.Entities.MobilityAid>(),
  reviewImageUrls: types.array( DomainQueriesSharedDtosReviewsReviewImageUrlsDtoModel),
  reviewText: types.string,
  serviceRating: types.maybe(types.integer),
  userDisplayName: types.string,
});

export interface IReviewDtoModel extends Instance<typeof ReviewDtoModel> {}
