import { types, Instance } from 'mobx-state-tree';
import { LocationDtoModel as DomainQueriesPointOfInterestSearchPointsOfInterestQueryLocationDtoModel } from './LocationDtoModel';

export const PointOfInterestDtoModel = types.model('PointOfInterestDtoModel', {
  alternateCategories: types.frozen<Domain.Aggregates.PointOfInterest.Entities.PointOfInterestCategory>(),
  averageAccessibilityRating: types.maybe(types.number),
  distanceInMetres: types.maybe(types.integer),
  id: types.identifier,
  location: DomainQueriesPointOfInterestSearchPointsOfInterestQueryLocationDtoModel,
  name: types.string,
  primaryCategory: types.frozen<Domain.Aggregates.PointOfInterest.Entities.PointOfInterestCategory>(),
  reviewCount: types.integer,
  shortAddress: types.string,
});

export interface IPointOfInterestDtoModel extends Instance<typeof PointOfInterestDtoModel> {}
