import React from 'react';
import styles from './PoisPanel.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore, useUrlParam } from 'views/hooks';
import { PoiResultItem } from './PoiResultItem';
import { SearchFurther, maxSearchRadius } from './searchFurther/SearchFurther';
import { urlParamNames } from 'views/routes/routePaths';
import { pluraliseCategory } from 'domain/store/repos/CategoriesRepo';
import { addParamToLocation } from 'infrastructure/locationUtils';
import { Link, LinkProps } from 'react-router-dom';
import { Icon, IIconProps } from 'views/components/Icon';

interface IShareSearchResultsProps {
  label: string;
  to: LinkProps['to'];
  iconName: IIconProps['name'];
}

const ShareSearchResults: React.FC<IShareSearchResultsProps> = function ({
  label,
  to,
  iconName,
}) {
  return (
    <Link className={styles.titleButton} aria-label={label} to={to}>
      <Icon name={iconName} className={styles.icon}/>
      <span>{label}</span>
    </Link>
  );
};

export const PoisPanel: React.FC = observer(function PoisPanel() {
  const store = useStore();
  const poiRepo = store.pointsOfInterestRepo;
  const nearbySearchResults = poiRepo.nearbySearchResults;
  const distantSearchResults = poiRepo.distantSearchResults;
  const searchModel = store.search;

  const [radius] = useUrlParam(urlParamNames.search.SEARCH_RADIUS);
  const [categoryValue] = useUrlParam(urlParamNames.search.CATEGORY);
  const nearbyResultsCount = !!nearbySearchResults.length;

  if (searchModel.state === 'cleared') {
    return null;
  }

  const resultsHeader = categoryValue ? pluraliseCategory(categoryValue) : 'Results';

  return (
    <section>
      {(nearbySearchResults.length || Number(radius) < maxSearchRadius) && (
        <div className={styles.header}>
          <h2 className={styles.title}>{resultsHeader}</h2>
          <ShareSearchResults
            label={"Share"}
            iconName="forward-arrow"
            to={l => addParamToLocation(l, urlParamNames.search.SHOW_SHARE, 'y')}
          />
        </div>
      )}
      {nearbyResultsCount && (
        <ul aria-label={'Search results'}>
          {nearbySearchResults.map(p => (
            <li key={p.id} className={styles.item}>
              <PoiResultItem item={p} />
            </li>
          ))}
        </ul>
      )}
      <SearchFurther />
      {distantSearchResults.length > 0 && (
        <>
          <h2 className={styles.title}>{resultsHeader} in other areas</h2>
          <ul aria-label={'Search results in other areas'}>
            {distantSearchResults.map(p => (
              <li key={p.id} className={styles.item}>
                <PoiResultItem item={p} />
              </li>
            ))}
          </ul>
        </>
      )}
    </section>
  );
});
