import { types, Instance } from 'mobx-state-tree';
import { ReviewImageUrlsDtoModel as DomainQueriesSharedDtosReviewsReviewImageUrlsDtoModel } from './../../../../Domain/Queries/SharedDtos/Reviews/ReviewImageUrlsDtoModel';
import { LocationDtoModel as DomainQueriesPointOfInterestGetPointOfInterestQueryLocationDtoModel } from './LocationDtoModel';

export const PointOfInterestDtoModel = types.model('PointOfInterestDtoModel', {
  alternateCategories: types.frozen<Domain.Aggregates.PointOfInterest.Entities.PointOfInterestCategory>(),
  attributes: types.array(types.string),
  averageAccessibilityRating: types.maybe(types.number),
  averageServiceRating: types.maybe(types.number),
  emailAddress: types.maybe(types.string),
  fullAddress: types.string,
  id: types.identifier,
  images: types.array( DomainQueriesSharedDtosReviewsReviewImageUrlsDtoModel),
  isReportedByCurrentUser: types.boolean,
  location: DomainQueriesPointOfInterestGetPointOfInterestQueryLocationDtoModel,
  name: types.string,
  phoneNumber: types.maybe(types.string),
  primaryCategory: types.frozen<Domain.Aggregates.PointOfInterest.Entities.PointOfInterestCategory>(),
  reviewCount: types.integer,
  shortAddress: types.string,
  sourcePoiUrl: types.maybe(types.string),
  webBeaconUrl: types.maybe(types.string),
  websiteUrl: types.maybe(types.string),
});

export interface IPointOfInterestDtoModel extends Instance<typeof PointOfInterestDtoModel> {}
