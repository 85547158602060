import React from 'react';
import styles from './Subpage.module.scss';
import cn from 'classnames';
import { getMillisecondsFromCssTime } from 'infrastructure/cssUtils';
import { siteDefault } from 'views/themes';
import { useStore } from 'views/hooks';

interface ISubsectionProps {
  className?: string;
  compact?: boolean;
}

export const Subsection: React.FC<ISubsectionProps> = function ({ className, compact, children }) {
  return (
    <section className={cn(className, styles.subsection, { [styles.compact]: compact })}>
      {children}
    </section>
  );
};

interface ISubpageProps {
  className?: string;
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
  title?: string;
  titleLeftAligned?: boolean;
  containerClassName?: string;
  firstTagToFocus?: 'a' | 'input' | 'button';
}

export const Subpage: React.FC<ISubpageProps> = function ({
  className,
  leftAction,
  rightAction,
  title,
  titleLeftAligned,
  containerClassName,
  firstTagToFocus,
  children,
}) {
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const store = useStore();
  const isSmallViewport = store.environment.isSmallViewport;

  React.useEffect(() => {
    if (firstTagToFocus && divRef.current && !isSmallViewport) {
      const inputs = divRef.current.getElementsByTagName(firstTagToFocus);
      if (inputs.length > 0) {
        setTimeout(() => {
          inputs[0].focus();
        }, getMillisecondsFromCssTime(siteDefault['--reveal-page-transition-time']));
      }
    }
  }, [firstTagToFocus, isSmallViewport]);

  return (
    <div className={cn(className, styles.root)} ref={divRef}>
      <div className={styles.actions}>
        <div className={styles.left}>{leftAction}</div>
        <div className={styles.right}>{rightAction}</div>
      </div>
      {title && (
        <h2 className={cn(styles.title, { [styles.leftAlign]: titleLeftAligned })}>{title}</h2>
      )}
      <div className={containerClassName}>{children}</div>
    </div>
  );
};
