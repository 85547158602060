import { MobilityAid } from 'api/enums/MobilityAid';
import { IDeleteFacebookUserDataCommandModel } from 'api/models/Domain/Aggregates/User/Commands/DeleteFacebookUserDataCommandModel';
import { IOnboardUserCommandModel } from 'api/models/Domain/Aggregates/User/Commands/OnboardUserCommandModel';
import { IUpdateUserEmailConsentCommandModel } from 'api/models/Domain/Aggregates/User/Commands/UpdateUserEmailConsentCommandModel';
import { IUpdateUserProfileCommandModel } from 'api/models/Domain/Aggregates/User/Commands/UpdateUserProfileCommandModel';
import { ProfileDtoModel } from 'api/models/Domain/Queries/User/GetCurrentUserProfileQuery/ProfileDtoModel';
import { getAjax } from 'domain/store/RootStoreModel';
import ky from 'ky';
import { cast, flow, types } from 'mobx-state-tree';

type IProfileDto = Domain.Queries.User.GetCurrentUserProfileQuery.IProfileDto;

export const SecurityModel = types
  .model('SecurityModel', {
    userProfile: types.maybe(ProfileDtoModel),
    isLoading: types.optional(types.boolean, false),
  })
  .extend(self => {
    function signIn(method: string) {
      window.location.assign(
        `/sign-in?returnUrl=${encodeURIComponent(window.location.href)}&method=${method}`
      );
    }

    function signOut() {
      window.location.assign(`/sign-out?returnUrl=${encodeURIComponent(window.location.href)}`);
    }

    function* getUserProfile() {
      try {
        self.isLoading = true;
        const profile: IProfileDto = yield getAjax(self).get('/api/user/profile').json();

        self.userProfile = cast(profile);
      } catch (error) {
        if (error instanceof ky.HTTPError && error.response.status === 401) {
          self.userProfile = undefined;
          return;
        }
        throw error;
      } finally {
        self.isLoading = false;
      }
    }

    function* onboardUser(
      displayName: string,
      mobilityAid: MobilityAid,
      emailAddress: string | undefined,
      emailConsent: boolean
    ): Generator {
      if (!self.userProfile) {
        throw new Error('No user profile found. Please sign in.');
      }

      self.userProfile.displayName = displayName;
      self.userProfile.mobilityAid = mobilityAid;
      self.userProfile.emailConsent = emailConsent;
      self.userProfile.emailAddress = emailAddress;

      const command: IOnboardUserCommandModel = {
        displayName: displayName,
        mobilityAid: mobilityAid,
        emailConsent: emailConsent,
        emailAddress: emailAddress,
      };

      yield getAjax(self).post('/api/user/onboard', {
        json: command,
      });

      self.userProfile.hasOnboarded = true;
    }

    function* updateUserProfile(command: IUpdateUserProfileCommandModel): Generator {
      if (!self.userProfile) {
        throw new Error('No user profile found. Please sign in.');
      }

      if (
        self.userProfile.displayName === command.displayName &&
        self.userProfile.mobilityAid === command.mobilityAid &&
        self.userProfile.emailAddress === command.emailAddress &&
        self.userProfile.emailConsent === command.emailConsent
      ) {
        return;
      }

      yield getAjax(self).put('/api/user/profile', {
        json: command,
      });
    }

    function* updateUserEmailConsentDetails(
      command: IUpdateUserEmailConsentCommandModel
    ): Generator {
      if (!self.userProfile) {
        throw new Error('No user profile found. Please sign in.');
      }

      self.userProfile.emailConsent = command.emailConsent;
      self.userProfile.emailAddress = command.emailAddress;

      yield getAjax(self).put('/api/user/emailconsent', {
        json: command,
      });
    }

    function* deleteFacebookUserData(facebookId: string): Generator {
      if (!self.userProfile) {
        throw new Error('No user profile found. Please sign in.');
      } else if (!self.userProfile.facebookId) {
        throw new Error('No facebook ID is associated with the user profile.');
      }

      try {
        self.isLoading = true;

        const command: IDeleteFacebookUserDataCommandModel = {
          facebookId: facebookId,
        };

        yield getAjax(self).post('/api/user/requestDeleteFacebookUserData', {
          json: command,
        });
        self.userProfile = undefined;
      } finally {
        self.isLoading = false;
      }
    }

    return {
      actions: {
        signIn,
        signOut,
        getUserProfile: flow(getUserProfile),
        onboardUser: flow(onboardUser),
        updateUserProfile: flow(updateUserProfile),
        updateUserEmailConsentDetails: flow(updateUserEmailConsentDetails),
        deleteFacebookUserData: flow(deleteFacebookUserData),
      },
      views: {
        get isLoggedIn() {
          return !!self.userProfile;
        },
      },
    };
  });
