import { MutableRefObject, useContext, useEffect, useRef } from 'react';
import * as atlas from 'azure-maps-control';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import {
  registerDataSource,
  createMapLayers,
  registerMapEvents,
  loadPointsOfInterest,
  setSelectedPoi,
  centreCameraOnLocation,
  setUserPin,
  centreCameraOnUserLocation,
  reloadSinglePointOfInterest,
  setOverrideLocationMarker,
  setMapActionMarker,
  clearMapMarker,
  initializeOverrideLocationMarkerAtPosition,
  markers,
} from './aaMapHelpers';
import { IPointOfInterestDtoModel } from 'api/models/Domain/Queries/PointOfInterest/SearchPointsOfInterestQuery/PointOfInterestDtoModel';
import { urlParamNames } from 'views/routes/routePaths';
import { MapContext } from 'views/routes/pois/Search';
import { useStore, useUrlParam } from 'views/hooks';
import { isValidCoordinate } from 'infrastructure/geoUtils';
import { getParamFromLocation } from 'infrastructure/locationUtils';

type AtlasMapConstructorOptions = ConstructorParameters<typeof atlas.Map>[1];

export function useAzureMap(
  mapContainer: MutableRefObject<HTMLDivElement | null>,
  subscriptionKey: string,
  selectedPoi: MutableRefObject<IPointOfInterestDtoModel | undefined>,
  searchPosition: MutableRefObject<atlas.data.Position | undefined>
) {
  const map = useRef<atlas.Map | undefined>(undefined);
  const store = useStore();
  const updatePosition = store.reverseGeocode.updatePosition;
  const mapContext = useContext(MapContext);
  const history = useHistory();
  const htmlMarkers: Map<string, atlas.HtmlMarker> = new Map();
  const [searchCoords] = useUrlParam(urlParamNames.search.SEARCH_COORDINATES);
  const [, setShowMapActionContextMenu, unsetShowMapActionContextMenu] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU
  );
  const [searchLat, searchLng] = searchCoords !== null ? searchCoords.split('_') : [null, null];
  const [overrideCoordinates, setOverrideCoordinates, unsetOverrideCoordinates] = useUrlParam(
    urlParamNames.search.OVERRIDE_COORDINATES
  );
  const [overrideLat, overrideLng] =
    overrideCoordinates !== null ? overrideCoordinates.split('_') : [null, null];
  const urlPosition = new atlas.data.Point([Number(searchLng), Number(searchLat)]);
  const poiRepo = store.pointsOfInterestRepo;
  const geolocation = store.geolocation;
  const hasSearchPosition = searchLat && searchLng;

  const defaultCoordinatesForAustralia = new atlas.data.Point([133.46191, -27.41078]);

  const defaultZoomLevel = () => {
    if (hasSearchPosition) {
      return 16;
    }

    const largestDimension = Math.max(window.innerWidth, window.innerHeight);
    const multipliedDimension = Math.ceil(largestDimension * 0.00275);
    return Math.max(2, Math.min(4, multipliedDimension));
  };

  const centre = hasSearchPosition ? urlPosition : defaultCoordinatesForAustralia;

  const options: AtlasMapConstructorOptions = {
    autoResize: false,
    center: centre.coordinates,
    zoom: defaultZoomLevel(),
    language: 'en-AU',
    view: 'Auto',
    showFeedbackLink: false,
    showLogo: false,
    style: 'road_shaded_relief',
    authOptions: {
      authType: atlas.AuthenticationType.subscriptionKey,
      subscriptionKey: subscriptionKey,
    },
  };

  function mapPosChanged() {
    const mapCentre = map.current?.getCamera().center;
    const moveTolerance = 0.005;

    mapContext.mapHasMoved =
      mapCentre &&
      searchPosition.current &&
      (Math.abs(searchPosition.current[0] - mapCentre[0]) > moveTolerance ||
        Math.abs(searchPosition.current[1] - mapCentre[1]) > moveTolerance);
  }

  const [updateMapSize] = useDebouncedCallback(() => map.current?.resize(), 10);
  // Debounce this function as if the map interactivity is changed
  // while a pinch is in progress it can cause the azure maps control to jam
  const [updateMapInteractivity] = useDebouncedCallback(() => {
    if (!map.current) {
      return;
    }

    // Only allow interactivity when the visual viewport is not zoomed in.
    // When the app is pinch-zoomed on touch devices, allowing both the map
    // and the site itself to pan is confusing and difficult to use.
    const mapInteractive = map.current.getUserInteraction().interactive;
    const newInteractive = window.visualViewport.scale <= 1;
    if (newInteractive !== mapInteractive) {
      map.current.setUserInteraction({ interactive: newInteractive });
    }
  }, 50);

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    map.current = new atlas.Map(mapContainer.current, options);

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', updateMapSize);
      window.visualViewport.addEventListener('resize', updateMapInteractivity);
    }
    map.current.events.add('ready', async e => {
      const dataSource = registerDataSource(e.map);

      centreCameraOnLocation(e.map, centre, { zoom: options.zoom });
      createMapLayers(e.map, dataSource);

      registerMapEvents(
        e.map,
        history,
        htmlMarkers,
        selectedPoi,
        poiRepo.searchResults,
        mapPosChanged,
        setShowMapActionContextMenu,
        unsetShowMapActionContextMenu,
        updatePosition
      );

      loadPointsOfInterest(e.map, poiRepo.searchResults);

      mapContext.centreMapOnUser = async () => {
        await geolocation
          .getCurrentLocation(5000)
          .then(([lat, long]) => {
            const userLocation = new atlas.data.Point([long, lat]);
            setUserPin(e.map, undefined, userLocation, unsetOverrideCoordinates);
            centreCameraOnUserLocation(e.map, selectedPoi.current, userLocation);
          })
          .catch(() => {
            // Show a warning toast if the user has denied their location
            store.notifications.addWarning('Please enable location permissions');
          });
      };

      mapContext.centreMapOnLocation = (
        lat: number,
        lng: number,
        options?: atlas.CameraOptions
      ) => {
        centreCameraOnLocation(e.map, new atlas.data.Point([lng, lat]), options);
      };

      mapContext.setMapOverrideCoords = () => {
        setOverrideLocationMarker(e.map, setOverrideCoordinates);
      };

      mapContext.getMapFocusCoords = () => {
        return map.current?.getCamera().center || null;
      };

      mapContext.getMapNewPoiCoords = () => {
        const newPoiMarker = map.current?.markers
          .getMarkers()
          .find(m => m.getOptions().text === markers.action);
        return newPoiMarker?.getOptions().position || null;
      };

      mapContext.setMapNewPoiCoords = (lat: number, lng: number) => {
        const newPoiMarker = e.map.markers
          .getMarkers()
          .find(m => m.getOptions().text === markers.action);

        if (newPoiMarker) {
          const position = new atlas.data.Position(lng, lat);
          const location = new atlas.data.Point([...position]);
          setMapActionMarker(e.map, location.coordinates, updatePosition);
          centreCameraOnLocation(e.map, location);
        }
      }

      mapContext.forceReloadSingleMapMarker = (id: string) =>
        map.current && reloadSinglePointOfInterest(map.current, id, poiRepo.searchResults);

      mapContext.zoom = (amount: number) => {
        if (map.current) {
          const zoom = map.current.getCamera().zoom;
          map.current.setCamera({
            zoom: zoom ? zoom + amount : 14,
            type: 'ease',
            duration: 200,
          });
        }
      };

      if (poiRepo.pointOfInterestDetails) {
        const selectedPoiLocation = new atlas.data.Point([
          poiRepo.pointOfInterestDetails.location.longitude,
          poiRepo.pointOfInterestDetails.location.latitude,
        ]);
        centreCameraOnLocation(e.map, selectedPoiLocation);
      } else if (hasSearchPosition) {
        centreCameraOnLocation(e.map, centre);
      }

      if (overrideLat && overrideLng) {
        initializeOverrideLocationMarkerAtPosition(
          e.map,
          new atlas.data.Position(Number(overrideLng), Number(overrideLat))
        );
        if (!hasSearchPosition && !poiRepo.pointOfInterestDetails) {
          centreCameraOnLocation(
            e.map,
            new atlas.data.Point([Number(overrideLng), Number(overrideLat)])
          );
        }
      } else {
        geolocation
          .getCurrentLocation(500)
          .then(([lat, long]) => {
            const userLocation = new atlas.data.Point([long, lat]);
            setUserPin(e.map, undefined, userLocation, unsetOverrideCoordinates);
            if (!hasSearchPosition && !poiRepo.pointOfInterestDetails) {
              centreCameraOnLocation(e.map, userLocation, {
                type: 'jump',
              });
            }
          })
          .catch(() => {
            if (!hasSearchPosition && !poiRepo.pointOfInterestDetails) {
              geolocation
                .getCurrentLocationFromIpAddress()
                .then(([lat, long]) =>
                  centreCameraOnLocation(e.map, new atlas.data.Point([long, lat]), {
                    type: 'jump',
                  })
                )
                .catch(() => {
                  // Do nothing if getting the user's ip location fails
                });
            }
          });
      }

      navigator.permissions &&
        navigator.permissions.query({ name: 'geolocation' }).then(result => {
          if (result.state === 'prompt') {
            result.onchange = () => {
              if (
                result.state === 'granted' &&
                !hasSearchPosition &&
                !poiRepo.pointOfInterestDetails &&
                !overrideLat &&
                !overrideLng
              ) {
                mapContext.centreMapOnUser && mapContext.centreMapOnUser();
              }
            };
          }
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapContainer, selectedPoi]);

  return map;
}

export function useSearchCoordinates(
  searchPosition: MutableRefObject<atlas.data.Position | undefined>
) {
  const history = useHistory();

  useEffect(() => {
    const searchCoordinates = getParamFromLocation(
      history.location,
      urlParamNames.search.SEARCH_COORDINATES
    );

    const [searchLat, searchLng] = searchCoordinates?.split('_') || [null, null];

    if (isValidCoordinate(searchLat, searchLng)) {
      searchPosition.current = new atlas.data.Position(Number(searchLng), Number(searchLat));
    }
  }, [searchPosition, history.location]);
}

export function usePointsOfInterest(map: atlas.Map | undefined) {
  const pointsOfInterest = useStore().pointsOfInterestRepo.searchResults;

  useEffect(() => {
    map && loadPointsOfInterest(map, pointsOfInterest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointsOfInterest, pointsOfInterest.length]);
}

export function useSelectedPoiFromUrl(
  map: atlas.Map | undefined,
  selectedPoi: MutableRefObject<IPointOfInterestDtoModel | undefined>
) {
  const history = useHistory();
  const pointsOfInterestRepo = useStore().pointsOfInterestRepo;

  useEffect(() => {
    if (map) {
      setSelectedPoi(map, history, selectedPoi, pointsOfInterestRepo.searchResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, history.location.pathname, pointsOfInterestRepo.pointOfInterestDetails]);
}

export function useShowMapActionContextMenuParam(map: atlas.Map | undefined) {
  const [showMapActionContextMenu] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU
  );
  const mapContext = useContext(MapContext);
  const isLoggedIn = useStore().security.isLoggedIn;
  const updatePosition = useStore().reverseGeocode.updatePosition;

  useEffect(() => {
    if (!map) {
      return;
    }

    if (!showMapActionContextMenu) {
      clearMapMarker(map, markers.action);
      return;
    }

    if (mapContext.getMapNewPoiCoords && !mapContext.getMapNewPoiCoords()) {
      const location = new atlas.data.Point([...map.getCamera().center]);
      setMapActionMarker(map, location.coordinates, updatePosition);
    }
  }, [map, showMapActionContextMenu, mapContext, isLoggedIn, updatePosition]);
}
