import { types } from 'mobx-state-tree';
import { History } from 'history';

interface SearchData {
  response: 'success' | 'error';
  searchTerm: string | undefined;
  category: string | undefined;
  numberOfResults: number;
  minimumAccessibility: number | undefined;
  radius: number | undefined;
  searchLocation: [number, number];
}

export const AnalyticsModel = types.model('AnalyticsModel', {}).actions(self => {
  function init(history: History, key: string | undefined) {
    if (!!key) {
      ga('create', key, 'auto');
      startUrlTracking(history);
    }
  }

  function startUrlTracking(history: History) {
    ga('send', 'pageview');

    history.listen(e => {
      ga('set', 'page', `${e.pathname}${e.search}`);
      ga('send', 'pageview');
    });
  }

  function recordSearch(searchMetaData: SearchData) {
    ga('send', 'event', {
      eventCategory: 'Search',
      eventAction: 'Search Performed',
      eventLabel: 'Successful Search Results',
      eventValue: searchMetaData.numberOfResults,
    });
  }

  return {
    init,
    recordSearch,
  };
});
