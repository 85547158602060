import { addParamToLocation } from 'infrastructure/locationUtils';
import React from 'react';
import { Link } from 'react-router-dom';
import { urlParams as shellUrlParams } from 'views/components/application/Shell';
import { useStore } from 'views/hooks';
import styles from './PrivacyPolicy.module.scss';

export const PrivacyPolicy: React.FC = function () {
  const store = useStore();
  const security = store.security;

  return (
    <article className={styles.root}>
      <section>
        <h2>Privacy Policy – Accessible Australia app</h2>
        <p>
          This policy applies to your use of the Accessible Australia app (
          <span className={styles.bold}>App</span>) and explains how and why we collect, use, hold
          and disclose your personal information.
        </p>
        <p>
          In this policy, “<span className={styles.bold}>we</span>”, “
          <span className={styles.bold}>us</span>” and “<span className={styles.bold}>our</span>”
          refers to Spinal Life Australia Ltd ACN 167 906 256 and any related body corporates.
        </p>
        <p>
          You consent to us collecting, holding, using and disclosing your personal information in
          accordance with this policy.
        </p>
      </section>
      <section>
        <h3>Our commitment to your privacy</h3>
        <p>
          We recognise the importance of your privacy and understand your concerns about the
          security of the personal information provided to us or input into our App. We comply with
          the Australian Privacy Principles (<span className={styles.bold}>APPs</span>) as contained
          in the <span className={styles.italic}>Privacy Act 1988</span> (Cth) (
          <span className={styles.bold}>Privacy Act</span>). The APPs detail how personal
          information may be collected, used, disclosed, stored and destroyed, and how an individual
          may gain access to or make complaints about the personal information held about them
        </p>
      </section>
      <section>
        <h3>Personal information</h3>
        <p>
          When we refer to personal information in this policy we mean any information or an opinion
          about an identified individual or an individual who can be reasonably identified from the
          information or opinion. Information or an opinion may be personal information regardless
          of whether it is true.
        </p>
      </section>
      <section>
        <h3>What personal information do we collect and hold?</h3>
        When you access and use the App, we collect personal information about you (see section 4
        below about how we collect your personal information). The information we collect from you
        will depend on how you choose to use the App. In particular:
        <ul className={styles.bulletList}>
          <li>
            if you sign in by a third party provider (for example, via a Facebook or Google
            account), we will not collect your name or contact details. However, if you create an
            account using an email address, we will collect your full name and email address;
          </li>
          <li>
            if you choose to create a profile, we will collect your full name or nickname and
            details of the mobility device you use (for example, a manual wheelchair, power
            wheelchair, walking frame, cane, or otherwise);
          </li>
          <li>
            if you choose to leave a review, we will collect your opinions about the App, including
            your user experience.
          </li>
        </ul>
        <p>
          Please be aware that while we will not collect or use any sensitive information, such as
          details of a health condition, the fact that you use a mobility device may in itself
          constitute the collection of such information.
        </p>
        <p>
          When you use the App, we may collect information about your location or activity including
          your IP address. Your IP address is logged when you visit our App to analyse the ways
          people use our App but cannot be used to identify a user personally. Some of this
          information we collect using cookies and similar technologies. A cookie is a small text
          file that the App may place on your device to store information. You can disable these
          cookies on most internet browser settings.
        </p>
      </section>
      <section>
        <h3>How do we collect your personal information?</h3>
        <p>
          We collect your personal information directly from you. We collect your personal
          information when that information is input into the App by you or your authorised
          representative.
        </p>
        <p>You can be anonymous or use a pseudonym when dealing with us, unless:</p>
        <ul className={styles.bulletList}>
          <li>the use of your true identity is a legal requirement; or</li>

          <li>it is impracticable for us to deal with you on such basis.</li>
        </ul>
      </section>
      <section>
        <h3>Why we collect your personal information</h3>
        <p>
          We collect, hold, use and disclose personal information from you or about you where it is
          reasonably necessary for us to carry out our services or to provide you with full use and
          enjoyment of the App. For example, we collect, hold and use your personal information so
          that we can:
        </p>
        <ul className={styles.bulletList}>
          <li>
            support users to be able to more easily navigate to features of the community, such as
            bars, cafes, restaurants and hotels, that are accessible when using a mobility device,
            such as a wheelchair;
          </li>
          <li>
            contact you including to respond to your queries or complaints, or if we need to provide
            you with important information;
          </li>
          <li>
            comply with our legal obligations and assist government and law enforcement agencies or
            regulators;
          </li>
          <li>improve our services and the App;</li>
          <li>undertake administrative tasks; and/or</li>
          <li>update our records and keep your records up to date.</li>
        </ul>
        <p>
          If you do not provide us with your personal information we may not be able to provide you
          with our services (including access to the App), communicate with you or respond to your
          enquiries.
        </p>
      </section>
      <section>
        <h3>Who do we disclose your personal information to and why?</h3>
        <p>We may disclose your personal information where:</p>
        <ol>
          <li>we are required or authorised by law to do so;</li>
          <li>
            you may have expressly consented to the disclosure or the consent may be reasonably
            inferred from the circumstances; or
          </li>
          <li>we are otherwise permitted to disclose the information under the Privacy Act.</li>
        </ol>
        <p>
          If the ownership or control of all or part of our organisation changes or if we sell our
          rights to the App, we may transfer your personal information to the new owner.{' '}
        </p>
      </section>
      <section>
        <h3>Do we disclose your personal information to anyone outside Australia? </h3>
        <p>
          All of your personal information will be stored in servers located in Australia, unless
          otherwise notified to you.{' '}
        </p>
      </section>
      <section>
        <h3>Access and correction to the personal information we hold about you</h3>
        <p>
          You have a right to access and correct the personal information we hold about you. You
          have complete control over the personal information we hold about you and can at any time
          change the personal information by accessing the App.
        </p>
        <p>
          In the event that you can no longer access the App, you may request to access and/or
          correct your personal information. When requesting access to or correction of your
          personal information, please provide us with as much detail as you can about the
          particular information you wish to access or correct, in order to help us retrieve it.
        </p>
        <p>
          We will respond to your requests to access or correct personal information in a reasonable
          time and will take all reasonable steps to ensure that the personal information we hold
          about you remains accurate, up to date and complete.
        </p>
      </section>
      <section>
        <h3>Deletion of your data</h3>
        <p>We provide you with the ability to delete your data.</p>
        <p>
          We store data until it is no longer necessary to provide our services or until you request
          deletion – whichever comes first. This is a case-by-case determination that depends on
          things such as the nature of the data, why it is collected and processed, and relevant
          legal or operational retention needs.
        </p>
        <p>
          When you contact us to delete your information, we delete your name and email address from
          our system but we will not delete information you have shared with others, Points Of
          Interest, photos you have uploaded or reviews you have added or reports you have made to
          Accessible Australia.
        </p>
        <p>
          To request deletion of information we hold about you at any time, please email{' '}
          <a href={'mailto:privacy@spinal.com.au'}>privacy@spinal.com.au</a>.
        </p>
        {security.userProfile?.facebookId && (
          <p>
            <Link
              to={l => addParamToLocation(l, shellUrlParams.SHOW_DELETE_FACEBOOK_DATA, 'y')}
              className={styles.link}
              title={'Request Deletion of Facebook Data'}>
              Request Accessible Australia to delete your personal data provided by Facebook
            </Link>
          </p>
        )}
      </section>
      <section>
        <h3>Storage and security of your personal information</h3>
        <p>
          We take reasonable steps to protect your personal information by storing it in a secure
          environment. Your personal information is stored by electronic means. We take all
          reasonable steps to protect all of the personal information we hold from misuse,
          interference and loss, and from unauthorised access, modification or disclosure. For
          example:
        </p>
        <ul className={styles.bulletList}>
          <li>
            regular auditing and monitoring our internal staff access to personal information; and
          </li>
          <li>IT System security measures.</li>
        </ul>
        <p>
          We will also take reasonable steps to de-identify personal information once we no longer
          require it for the purposes for which it was collected or for any secondary purpose
          permitted under the APPs.
        </p>
      </section>
      <section>
        <h3>Third Party Sites</h3>
        <p>
          Our App may contain and we may provide links to third parties’ websites and services.
          These third-party websites and services are not subject to our privacy policy. Please
          visit the websites of or contact these third parties to view a copy of their privacy
          policy.
        </p>
      </section>
      <section>
        <h3>Complaints and concerns</h3>
        <p>
          We have procedures in place for dealing with complaints and concerns about our practices
          in relation to the Privacy Act and the APPs. We will respond to your complaint in
          accordance with the relevant provisions of the APPs. For further information, please
          contact our Privacy Officer using the details below.
        </p>
      </section>
      <section>
        <h3>Contact Us</h3>
        <p>
          If you have any other questions about this policy, you may{' '}
          <a href={'https://www.spinal.com.au/contact'} target={'_blank'} rel="noopener noreferrer">
            contact us
          </a>{' '}
          or{' '}
          <a
            href={'https://www.spinal.com.au/contact/feedback-complaints/'}
            target={'_blank'}
            rel="noopener noreferrer">
            leave your feedback
          </a>
          . You may also <a href={'mailto:privacy@spinal.com.au'}>email our Privacy Officer</a>.
        </p>
      </section>
      <section>
        <h3>Changes to our Privacy Policy</h3>
        <p>We may change this policy from time to time or as the need arises.</p>
      </section>
      <footer>
        <p className={styles.italic}>This policy was last updated on 01/11/2021. </p>
      </footer>
    </article>
  );
};
