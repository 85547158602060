import React from 'react';
import styles from './Feedback.module.scss';
import { observer } from 'mobx-react-lite';
import { removeParamFromLocation } from 'infrastructure/locationUtils';
import { urlParams, urlParams as shellUrlParams } from 'views/components/application/Shell';
import { useForm } from 'react-hook-form';
import { TextArea } from 'views/components/forms/textArea/TextArea';
import { ISendUserFeedbackCommandModel } from 'api/models/Domain/Aggregates/Feedback/Commands/SendUserFeedbackCommandModel';
import { useStore, useUrlParam } from 'views/hooks';
import { CheckBox } from 'views/components/forms/checkbox/Checkbox';
import { Input } from 'views/components/forms/input/Input';
import { SubpageForm } from 'views/components/layout/SubpageForm';
import { BackButton } from 'views/components/detail/backButton/BackButton';
import { useHistory } from 'react-router-dom';

interface IFeedbackProps {
  className?: string;
}

interface IFormData {
  feedbackText: string;
  contactPermissionGranted: boolean;
  email: string;
  phone: string;
}

export const Feedback: React.FC<IFeedbackProps> = observer(function ({ className }) {
  const store = useStore();
  const history = useHistory();
  const feedbackModel = store.feedback;
  const [, , unsetShowFeedback] = useUrlParam(urlParams.SHOW_FEEDBACK);
  const { register, handleSubmit, errors, watch, reset, control } = useForm<IFormData>({
    defaultValues: {
      feedbackText: '',
      contactPermissionGranted: false,
    },
  });

  const contactPermissionGranted = watch('contactPermissionGranted');
  const emailOrPhoneProvided = !!watch('email') || !!watch('phone');

  async function onSubmit(data: IFormData) {
    const dto: ISendUserFeedbackCommandModel = {
      feedbackText: data.feedbackText.trim(),
      userEmailAddress: data.email,
      userPhoneNumber: data.phone,
    };

    await feedbackModel.sendFeedback(dto);
  }

  function afterSubmit() {
    unsetShowFeedback();
    reset();
  }

  return (
    <SubpageForm
      title="Feedback"
      titleLeftAligned
      className={className}
      containerClassName={styles.container}
      onSubmit={handleSubmit(onSubmit)}
      afterSubmit={afterSubmit}
      control={control}
      leftAction={
        <BackButton
          onClick={() =>
            history.push(removeParamFromLocation(history.location, shellUrlParams.SHOW_FEEDBACK))
          }
          className={styles.goBack}
        />
      }>
      <TextArea
        textAreaErrors={errors.feedbackText}
        name="feedbackText"
        register={register({
          required: { value: true, message: 'Review text is required' },
          pattern: { value: /\S+/, message: 'Review text cannot be empty' },
        })}
        label="Feedback"
        placeholder="Enter your feedback here..."
        maxLength={2500}
        required={true}
      />
      <CheckBox
        register={register}
        name="contactPermissionGranted"
        label="Yes"
        title="Are you happy to be contacted by Spinal Life in response to your feedback?"
      />
      {contactPermissionGranted && (
        <>
          <p>Please provide your phone number or email address below</p>
          <Input
            inputErrors={errors.email}
            name="email"
            register={register({
              validate: () =>
                emailOrPhoneProvided || 'Please provide an email address or phone number',
            })}
            label="Email"
            placeholder="Enter your email"
            type="email"
          />
          <Input
            inputErrors={errors.phone}
            name="phone"
            register={register({
              validate: () =>
                emailOrPhoneProvided || 'Please provide an email address or phone number',
            })}
            label="Phone Number"
            placeholder="Enter your phone number"
            type="tel"
          />
        </>
      )}
    </SubpageForm>
  );
});
