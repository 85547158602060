import React from 'react';
import styles from './LoadingPane.module.scss';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { Icon } from 'views/components/Icon';

interface ILoadingPaneProps {
  className?: string;
  isLoading: boolean;
  children: React.ReactNode;
}

export const LoadingPane: React.FC<ILoadingPaneProps> = observer(function LoadingPane({
  className,
  isLoading,
  children,
}) {
  return (
    <div className={cn(styles.root, className)}>
      <div className={cn(styles.container, { [styles.loading]: isLoading })}>
        <Icon className={styles.icon} name="loading" />
      </div>
      {isLoading ? null : <div>{children || null}</div>}
    </div>
  );
});
