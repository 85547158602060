import { types } from 'mobx-state-tree';
import { observable } from 'mobx';

const SMALL_SCREEN_QUERY = '(max-width: 50pc)';
const TINY_SCREEN_QUERY = '(max-width: 40pc)';

interface ILocalState {
  isSmallViewport: boolean;
  isTinyViewport: boolean;
  actualVhOffset: number;
  isViewportZoomed: boolean;
  isTouchEnabled: boolean;
}

export const EnvironmentModel = types.model('EnvironmentModel', {}).extend(_ => {
  const localState = observable<ILocalState>({
    isSmallViewport: window.matchMedia(SMALL_SCREEN_QUERY).matches,
    isTinyViewport: window.matchMedia(TINY_SCREEN_QUERY).matches,
    isViewportZoomed: false,
    isTouchEnabled:
      !!window.ontouchstart || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,

    // On iOS safari, the browser chrome causes 100vh to not be the correct height - This value is the offset to make it correct
    actualVhOffset: 0,
  });

  function init() {
    if (window.visualViewport) {
      setCustomVhProperty();
      window.visualViewport.addEventListener('resize', setCustomVhProperty);

      if (localState.isTouchEnabled) {
        window.visualViewport.addEventListener('resize', setIsViewportZoomed);
      }
    }

    if (window.matchMedia) {
      const smallMediaList = window.matchMedia(SMALL_SCREEN_QUERY);
      const smallScreenHandler = (e: MediaQueryListEvent) => {
        localState.isSmallViewport = e.matches;
      };
      if (smallMediaList.addEventListener) {
        smallMediaList.addEventListener('change', smallScreenHandler);
      } else {
        smallMediaList.addListener(smallScreenHandler);
      }

      const tinyMediaList = window.matchMedia(TINY_SCREEN_QUERY);
      const tinyScreenHandler = (e: MediaQueryListEvent) => {
        localState.isTinyViewport = e.matches;
      };
      if (tinyMediaList.addEventListener) {
        tinyMediaList.addEventListener('change', tinyScreenHandler);
      } else {
        tinyMediaList.addListener(tinyScreenHandler);
      }
    }
  }

  function setIsViewportZoomed() {
    localState.isViewportZoomed = window.visualViewport.scale !== 1;
  }

  return {
    actions: {
      init,
    },
    views: {
      get isSmallViewport() {
        return localState.isSmallViewport;
      },
      get isTinyViewport() {
        return localState.isTinyViewport;
      },
      get isViewportZoomed() {
        return localState.isViewportZoomed;
      },
    },
  };
});

function setCustomVhProperty() {
  // From: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  // this also fixes a vertical scrollbar appearing when using full-screen on some desktop browsers
  let vh = document.documentElement.clientHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
