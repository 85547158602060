import React from 'react';
import cn from 'classnames';
import { useStore, useUrlParam } from 'views/hooks';
import { MapContext } from 'views/routes/pois/Search';
import { useHistory } from 'react-router-dom';
import { routePaths, urlParamNames } from 'views/routes/routePaths';
import { urlParams as shellUrlParams } from 'views/components/application/Shell';
import styles from './NoPoiResults.module.scss';
import { Icon } from 'views/components/Icon';
import { pluraliseCategory } from 'domain/store/repos/CategoriesRepo';

interface INoResultsProps {
  clearSearch: () => void;
}

export const NoPoiResults: React.FC<INoResultsProps> = function ({ clearSearch }) {
  const store = useStore();
  const security = store.security;
  const environment = store.environment;

  const searchContext = React.useContext(MapContext);
  const history = useHistory();

  const [, setShowMapActionContextMenu] = useUrlParam(
    urlParamNames.mapContextMenu.SHOW_MAP_ACTION_CONTEXT_MENU
  );
  const [, setShowNewPoiForm] = useUrlParam(urlParamNames.mapContextMenu.SHOW_NEW_POI_FORM);
  const [, setShowProfile] = useUrlParam(shellUrlParams.SHOW_PROFILE);
  const [category] = useUrlParam(urlParamNames.search.CATEGORY);

  const [hidden, setHidden] = React.useState(true);
  React.useEffect(() => {
    // The component flickers in slightly before it should, so by adding a change from transparent to opaque,
    // the flicker is hidden.
    // This comes from an error with how searches are kicked off:
    // When the user clicks 'search further', the time taken for the URL to change and the useEffect in SearchPane
    // to run is long enough that the searchModel doesn't change to a 'loading' state in time.
    // As such you see the component appear before that state changes to loading.
    // A potential solution is to change searchModel to take in changes to the search state, and manage searches
    // accordingly, rather than centrally kicking them off from SearchPanel
    setHidden(false);
  }, []);

  return (
    <div className={cn(styles.root, { [styles.hidden]: hidden })}>
      <Icon name="no-results" className={styles.icon} />
      <p className={styles.firstText}>
        Unfortunately we couldn't find any{' '}
        {category ? pluraliseCategory(category)?.toLowerCase() : 'places'} that matched your search.
      </p>
      <p className={styles.text}>
        If we don't have what you're looking for, help us out by
        {security.isLoggedIn ? '' : ' signing in and'} adding it to our map!
      </p>
      <div className={styles.actions}>
        <button
          onClick={() => {
            clearSearch();
            searchContext.focusSearchField && searchContext.focusSearchField();
          }}>
          New search
        </button>
        {security.isLoggedIn ? (
          <button
            onClick={() => {
              setShowMapActionContextMenu('y');
              !environment.isSmallViewport && setShowNewPoiForm('y');
              history.push(
                routePaths.pois.toKeepingExistingParams({ SHOW_LIST: false })(history.location)
              );
            }}>
            Add a place
          </button>
        ) : (
          <button onClick={() => setShowProfile('y')}>Sign in</button>
        )}
      </div>
    </div>
  );
};
