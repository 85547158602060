// Warning: Long enums may have double-precision problems, comparing these may return incorrect results.  Use at your own risk.
// See https://github.com/xwipeoutx/EnumGenie/issues/9
export enum PointOfInterestCategory {
    Accommodation = 1,
    CafeRestaurant = 2,
    Bar = 4,
    ChangingRoom = 8,
    Bathroom = 16,
    Activity = 32,
    Attraction = 64,
    Bank = 128,
    BusStop = 256,
    CarPark = 512,
    Dentist = 1024,
    EquipmentHire = 2048,
    GeneralPractitioner = 4096,
    Grocer = 8192,
    Gymnasium = 16384,
    Hospital = 32768,
    Information = 65536,
    LightRailStation = 131072,
    Museum = 262144,
    OtherRetail = 524288,
    Park = 1048576,
    Pharmacy = 2097152,
    ServiceStation = 4194304,
    Services = 8388608,
    TaxiRank = 16777216,
    Theatre = 33554432,
    Tour = 67108864,
    TrainStation = 134217728,
    VehicleHire = 268435456,
    Venue = 536870912,
    Beach = 1073741824,
    AlliedHealth = 2147483648,
    Specialist = 4294967296,
    OtherHealthService = 8589934592
}
export function pointOfInterestCategoryDescription(value: PointOfInterestCategory) {
    switch (value) {
        case PointOfInterestCategory.Accommodation:
            return `Accommodation`;
        case PointOfInterestCategory.CafeRestaurant:
            return `Café / Restaurant`;
        case PointOfInterestCategory.Bar:
            return `Bar`;
        case PointOfInterestCategory.ChangingRoom:
            return `Changing Room`;
        case PointOfInterestCategory.Bathroom:
            return `Bathroom`;
        case PointOfInterestCategory.Activity:
            return `Activity`;
        case PointOfInterestCategory.Attraction:
            return `Attraction`;
        case PointOfInterestCategory.Bank:
            return `Bank`;
        case PointOfInterestCategory.BusStop:
            return `Bus Stop`;
        case PointOfInterestCategory.CarPark:
            return `Car Park`;
        case PointOfInterestCategory.Dentist:
            return `Dentist`;
        case PointOfInterestCategory.EquipmentHire:
            return `Equipment Hire`;
        case PointOfInterestCategory.GeneralPractitioner:
            return `General Practitioner`;
        case PointOfInterestCategory.Grocer:
            return `Grocer`;
        case PointOfInterestCategory.Gymnasium:
            return `Gymnasium`;
        case PointOfInterestCategory.Hospital:
            return `Hospital`;
        case PointOfInterestCategory.Information:
            return `Information`;
        case PointOfInterestCategory.LightRailStation:
            return `Light Rail Station`;
        case PointOfInterestCategory.Museum:
            return `Museum`;
        case PointOfInterestCategory.OtherRetail:
            return `Other Retail`;
        case PointOfInterestCategory.Park:
            return `Park`;
        case PointOfInterestCategory.Pharmacy:
            return `Pharmacy`;
        case PointOfInterestCategory.ServiceStation:
            return `Service Station`;
        case PointOfInterestCategory.Services:
            return `Services`;
        case PointOfInterestCategory.TaxiRank:
            return `Taxi Rank`;
        case PointOfInterestCategory.Theatre:
            return `Theatre`;
        case PointOfInterestCategory.Tour:
            return `Tour`;
        case PointOfInterestCategory.TrainStation:
            return `Train Station`;
        case PointOfInterestCategory.VehicleHire:
            return `Vehicle Hire`;
        case PointOfInterestCategory.Venue:
            return `Venue`;
        case PointOfInterestCategory.Beach:
            return `Beach`;
        case PointOfInterestCategory.AlliedHealth:
            return `Allied Health`;
        case PointOfInterestCategory.Specialist:
            return `Specialist`;
        case PointOfInterestCategory.OtherHealthService:
            return `Other Health Service`;
    }
}
export interface IPointOfInterestCategoryDescriptor { value: PointOfInterestCategory; name: string; description: string; }
export var allPointOfInterestCategory: IPointOfInterestCategoryDescriptor[] = [
    { value: PointOfInterestCategory.Accommodation, name: `Accommodation`, description: `Accommodation` },
    { value: PointOfInterestCategory.CafeRestaurant, name: `CafeRestaurant`, description: `Café / Restaurant` },
    { value: PointOfInterestCategory.Bar, name: `Bar`, description: `Bar` },
    { value: PointOfInterestCategory.ChangingRoom, name: `ChangingRoom`, description: `Changing Room` },
    { value: PointOfInterestCategory.Bathroom, name: `Bathroom`, description: `Bathroom` },
    { value: PointOfInterestCategory.Activity, name: `Activity`, description: `Activity` },
    { value: PointOfInterestCategory.Attraction, name: `Attraction`, description: `Attraction` },
    { value: PointOfInterestCategory.Bank, name: `Bank`, description: `Bank` },
    { value: PointOfInterestCategory.BusStop, name: `BusStop`, description: `Bus Stop` },
    { value: PointOfInterestCategory.CarPark, name: `CarPark`, description: `Car Park` },
    { value: PointOfInterestCategory.Dentist, name: `Dentist`, description: `Dentist` },
    { value: PointOfInterestCategory.EquipmentHire, name: `EquipmentHire`, description: `Equipment Hire` },
    { value: PointOfInterestCategory.GeneralPractitioner, name: `GeneralPractitioner`, description: `General Practitioner` },
    { value: PointOfInterestCategory.Grocer, name: `Grocer`, description: `Grocer` },
    { value: PointOfInterestCategory.Gymnasium, name: `Gymnasium`, description: `Gymnasium` },
    { value: PointOfInterestCategory.Hospital, name: `Hospital`, description: `Hospital` },
    { value: PointOfInterestCategory.Information, name: `Information`, description: `Information` },
    { value: PointOfInterestCategory.LightRailStation, name: `LightRailStation`, description: `Light Rail Station` },
    { value: PointOfInterestCategory.Museum, name: `Museum`, description: `Museum` },
    { value: PointOfInterestCategory.OtherRetail, name: `OtherRetail`, description: `Other Retail` },
    { value: PointOfInterestCategory.Park, name: `Park`, description: `Park` },
    { value: PointOfInterestCategory.Pharmacy, name: `Pharmacy`, description: `Pharmacy` },
    { value: PointOfInterestCategory.ServiceStation, name: `ServiceStation`, description: `Service Station` },
    { value: PointOfInterestCategory.Services, name: `Services`, description: `Services` },
    { value: PointOfInterestCategory.TaxiRank, name: `TaxiRank`, description: `Taxi Rank` },
    { value: PointOfInterestCategory.Theatre, name: `Theatre`, description: `Theatre` },
    { value: PointOfInterestCategory.Tour, name: `Tour`, description: `Tour` },
    { value: PointOfInterestCategory.TrainStation, name: `TrainStation`, description: `Train Station` },
    { value: PointOfInterestCategory.VehicleHire, name: `VehicleHire`, description: `Vehicle Hire` },
    { value: PointOfInterestCategory.Venue, name: `Venue`, description: `Venue` },
    { value: PointOfInterestCategory.Beach, name: `Beach`, description: `Beach` },
    { value: PointOfInterestCategory.AlliedHealth, name: `AlliedHealth`, description: `Allied Health` },
    { value: PointOfInterestCategory.Specialist, name: `Specialist`, description: `Specialist` },
    { value: PointOfInterestCategory.OtherHealthService, name: `OtherHealthService`, description: `Other Health Service` }
];

export function getPointOfInterestCategoryDescriptor(value: PointOfInterestCategory) {
    switch (value) {
        case PointOfInterestCategory.Accommodation:
            return { value: PointOfInterestCategory.Accommodation, name: `Accommodation`, description: `Accommodation` };
        case PointOfInterestCategory.CafeRestaurant:
            return { value: PointOfInterestCategory.CafeRestaurant, name: `CafeRestaurant`, description: `Café / Restaurant` };
        case PointOfInterestCategory.Bar:
            return { value: PointOfInterestCategory.Bar, name: `Bar`, description: `Bar` };
        case PointOfInterestCategory.ChangingRoom:
            return { value: PointOfInterestCategory.ChangingRoom, name: `ChangingRoom`, description: `Changing Room` };
        case PointOfInterestCategory.Bathroom:
            return { value: PointOfInterestCategory.Bathroom, name: `Bathroom`, description: `Bathroom` };
        case PointOfInterestCategory.Activity:
            return { value: PointOfInterestCategory.Activity, name: `Activity`, description: `Activity` };
        case PointOfInterestCategory.Attraction:
            return { value: PointOfInterestCategory.Attraction, name: `Attraction`, description: `Attraction` };
        case PointOfInterestCategory.Bank:
            return { value: PointOfInterestCategory.Bank, name: `Bank`, description: `Bank` };
        case PointOfInterestCategory.BusStop:
            return { value: PointOfInterestCategory.BusStop, name: `BusStop`, description: `Bus Stop` };
        case PointOfInterestCategory.CarPark:
            return { value: PointOfInterestCategory.CarPark, name: `CarPark`, description: `Car Park` };
        case PointOfInterestCategory.Dentist:
            return { value: PointOfInterestCategory.Dentist, name: `Dentist`, description: `Dentist` };
        case PointOfInterestCategory.EquipmentHire:
            return { value: PointOfInterestCategory.EquipmentHire, name: `EquipmentHire`, description: `Equipment Hire` };
        case PointOfInterestCategory.GeneralPractitioner:
            return { value: PointOfInterestCategory.GeneralPractitioner, name: `GeneralPractitioner`, description: `General Practitioner` };
        case PointOfInterestCategory.Grocer:
            return { value: PointOfInterestCategory.Grocer, name: `Grocer`, description: `Grocer` };
        case PointOfInterestCategory.Gymnasium:
            return { value: PointOfInterestCategory.Gymnasium, name: `Gymnasium`, description: `Gymnasium` };
        case PointOfInterestCategory.Hospital:
            return { value: PointOfInterestCategory.Hospital, name: `Hospital`, description: `Hospital` };
        case PointOfInterestCategory.Information:
            return { value: PointOfInterestCategory.Information, name: `Information`, description: `Information` };
        case PointOfInterestCategory.LightRailStation:
            return { value: PointOfInterestCategory.LightRailStation, name: `LightRailStation`, description: `Light Rail Station` };
        case PointOfInterestCategory.Museum:
            return { value: PointOfInterestCategory.Museum, name: `Museum`, description: `Museum` };
        case PointOfInterestCategory.OtherRetail:
            return { value: PointOfInterestCategory.OtherRetail, name: `OtherRetail`, description: `Other Retail` };
        case PointOfInterestCategory.Park:
            return { value: PointOfInterestCategory.Park, name: `Park`, description: `Park` };
        case PointOfInterestCategory.Pharmacy:
            return { value: PointOfInterestCategory.Pharmacy, name: `Pharmacy`, description: `Pharmacy` };
        case PointOfInterestCategory.ServiceStation:
            return { value: PointOfInterestCategory.ServiceStation, name: `ServiceStation`, description: `Service Station` };
        case PointOfInterestCategory.Services:
            return { value: PointOfInterestCategory.Services, name: `Services`, description: `Services` };
        case PointOfInterestCategory.TaxiRank:
            return { value: PointOfInterestCategory.TaxiRank, name: `TaxiRank`, description: `Taxi Rank` };
        case PointOfInterestCategory.Theatre:
            return { value: PointOfInterestCategory.Theatre, name: `Theatre`, description: `Theatre` };
        case PointOfInterestCategory.Tour:
            return { value: PointOfInterestCategory.Tour, name: `Tour`, description: `Tour` };
        case PointOfInterestCategory.TrainStation:
            return { value: PointOfInterestCategory.TrainStation, name: `TrainStation`, description: `Train Station` };
        case PointOfInterestCategory.VehicleHire:
            return { value: PointOfInterestCategory.VehicleHire, name: `VehicleHire`, description: `Vehicle Hire` };
        case PointOfInterestCategory.Venue:
            return { value: PointOfInterestCategory.Venue, name: `Venue`, description: `Venue` };
        case PointOfInterestCategory.Beach:
            return { value: PointOfInterestCategory.Beach, name: `Beach`, description: `Beach` };
        case PointOfInterestCategory.AlliedHealth:
            return { value: PointOfInterestCategory.AlliedHealth, name: `AlliedHealth`, description: `Allied Health` };
        case PointOfInterestCategory.Specialist:
            return { value: PointOfInterestCategory.Specialist, name: `Specialist`, description: `Specialist` };
        case PointOfInterestCategory.OtherHealthService:
            return { value: PointOfInterestCategory.OtherHealthService, name: `OtherHealthService`, description: `Other Health Service` };
    }
}
