import React from 'react';
import styles from './Profile.module.scss';
import cn from 'classnames';
import { useStore } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import { Onboarding } from './onboarding/Onboarding';
import { FooterLinks } from './legal/FooterLinks';
import { Link } from 'react-router-dom';
import { addParamToLocation } from 'infrastructure/locationUtils';
import { urlParams as shellUrlParams } from 'views/components/application/Shell';

interface IProfileProps {
  hidden: boolean;
}

export const Profile: React.FC<IProfileProps> = observer(function Profile({ hidden }) {
  const store = useStore();
  const security = store.security;

  const [showOnboarding, setShowOnboarding] = React.useState(
    security.userProfile && !security.userProfile.hasOnboarded
  );

  return (
    <>
      <article className={cn(styles.root, { [styles.hidden]: hidden || showOnboarding })}>
        <div className={styles.userNameContainer}>
          <span>{security.userProfile && security.userProfile.displayName}</span>
        </div>
        <nav>
          <ul>
            <li>
              <Link
                className={styles.profileLink}
                to={l => addParamToLocation(l, shellUrlParams.SHOW_UPDATE_PROFILE, 'y')}
                aria-labelledby={'profile-label'}
                aria-describedby={'profile-description'}>
                <p id={'profile-label'} className={styles.actionLabel}>
                  Profile
                </p>
                <p id={'profile-description'} className={styles.actionDescription}>
                  Your details & preferences
                </p>
              </Link>
            </li>
            <li>
              <Link
                className={styles.profileLink}
                to={l => addParamToLocation(l, shellUrlParams.SHOW_FEEDBACK, 'y')}
                aria-labelledby={'feedback-label'}
                aria-describedby={'feedback-description'}>
                <p id={'feedback-label'} className={styles.actionLabel}>
                  Feedback
                </p>
                <p id={'feedback-description'} className={styles.actionDescription}>
                  Help us improve Accessible Australia
                </p>
              </Link>
            </li>
            <li>
              <button
                className={styles.profileButton}
                onClick={security.signOut}
                aria-labelledby={'sign-out-label'}
                aria-describedby={'sign-out-description'}>
                <p id={'sign-out-label'} className={styles.actionLabel}>
                  Sign Out
                </p>
                <p id={'sign-out-description'} className={styles.actionDescription}>
                  Sign out of your account
                </p>
              </button>
            </li>
          </ul>
        </nav>
        <div className={styles.anchorBottom}>
          <FooterLinks />
        </div>
      </article>
      {showOnboarding && <Onboarding onCancelOnboarding={() => setShowOnboarding(false)} />}
    </>
  );
});
